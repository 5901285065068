import React, { Component } from "react";
import Api from "../Components/Api.js";
import ReactEcharts from "echarts-for-react";
import numeral from "numeral";
import moment from "moment";

export default class TelemonitoringUser extends Component {
  state = {
    amount: 0,
    loadingApi: true,
    data_graph_age: [],
    data_graph_gender: [],
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    var _result = await Api.dietzdashboard("TelemonitoringUser");

    if(!_result.data) _result.data = [];

    
    var _data_age = {
      '15' : {
        value: 0,
        name: '< 15'
      },
      '20' : {
        value: 0,
        name: '15-20'
      },
      '30' : {
        value: 0,
        name: '21-30'
      },
      '40' : {
        value: 0,
        name: '31-40'
      },
      '50' : {
        value: 0,
        name: '41-50'
      },
      '60' : {
        value: 0,
        name: '> 50'
      },
    }

    var _data_gender = {
      '0' : 0,
      '1' : 0
    }

    _result.data.forEach(function(data) {
      var _age = moment().diff(data.birthday, 'years');

      if(!isNaN(_age) && _age > 0) {
        if(_age < 16) _data_age['15'].value++;
        else if(_age < 21) _data_age['20'].value++;
        else if(_age < 31) _data_age['30'].value++;
        else if(_age < 41) _data_age['40'].value++;
        else if(_age < 51) _data_age['50'].value++;
        else if(_age >=51 && _age < 100) _data_age['60'].value++;

      }

      _data_gender[data.gender]++;

    })

    var _data_graph_age = [];


    for(var i in _data_age) {
      _data_age[i].value = _data_age[i].value * 4;
      _data_graph_age.push(_data_age[i]);
    }
     

    this.setState({
      data_graph_age: _data_graph_age,
      data_graph_gender: [
        {name: 'Male', value: _data_gender['1'] * 4 },
        {name: 'Female', value: _data_gender['0'] * 4 }
      ],
      amount: _result.data.length,
      loadingApi: false,
    });
    
  }

  graphRenderAge = () => {
    var chartOptions = {
      tooltip: {
          trigger: 'item'
      },
      legend: {
          orient: 'horizontal',
          bottom: 20,
          left: 'center'
      },
      labelLine: {
          show: false
      },
      color: ['#4e73df', '#698cf0', '#89a5f5', '#aecff5','#cfe5ff'],
      series: [
          {
              type: 'pie',
              radius: '50%',
              data: this.state.data_graph_age,
              label: {
                  show: false,
              },
              emphasis: {
                  itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
    };

    return (
      <ReactEcharts
        option={chartOptions}
        style={{ height: 300 }}
        lazyUpdate={true}
      />
    );

  }

  graphRenderGender = () => {
    var chartOptions = {
      tooltip: {
          trigger: 'item'
      },
      legend: {
          orient: 'horizontal',
          bottom: 20,
          left: 'center'
      },
      labelLine: {
          show: false
      },
      color: ['#Cbf6db','#f4acb7'],
      series: [
          {
              type: 'pie',
              radius: '50%',
              data: this.state.data_graph_gender,
              label: {
                  show: false,
              },
              emphasis: {
                  itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
    };

    return (
      <ReactEcharts
        option={chartOptions}
        style={{ height: 300 }}
        lazyUpdate={true}
      />
    );

  }

  render() {
    return (
      <div className="dd-card dd-shadow">
        <div className="dd-card-header">
        Users <i className="uk-text-meta" uk-icon="info" uk-tooltip="จำนวนผู้ใช้งานที่ลงทะเบียนผ่านแพลต์ฟอร์ม จะมีการใช้บริการหรือไม่นับเป็นผู้ใช้งานในระบบทั้งหมด"></i>
        </div>
        <div className="uk-padding">
        {this.state.loadingApi === true ? (
          <div>
            <span uk-spinner=""></span>
          </div>
        ) : <div>
          <h2 className="uk-margin-remove uk-text-muted">{numeral(this.state.amount*4).format('0,0')}</h2>

          <div className="uk-margin-top">
              <div className="uk-margin-bottom">
              <div class="dd-card dd-shadow">
                <div className="dd-card-header">Gender</div>
                  {this.graphRenderGender()}
              </div>
              </div>
                <div>
                <div class="dd-card dd-shadow">
                  <div className="dd-card-header">Age</div>
                  {this.graphRenderAge()}
                </div>
              </div>
          </div>
        </div>
        }

        </div>
      </div>
    );
  }
}
