import React, { Component } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import ImageUriReduce from './ImageUriReduce';
import moment from 'moment';
import UIkit from "uikit";

class UploadFirebase extends Component {
  state = {
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: ""
  };

  handleUploadStart = (file) => {
    try {
      if(file.size > 209715200 ) {
        throw new Error ("จำกัดขนาดสูงสุด 200 Mb")
      } else if(
        ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/gif', 'video/mp4', 'application/pdf'].includes(file.type) !== true
      ) {
        throw new Error ("ไฟล์ไม่สนับสนุน")
  
      }

      this.setState({ isUploading: true, progress: 0 });
      
    } catch(e) {
      UIkit.notification(e.message);
    }
  }
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
  };
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref("images/"+moment().format('YYYY_MM_DD'))
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url })
        if(this.props.cb) this.props.cb(ImageUriReduce(url));
      });

    
  };

  render() {
    return <div className="uk-text-center">
      {
        this.state.isUploading ?
          <div><span uk-spinner=""></span> {this.state.progress} %</div>
          :
          <FileUploader
            hidden
            accept={this.props.accept || "image/*"}
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("images/"+moment().format('YYYY_MM_DD'))}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            //maxWidth={this.props.maxWidth || 1000}
            //maxHeight= {this.props.maxHeight || 1000}
          />
      }
    </div>
  }
}

export default UploadFirebase;