import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';

import Login from '../../Pages/Consult/Login';
import SideMenu from './SideMenu';
import Dashboard from '../../Pages/Consult/Dashboard';
import Diabates from './Diabetes';
import Error404 from '../../Pages/Error404';
import HeaderMenu from './HeaderMenu';
import Chat from '../../Pages/Consult/Chat';
import moment from 'moment';
import AppointmentList from '../../Pages/Consult/AppointmentList.js';
import ConsultStatistic from '../../Pages/Consult/ConsultStatistic';
import MemberSummary from '../../Pages/Consult/MemberSummary';
import PrescriptionList from '../PrescriptionList';
import ConsultCampaign from './ConsultCampaign';
import ConsultCampaignDetail from './ConsultCampaignDetail';
import ConsultSMCReport from '../../Pages/Consult/ConsultSMCReport';
import VisitNote from './VisitNote';
import TeleConsult from './TeleConsult';
import TeleConsultRoom from './TeleConsultRoom';
import consultSMCFinanceReport from '../../Pages/Consult/ConsultSMCFinanceReport';
import MemberList from '../../Pages/Consult/MemberList.js';

export default class ConsultRoute extends Component {

  state = {
    login: false,
    loadingLogin: true
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  consult = null

  async init() {
    
    var result = false;
    try {
      var {result, consult} = await Api.consult('ConsultLoginBySession');

      this.consult = consult
    } catch(e) {
      console.error(e);
    }
    this.setState({ login: result === true ? true : false, loadingLogin: false });
  }

  routeRender = () => {
    var routes = {
      "/": Dashboard,
      "/consult": Dashboard,
      "/consult/chat": Chat,
      "/consult/appointmentList": AppointmentList,
      "/consult/consultStatistic": ConsultStatistic,
      "/consult/diabetes": Diabates,
      "/consult/memberSummary": MemberSummary,
      "/consult/prescriptionList": PrescriptionList,
      "/consult/campaign" : ConsultCampaign,
      "/consult/campaignDetail" : ConsultCampaignDetail,
      "/consult/visitNote": VisitNote,
      "/consult/teleConsult" : TeleConsult,
      "/consult/teleConsultRoom" : TeleConsultRoom,
      "/consult/consultSMCReport" : ConsultSMCReport,
      "/consult/consultSMCFinanceReport" : consultSMCFinanceReport,
      "/consult/memberList" : MemberList
    }; 

    var pathname = this.props.location.pathname.split('/').filter(Boolean).join('/');
    var Route = routes[`/${pathname}`];
    if(Route) {
      return <Route {...this.props} qs={Api.queryString} consult={this.consult} />;
    }
    else {
      return <Error404 {...this.props} />;
    }
  }


  render() {
    if (this.state.login) {
      return (
        <div uk-grid="" className="uk-grid-collapse">
          <div id="d-side" className="uk-width-1-1">
            <SideMenu {...this.props} consult={this.consult} />
          </div>
          <div id="d-body" className="uk-width-expand uk-width-1-1 ">
            <HeaderMenu {...this.props} />
            <div>
            {this.routeRender()}
            </div>
            <div className="d-footer">
            <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@l">
              <div>
                Copyright © {moment().format('YYYY')} <a className="text-bold-800 grey darken-2" href="https://dietz.asia/" target="_blank">dietz.asia </a>
              </div>
              <div>
              </div>
            </div>
            </div>
          </div>
        </div>
      )
    } else {
      return this.state.loadingLogin ?
        <div className="uk-text-center uk-padding-small d-page-loadings-login">
          <span uk-spinner=""></span>
        </div>
        :
        <Login {...this.props} />
    }

  }
};
