import React, { Component } from 'react';
import Api from '../../Components/Api';
import HIVKey from '../../Components/HIVKey';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import $ from 'jquery';

UIkit.use(Icons);

var MONTH = ['01','02','03','04','05','06','07','08','09','10','11','12'];

var KEY_WITHOUT_AMOUNT = ['how_many_tablet_are_left', 
'how_many_day_are_left', 
'how_many_tablet_per_day', 
'symptom_other_abnormal',
'continuous_cough_2_week',
'blood_cough_1_month',
'weight_loss_1_month',
'unknow_fever_1_month',
'unknow_night_sweating_1_month',
'body_rash',
'dizziness',
'tired',
'squeamish',
'diarrhea',
'yellow_body',
'sleepless',
'nightmare',
'urine_pain',
'abnormal_leucorrhoea',
'genitalia_pain',
'genitalia_wound',
'genitalia_itch',
'genitalia_swell',
'symptom_other_abnormal',
'missing_because_bore',
'missing_because_no_time',
'missing_because_busy_with_work',
'missing_because_lost',
'missing_because_share',
'missing_because_no_inspiration',
'missing_because_dispirited',
'missing_because_strong',
'missing_because_too_often',
'missing_because_other',
'missing_because_other_detail',
'sex_more_than_1_person',
'sex_with_disease',
'sex_with_no_condom',
'sex_with_prostitute',
'sex_with_no_risk',
'sex_with_same_gender',
'sex_with_opposite_gender',
'sex_with_bi',
'sex_not_answer'
];

export default class HivTable extends Component {

  state = {
    loadingData: true,
    campaignHiv: false,
  }

  componentDidMount () {
    this.HivDataGet();
  }

  hivData = {};

  tagData = {};

  tag = ['tb','adr','sti','adherence','narcotic','vl','2q','unsafe_sex','partner','preconception','covid'];

  HivDataGet = async () => {
    this.setState({loadingData: true});

    var _response = await Api[this.props.apiType || 'consult']("HivDataTableGet", {
      year: this.props.year,
      campaignId: this.props.campaignId || 'all'
    });

    // console.log(_response)

    if(!Array.isArray(_response.data)) _response.data = [];
    if(!Array.isArray(_response.healthnoti)) _response.healthnoti = [];

    this.hivData = {};
    this.tagData = {};
    for(var tag of this.tag) {
      this.tagData[tag] = {
        sum: 0
      }

      for(var month of MONTH) {
        this.tagData[tag][month] = 0
      }
    }
    
    _response.data.forEach((data) => {

      var _key = `${data.keyname}-${data.value}`;
      var _value = data.amount;
      
      
      if(KEY_WITHOUT_AMOUNT.includes(data.keyname) === true) {
        _key = data.keyname;
        _value = data.amount;
      }

      if(this.hivData[_key] == null) {
        this.hivData[_key] = {};
      }

      var _month = moment(data.dateadd, 'YYYY-MM-DD').format('MM');

      if(this.hivData[_key][_month] == null) {
        this.hivData[_key][_month] = _value;
      }
    })

    // console.log(this.hivData)

    for(var data of _response.healthnoti) {
      var _month = moment(data.datelog, 'YYYY-MM-DD').format('MM');

      this.tagData[data.notikey][_month] += parseInt(data.amount);
      this.tagData[data.notikey].sum += parseInt(data.amount);
    }


    this.setState({loadingData: false, campaignHiv: _response.campaignHiv});

  }

  rowRender = (data) => {
    var _key = `${data.name}-${data.value}`;

    
    if(data.label || data.label_before || data.label_after || data.show_on_column === true) {

      if(data.key === 'how_many_tablet_are_left') {
        _key = data.key;
        data.label = 'จำนวผู้ตอบคำถามจำนวนยาต้านไวรัสเหลือกี่เม็ด';
      }
      else if(data.key === 'how_many_day_are_left') {
        _key = data.key;
        data.label = 'จำนวนผู้ตอบคำถามจำนวนยาต้านไวรัสเหลือกี่วัน';
      }
      else if(data.key === 'how_many_tablet_per_day') {
        _key = data.key;
        data.label = 'จำนวผู้ตอบคำถามจำนวนยาที่รับประทานต่อวัน';
      }
      else if(KEY_WITHOUT_AMOUNT.includes(data.key)) {
        _key = data.key;

      }
      

      var _sum = 0;

      MONTH.forEach((month) => {

        if(this.hivData[_key] != null && this.hivData[_key][month] != null) {
          _sum += parseInt(this.hivData[_key][month]);
        }
      })

      return <tr key={_key}>
        <td className='uk-text-left'><div dangerouslySetInnerHTML={{__html: `${data.text_prefix || ''}${data.label}`}}></div></td>
        <td>{_sum > 0 ? _sum : ''}</td>
        {MONTH.map((month)=>{
          return <td key={_key+month}>{this.hivData[_key] != null && this.hivData[_key][month] != null ? this.hivData[_key][month] : ''}</td>
        })}
      </tr>

    } else {

    }
  }

  childRender = (a) => {
    return a.map((data) => {
      return [
        this.rowRender(data),
        data.child && this.childRender(data.child)
      ]
    })
  }

  TableRender = () => {
    return [
      this.tagRowRender(),
      this.childRender(HIVKey)
    ]
  }

  tagRowRender = () => {
    var _row = [];

    for(var tag in this.tagData) {
      _row.push(
        <tr key={tag}>
        <td className='uk-text-left'><span className={"uk-label uk-text-small d-background-red"}>{tag}</span></td>
        <td>{this.tagData[tag].sum || ''}</td>
        {MONTH.map((month)=>{
          return <td key={tag+month}>{this.tagData[tag][month] || ''}</td>
        })}
      </tr>
      )
    }

    return _row;
  }
  
  CsvExport = () => {
    var _header = [];
    $('#d-table-data > thead > tr:eq(0) > th').each(function() {
      _header.push(`"${$(this).text()}"`);
    })

    var _csv = [`${_header.join(",")}`];



    $('#d-table-data > tbody > tr').each(function() {
      var _row = [];
      $(this).find('td').each(function() {
        _row.push(`"${$(this).text()}"`);
      })

      _csv.push(`${_row.join(",")}`);
    })


    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+_csv.join('\r\n')));
    a.setAttribute('download', `แบบประเมินตนเองก่อนเข้าพบแพทย์สำหรับข้อมูลสร้างเสริมสุขภาพ-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
    window.document.body.appendChild(a);
    a.click();
  }
 
  render() {
    return this.props.hideIfNoHiv !== true || (this.props.hideIfNoHiv && this.state.campaignHiv === true) ? <div className="uk-card uk-card-default uk-padding-small uk-margin-top ">
      <div className="d-card-header">
        <div class="d-card-header">
          <div class="d-card-header-left">แบบประเมินตนเองก่อนเข้าพบแพทย์สำหรับข้อมูลสร้างเสริมสุขภาพ</div>
          <div class="d-card-header-right"><button className='uk-button d-text-white' onClick={this.CsvExport}><span uk-icon="download"></span> Export CSV</button></div>
        </div>
      </div>
      <div className="uk-overflow-auto">
        <table className='uk-table uk-table-divider uk-table-small d-table-border-vertical uk-text-center' id="d-table-data">
          <thead>
            <tr> 
              <th></th>
              <th className='uk-text-center'>รวม</th>
            {MONTH.map((data)=>{
              return <th className='uk-text-center'>{data}</th>;
            })}
            </tr>
          </thead>
          <tbody>
            {
              this.state.loadingData === true ?
              <div><span uk-spinner=""></span></div>
              :
              this.TableRender()
            }
          </tbody>
        </table>
      </div>
    </div>
    :
    null
  }
};
