import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import moment from "moment";
import numeral from "numeral";
import __ from "../../Components/Language";
import $ from "jquery";
import Login from "../../Pages/Member/Login";
import SideMenu from "./SideMenu";
import Dashboard from "../../Pages/Member/Dashboard";
import HeaderMenu from "./HeaderMenu";
import Error404 from "../../Pages/Error404";
import User from "../../Pages/Member/User";
import SmartHealth from "../../Pages/Member/SmartHealth";
import CampaignReport from "../../Pages/Member/CampaignReport";
import UserSmartHealth from "../../Pages/Member/UserSmartHealth";
import Chat from "../../Pages/Member/Chat";
import CampaignChat from "../../Pages/Member/CampaignChat";
import Report from "../../Pages/Member/Report";
import People from "../../Pages/Member/People";
import Register from "../../Pages/Member/Register";
import AppointmentList from "../../Pages/Member/AppointmentList";
import HealthSummary from "../../Pages/Member/HealthSummary";
import WorkHealthRecord from "../../Pages/Member/WorkHealthRecord";
import WorkHealthRecordList from "../../Pages/Member/WorkHealthRecordList";
import PasswordRecover from "../../Pages/Member/PasswordRecover";
import prescriptionList from "../../Pages/Member/prescriptionList";
import invoiceList from "../../Pages/Member/InvoiceList";
import HIV from "./HIV";
import Survey from "../../Pages/Member/Survey";
import ImageUri from "../../Components/ImageUri";
import LanguageLoginSelect from "../../Components/LanguageLoginSelect";
import WearableDevice from '../../Components/Member/WearableDevice';
export default class MemberRoute extends Component {
  memberTemplate = {
    company: {
      level: "0",
    },
  };

  member = null;

  state = {
    login: false,
    loadingLogin: true,
    loadingStep1: false,
    member: {
      ...this.memberTemplate,
    },
    promocode: "",
    paymentType: "1",
    registerNewCampaignStatus: "ตรวจสอบการสมัครเข้าแคมเปญ",
    registerNewCampaignErrorText: "",
    acceptDelicate: false,
    pathname: this.props.location.pathname.split("/").filter(Boolean).join("/"),
    memberpay: {},
    payComplete: false,
    payCompleteError: false,
    payLoading: false,
    campaignList: [],
    alreadyRegisterThisCampaign: false,
  };

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  qs = Api.queryString;

  async init() {
    if (this.qs.logout === "true") localStorage.setItem("AL", "");

    if (this.qs.display === "list" && this.qs.coid) {
      var _response = await Api.member("CampaignByCompanyId", {
        coid: this.qs.coid,
      });
      this.setState({ campaignList: _response.data || [] });
    }

    if (this.qs.vimutToken) {
      var { result, session } = await Api.member("MemberLoginByVimutToken", {
        vimutToken: encodeURIComponent(this.qs.vimutToken),
      });

      if (result == true) {
        localStorage.setItem("memberLogin", session);

        window.location.href = "/";
        return false;
      }
    }

    var result = false;
    try {
      var { result, member } = await Api.member("MemberLoginBySession");
    } catch (e) {
      console.error(e);
    }


    this.member = member || this.memberTemplate;
    
    this.memberCampaignById = [];

    if (Array.isArray(member != null && member.campaign) === true) {

      member.campaign.forEach((c) => {
        this.memberCampaignById.push(c.campaignid);
      });
    }


    this.setState(
      {
        login: result || false,
        loadingLogin: false,
        member: this.member,
        pathname: this.props.location.pathname
          .split("/")
          .filter(Boolean)
          .join("/"),
      },
      () => {
        if (
          this.state.login === true &&
          this.qs.coid != null &&
          this.qs.caid != null
        ) {
          

          if (this.memberCampaignById.includes(this.qs.caid) === true) {
            this.setState({ alreadyRegisterThisCampaign: true });
          } else {
            this.registerNewCampaignDataGet();
            this.setState({ alreadyRegisterThisCampaign: false });
          }
        }
      }
    );

    if (this.qs.mpid) {
      this.setState({ payLoading: true });

      var _response = await Api.member("MemberpayGet", {
        mpid: this.qs.mpid,
      });

      if (_response.data != null && _response.data.paystatus === "Paid") {
        this.setState({ payComplete: true });

        this.registerNewCampaign();
      } else {
        this.setState({
          payComplete: false,
          payLoading: false,
          payCompleteError: true,
        });
      }
    }
  }

  registerNewCampaign = async () => {
    var _response = await Api.member("MemberRegisterNewCampaign", {
      coid: this.qs.coid,
      caid: this.qs.caid,
    });

    if (_response.result === true) {
      window.location.href = "/chat";
    } else {
      this.setState({
        registerNewCampaignErrorText: _response.responseText,
      });
    }
  };

  registerNewCampaignPaymentCheck = async () => {
    try {
      if (this.state.acceptDelicate !== true)
        throw new Error(
          "คุณต้องยอมรับการใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ"
        );

      if (this.campaign.pay === "1") {
        if (this.state.paymentType === "0") {
          var _response = await Api.member("CampaignPromocodeCheck", {
            campaignId: this.qs.caid,
            promocode: this.state.promocode,
          });

          if (_response.result !== true) {
            throw new Error(_response.responseText);
          } else {
            this.registerNewCampaign();
          }
        } else {
          this.setState({ payLoading: true });

          var _response = await Api.member(
            "MemberRegisterNewCampaignPaymentAdd",
            {
              caid: this.qs.caid,
              coid: this.qs.coid,
              mpid: this.qs.mpid || null,
            }
          );

          this.setState({ memberpay: _response.memberpay }, () => {
            if ($("#d-register-pay-form").length) {
              $("#d-register-pay-form").submit();
            }

            this.setState({ payLoading: false });
          });
        }
      } else {
        this.registerNewCampaign();
      }
    } catch (e) {
      console.log(e);

      this.setState({ registerNewCampaignErrorText: e.message });
    }
  };

  campaign = {};

  company = {};

  memberCampaignById = [];

  registerNewCampaignDataGet = async () => {
    var _response = await Api.member("CompanyCampaignById", {
      caid: this.qs.caid,
      coid: this.qs.coid,
      mpid: this.qs.mpid || null,
    });

    this.company = {
      name: _response.co_name,
    };

    this.campaign = _response.campaign || {};

    this.setState({
      registerNewCampaignStatus:
        _response.result === true ? "step_0" : _response.responseText,
    });
  };

  pay = () => {
    return (
      this.campaign.pay === "1" &&
      this.state.memberpay.id && (
        <form
          method="post"
          action="https://www.thaiepay.com/epaylink/payment.aspx"
          target="_self"
          id="d-register-pay-form"
        >
          <input type="text" name="refno" value={this.state.memberpay.id} />
          <input type="text" name="merchantid" value="17332033" />
          <input
            type="text"
            name="customeremail"
            value={this.state.member.email || "noemail@dietz.asia"}
          />
          <input type="hidden" name="cc" value="00" />
          <input
            type="text"
            name="productdetail"
            value={`ชำระเงินค่าสมัครสมาชิก ${this.campaign.name} ${this.company.name}`}
          />
          <input
            type="text"
            name="total"
            value={numeral(this.state.memberpay.price).format("0.00")}
          />
          <input
            type="text"
            name="returnurl"
            value={`https://smarthealth.dietz.asia/register?coid=${this.qs.coid}&caid=${this.qs.caid}&mpid=${this.state.memberpay.id}`}
          ></input>
        </form>
      )
    );
  };

  campaignCardRender = () => {
    return (
      <div>
        {this.pay()}
        <div>
          <div>{__("สถานพยาบาล")}</div>
          <input
            value={this.company.name}
            disabled={true}
            className="uk-input"
            type="text"
          />
        </div>
        <div>
          <div>{__("แคมเปญ")}</div>
          <input
            value={this.campaign.name}
            disabled={true}
            className="uk-input"
            type="text"
          />
          {this.campaign.caption && (
            <div
              className="uk-margin-small-top uk-margin-small-bottom uk-text-meta"
              dangerouslySetInnerHTML={{
                __html: this.campaign.caption.replaceAll("\n", "<br />"),
              }}
            ></div>
          )}
        </div>
        {this.campaign && +this.campaign.day > 0 && (
          <div>
            <div>
              <div>{__("ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล")}</div>
              <input
                type="text"
                className="uk-input"
                disabled="disabled"
                value={this.campaign.day + __(" วัน")}
              />
            </div>
            <div>
              <div>{__("วันที่สมัคร")}</div>
              <input
                type="text"
                className="uk-input"
                disabled="disabled"
                value={moment().add(543, "year").format("DD/MM/YYYY")}
              />
            </div>
            <div>
              <div>{__("วันที่หมดอายุ")}</div>
              <input
                type="text"
                className="uk-input"
                disabled="disabled"
                value={moment()
                  .add(+this.campaign.day + 1, "d")
                  .add(543, "year")
                  .format("DD/MM/YYYY")}
              />
            </div>
            {this.campaign.pay === "1" && (
              <div>
                <div>
                  <div>{__("ค่าใช้จ่าย")}</div>
                  <input
                    type="text"
                    className="uk-input"
                    disabled="disabled"
                    value={numeral(this.campaign.price).format("0,0.00")}
                  />
                </div>
                {this.state.payComplete !== true ? (
                  <div>
                    <div className="uk-margin-small-top">
                      <div>
                        {__(
                          "กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง"
                        )}
                      </div>
                      <div className=" uk-margin-small-top">
                        <label className="uk-width-1-1">
                          <input
                            type="radio"
                            name="payment-type"
                            checked={
                              this.state.paymentType === "0" ? true : false
                            }
                            onChange={() => this.setState({ paymentType: "0" })}
                          />{" "}
                          {__("กรอกรหัสโปรโมชัน")}{" "}
                          <input
                            type="text"
                            className="uk-input uk-text-center uk-margin-small-top"
                            value={this.state.promocode}
                            onChange={(e) =>
                              this.setState({ promocode: e.target.value })
                            }
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="payment-type"
                            checked={
                              this.state.paymentType === "1" ? true : false
                            }
                            onChange={() => this.setState({ paymentType: "1" })}
                          />{" "}
                          {__("ชำระเงิน")}
                        </label>
                      </div>
                    </div>
                    {this.qs.mpid && (
                      <div className="uk-padding-small uk-text-center uk-background-muted uk-text-danger uk-margin-top">
                        {__("โปรดชำระเงิน")}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="uk-padding-small uk-text-center uk-background-muted uk-text-success uk-margin-top">
                    {__("ชำระเงินแล้ว")}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="uk-margin-top">
          <label uk-grid="" className="uk-grid-small">
            <div className="uk-width-auto">
              <input
                type="checkbox"
                className="uk-checkbox"
                id="d-accept-delicate"
                checked={this.state.acceptDelicate === true ? "checked" : ""}
                onChange={() => {
                  this.setState({
                    acceptDelicate: $("#d-accept-delicate").prop("checked"),
                  });
                }}
              />
            </div>
            <div className="uk-width-expand">
              {__("คุณได้อ่านและยอมรับ")}{" "}
              <a
                href="https://dietz.asia/terms.html"
                target="_blank"
                className="uk-link-reset"
                rel="noopener"
              >
                <u>{__("ท่านรับทราบและยอมรับ")}</u>
              </a>{" "}
              {__("และ")}{" "}
              <a
                href="https://dietz.asia/sensitive.html"
                target="_blank"
                className="uk-link-reset"
                rel="noopener"
              >
                <u>
                  {__("การใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ")}
                </u>
              </a>
            </div>
          </label>
        </div>

        {this.state.registerNewCampaignErrorText && (
          <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
            {__(this.state.registerNewCampaignErrorText)}
          </div>
        )}
        {this.state.payLoading === true ? (
          <div className="uk-text-center uk-padding-small">
            <span uk-spinner=""></span>
          </div>
        ) : (
          <button
            className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
            type="button"
            onClick={this.registerNewCampaignPaymentCheck}
          >
            {__(
              this.state.paymentType === "1" &&
                this.state.campaign &&
                this.state.campaign.pay === "1" &&
                this.state.payComplete !== true
                ? "ชำระเงิน"
                : "สมัครสมาชิก"
            )}
          </button>
        )}
      </div>
    );
  };

  routeRender = () => {
    var routes = {
      "/": Chat,
      "/register": UserSmartHealth,
      "/member": UserSmartHealth,
      "/user": User,
      "/user-smart-health": UserSmartHealth,
      "/chat": Chat,
      "/campaign-chat": CampaignChat,
      "/appointment-list": AppointmentList,
      "/health-summary": HealthSummary,
      "/work-health-record": WorkHealthRecord,
      "/wearable-device": WearableDevice,
      "/work-health-record-list": WorkHealthRecordList,
      "/prescriptionList": prescriptionList,
      "/invoiceList": invoiceList,
      "/hiv": HIV,
      "/survey": Survey,
    };

    if (this.state.member.company.level == "1") {
      routes = {
        ...routes,
        "/dashboard": Dashboard,
        "/report": Report,
        "/smart-health": SmartHealth,
        "/campaign-report": CampaignReport,
        "/people": People,
      };
    }

    var Route = routes[`/${this.state.pathname}`];

    if (Route) {
      return <Route {...this.props} qs={this.qs} member={this.member} />;
    } else {
      return <Error404 {...this.props} />;
    }
  };

  campaignListRender = () => {
    if (this.state.campaignList.length === 0) {
      return (
        <div className="uk-padding-small uk-text-center">
          {__("ไม่พบแคมเปญ")}
        </div>
      );
    } else {
      return (
        <div className="uk-margin-top">
          <div className="uk-text-center uk-margin-bottom">
            {__("เลือกแคมเปญ")}
          </div>
          <div>
            {this.state.campaignList.map((value, key) => {

              return (
                <a
                  href={`/register?coid=${this.qs.coid}&caid=${value.id}`}
                  key={`campaignList${key}`}
                  className="uk-grid-small uk-flex-middle uk-link-reset"
                  uk-grid=""
                >
                  <div className="uk-width-1-6">
                    <div
                      className="uk-width-small uk-background-contain"
                      style={{
                        paddingTop: "100%",
                        backgroundImage: `url(${ImageUri(value.image)})`,
                      }}
                    ></div>
                  </div>
                  <div className="uk-width-expand">
                    <div className="uk-text-bold">
                      <u>{value.name}</u>
                    </div>
                    <div>
                      {
                      this.memberCampaignById.includes(value.id) === true ?
                      <span className="uk-text-meta"><span uk-icon="icon: check;"></span> {__('สมัครแคมเปญแล้ว')}</span>
                      :
                      null
              }
                    </div>
                  </div>
                  <div className="uk-width-auto uk-text-right">
                    <span uk-icon="icon: chevron-right;"></span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      );
    }
  };

  loginCardRender = (data) => {
    return (
      <div className="d-form-login-body uk-padding-small">
        <div className="uk-card uk-card-default uk-padding uk-padding-remove-top d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
          <LanguageLoginSelect />
          <div className="uk-text-center uk-padding-small">
            <img src="/logo_dietz.png" />
          </div>
          <h1 className="d-form-login-title uk-margin-small-bottom">
            {__("สมัครใช้บริการสำหรับคนไข้")}
          </h1>
          <div>{data}</div>
        </div>
        <div className="uk-text-center uk-margin-top"><a href="/">{__('กลับหน้าหลัก')}</a></div>
        
      </div>
    );
  };

  render() {
    if (this.state.login) {
      if (
        this.qs.coid != null &&
        this.qs.caid != null &&
        this.state.alreadyRegisterThisCampaign === false
      ) {
        return this.loginCardRender(
          this.state.registerNewCampaignStatus === "step_0"
            ? this.campaignCardRender()
            : this.state.registerNewCampaignStatus
        );
      }
      else if(this.qs.display === "list" && this.qs.coid ) {
        return this.loginCardRender(this.campaignListRender())
      }
      else {
        return (
          <div uk-grid="" className="uk-grid-collapse">
            <div id="d-side" className="uk-width-1-1">
              <SideMenu
                {...this.props}
                level={this.state.member.company.level}
                member={this.member}
              />
            </div>
            <div id="d-body" className="uk-width-expand uk-width-1-1 ">
              <HeaderMenu {...this.props} />
              <div>{this.routeRender()}</div>
              <div className="d-footer">
                <div
                  uk-grid=""
                  className="uk-child-width-1-1 uk-child-width-1-2@l"
                >
                  <div>
                    Copyright © {moment().format("YYYY")}{" "}
                    <a
                      className="text-bold-800 grey darken-2"
                      href="https://dietz.asia/"
                      target="_blank"
                    >
                      dietz.asia{" "}
                    </a>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return this.state.loadingLogin ? (
        <div className="uk-text-center uk-padding-small d-page-loadings-login">
          <span uk-spinner=""></span>
        </div>
      ) : this.props.location.pathname.indexOf("/register") >= 0 ? (
        <Register {...this.props} qs={this.qs} />
      ) : this.props.location.pathname.indexOf("/password-recover") >= 0 ? (
        <PasswordRecover {...this.props} qs={this.qs} />
      ) : (
        <Login {...this.props} qs={this.qs} />
      );
    }
  }
}
