import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import Api from "./Api";
import moment from "moment";
import DataType from "./DataType";
import Formular from "./Formular";
import GroupReport from "./Formular/GroupReport";
import UploadFirebase from "./UploadFirebase";
import UserSmartHealthTrendingGraph from "./Member/UserSmartHealthTrendingGraph";
import Rate from "./Rate";
import numeral from "numeral";
import $ from "jquery";
import ImageUri from "./ImageUri";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import ConsultPrescription from "./Consult/Prescription";
import UserSmartHealthFbsGraph from "./Member/UserSmartHealthFbsGraph";
import CampaignRenew from "./CampaignRenew.js";
import MemberRegisterLocation from '../Components/Member/MemberRegisterLocation';
import HIV from './Member/HIV';
import ChatBoxMessageChat from "./ChatBoxMessageChat";
import firebase from "firebase";
import MemberProfileChatCard from './MemberProfileChatCard';
import ChatBoxDiabates from './ChatBoxDiabates';
import ChatBoxMessageSumbitDialysisType1Render from './ChatBoxMessageSumbitDialysisType1Render';
import ChatBoxMessageSumbitDialysisType2Render from './ChatBoxMessageSumbitDialysisType2Render';
import ChatBoxMessageSumbitDialysisPregnant from './ChatBoxMessageSumbitDialysisPregnant';
import Media from "./Media";
import DiabatesPregnantForm from './DiabetesPregnantForm';
import DiabatesTypeForm from './DiabetesTypeForm';
import {searchAddressByDistrict, searchAddressByProvince, searchAddressByZipcode, searchAddressByAmphoe } from 'thai-address-database';
import Autocomplete from "react-autocomplete";
UIkit.use(Icons);

export default class ChatBox extends Component {
  diabetes = ChatBoxDiabates;

  state = {
    addressAmphoeOption: [],
    addressZipcodeOption: [],
    addressDistrictOption: [],
    addressProvinceOption: [],

    chatList: [],
    chatListAll: [],
    chatListLoading: true,
    chatListPage: 1,
    chatListKeyword: "",
    messageListLoading: false,
    messageListSending: false,
    messageList: [],
    messageCampaignId: false,
    messageCampaignName: "",
    messageConsultId: false,
    messageMemberId: false,
    messageSelectIndex: Api.queryString.messageSelectIndex || false,
    messageConsultName: '',
    chatMessageImage: [],
    chatMessageFile: "",
    chatMessage: "",
    chatMessageFoodName: "",
    chatMessageFoodCaption: "",
    chatMessageFoodImage: "",
    chatMessageFoodDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageMemberImage: "",
    chatMessageConsultImage: "",
    chatMessageBloodDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageBloodSys: "",
    chatMessageBloodDias: "",
    chatMessageBloodTime: "ก่อนนอน",
    chatMessageCovid19Abnormal: true,
    chatMessageCovid19HeartRate: "",
    chatMessageCovid19Weight: "",
    chatMessageCovid19Height: "",
    chatMessageCovid19O2: "",
    chatMessageCovid19Temp: "",
    chatMessageCovid19AtkStatus: "normal",
    chatMessageCovid19AtkDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageCovid19AtkImage: "",
    chatMessageSugarDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageSugarTimeFood: "ก่อนอาหาร",
    chatMessageSugarTime: "เช้า",
    chatMessageSugar: "",
    chatMessageWeightDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageWeight: "",
    chatMessageWaistLine: "",
    chatMessageHeight: "",
    chatMessageFat: "",
    chatMessageConsult: {},
    chatMessageDiabetes: this.diabetes.state_init(),
    chatMessageDiabetesDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageAppointmentType: "โทรศัพท์",
    chatMessageAppointmentDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageAppointmentName: "ติดตามอาการ",
    chatMessageAppointmentBy: "",
    chatMessageAppointmentNote: "",
    chatMessagePaymentDetail: "",
    chatMessagePaymentPrice: "",
    chatMessageWoundSymptom: "ไม่มีอาการ",
    chatMessageWoundSymptomType: [],
    chatMessageWoundSymptomArea: "",
    chatMessageWoundSymptomDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    chatMessageWoundSymptomDetail: "",
    chatMessageWoundSymptomImage: "",
    chatMessageDialysisDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageDialysisDetail: [],
    chatMessageVisitNoteDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageVisitNoteDetail: '',
    chatMessageVisitNoteHn: '',
    chatMessageVisitNoteImage: '',
    chatMessagePatientProfileSymptom: '',
    chatMessagePatientProfileSymptomDay: '',
    chatMessagePatientProfileDrugAllergy: '',
    chatMessagePatientProfileCongenital: '',
    chatMessagePatientProfileMedicalCert: false,
    chatMessagePatientProfileIsCovid: false,
    chatMessagePatientProfileShipping: 'drivethru',
    chatMessagePatientProfileAddressZipcode : '',
    chatMessagePatientProfileAddressDistrict: '',
    chatMessagePatientProfileAddressAmphoe: '',
    chatMessagePatientProfileAddressProvince: '',
    chatMessagePatientProfileAddress: '',
    chatMessagePatientProfileAddressRoad: '',
    chatMessagePatientProfileAddressSoi: '',
    chatMessagePatientProfileImage1: '',
    chatMessagePatientProfileImage2: '',
    chatMessagePatientProfileImage3: '',
    chatMessagePatientProfileImage4: '',
    chatMessagePatientProfileImage5: '',
    chatMessageAddressLat: "",
    chatMessageAddressLng: "",
    chatMessageAddressDetail: "",
    chatMessageAddressPostcode: "",
    chatMessageAddressPopupShow: false,
    chatMessageSOSTemp: '',
    chatMessageSOSPulse: '',
    chatMessageSOSBreath: '',
    chatMessageSOSSys: '',
    chatMessageSOSConsciousness: 'ตื่นดี พูดคุยรู้เรื่อง',
    chatMessageSOSRespirator: false,
    chatMessageSOSStimulant: false,
    WoundTabData: [],
    page: 1,
    profileLoading: true,
    profileRisk: [],
    filterChatTypeSelect: "all",
    filterChatCampaignSelect: "all",
    optionCampaign: [],
    noMoreMessage: false,
    mobileSideMenu: false,
    modalConsultDetailShow: false,
    prescriptionShow: false,
    dialysisTab: 'form',
    dialysisHistory: [],
    atk: null,
    messageMemberCampaignExpire: false,
    hivFinishLoading: false,
    consultInCampaign: []
  };

  messageListView = Api.queryString.listId;

  componentDidMount() {
    this.init();

    // if (!window && global) window = global;

    // if (!window[`chatLoop${this.props.chatby}`])
    //   window[`chatLoop${this.props.chatby}`] = setInterval(() => {
    //     var _p = window.location.pathname;

    //     if (_p === "/consult/chat" || _p === "/chat" || _p === '/') {
    //       this.messageListGet();
    //       this.chatListGet();
    //     }
    //   }, 4000);
      
  }

  componentWillReceiveProps() {
    this.init();
  }

  init = () => {
    this.firebaseListenUpdate();
    this.datepickerGet();

    
    if(this.props.chatby === "c" && this.props.consult != null) {

      this.consultProfile = this.props.consult;

      this.consultProfile.fullname = `${this.props.consult.nameprefix} ${this.props.consult.name} ${this.props.consult.lastname}`;
    }
  };

  consultInCampaignGet = async () => {
    var _response = await Api.consult("ConsultInCampaign", {
      id: this.state.messageCampaignId
    });


    this.setState({consultInCampaign: _response.list || [], chatMessageAppointmentBy: this.consultProfile.id});
  }
  
  firebaseListenUpdate = () => {
    firebase.database().ref(`chatlist_${this.props.chatby}/` + this.props[this.props.chatby === 'c' ?  'consult' : 'member'].id).on('value', (snapshot) => {

      console.log("UPDATE!")

      setTimeout(() => {
        this.chatListGet(true);
        this.messageListGet(true);
      }, 500);
    });
  }
  
  firebaseChatlistUpdate = () => {
    firebase.database().ref(`chatlist_c/${this.state.messageConsultId}`).set(+new Date());

    firebase.database().ref(`chatlist_m/${this.state.messageMemberId}`).set(+new Date());
  }

  apiType = this.props.chatby === "c" ? "consult" : "member";

  consultProfile = {}

  DiabatesPregnantForm = () => {
    return <DiabatesPregnantForm callback={(async (data) => {
      var _result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "text",
        memberRead: this.props.chatby === "m" ? 1 : 0,
        consultRead: this.props.chatby === "c" ? 1 : 0,
        message: data.text,
        chatby: this.props.chatby,
        toAllConsult: "1"
      });

      var _result = await Api[this.apiType]("DiabetesNotiUpdate", {
        noti: data.alert_tag,
        campaignId: this.state.messageCampaignId,
      });
  
      this.firebaseChatlistUpdate();
    })} />
  }

  DiabatesTypeForm = () => {
    return <DiabatesTypeForm callback={(async (data) => {
      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "text",
        memberRead: this.props.chatby === "m" ? 1 : 0,
        consultRead: this.props.chatby === "c" ? 1 : 0,
        message: data.text,
        chatby: this.props.chatby,
        toAllConsult: "1"
      });

      
      var _result = await Api[this.apiType]("DiabetesNotiUpdate", {
        noti: data.alert_tag,
        campaignId: this.state.messageCampaignId,
      });
  
      this.firebaseChatlistUpdate();
    })}  />
  }

  datepickerGet = () => {
    var _type = [
      "Food",
      "Blood",
      "Sugar",
      "Weight",
      "Diabetes",
      "Appointment",
      "Dialysis",
      "WoundSymptom",
      "Covid",
      "Covid2",
      "VisitNote"
    ];
    
    let _this = this;

    _type.forEach((t) => {
      $("#datetimepicker" + t)
        .on("dp.hide", function (e) {
          _this.setState({
            [`chatMessage${t}Date`]: e.date.format("YYYY-MM-DD HH:mm"),
          });
        })
        .datetimepicker({
          format: "DD/MM/YYYY HH:mm",
          defaultDate: moment(),
        });
    });
  };

  profile = {
    profile: {},
    company: {},
    companyGroup: [],
  };

  memberData = {
    bmi: [],
    fbs: [],
    sys: [],
  };

  profileGet = (force) => {
    if (force === true || (this.profile.profile.id !== this.state.messageMemberId)) {
      this.setState({ profileLoading: true }, async () => {
        var { profile, bmi, fbs, sys, company, group } = await Api[
          this.apiType
        ]("UserProfileCard", {
          memberId: this.state.messageMemberId,
          admin: this.props.admin === "true" ? true : false,
        });
 
        this.profile = {
          profile: profile || {},
          company: company || {},
        };

        if (!group) group = [];
        this.profile.companyGroup = group.filter((cg) => {
          if (cg.companyid == this.profile.company.id) {
            return true;
          } else {
            return false;
          }
        });

        this.memberData = {
          bmi: bmi || [],
          fbs: fbs || [],
          sys: sys || [],
        };

        
        this.setState({ 
          profileLoading: false,
          chatMessageAddressLat: profile.lat,
          chatMessageAddressLng: profile.lng,
          chatMessageAddressDetail: profile.address,
          chatMessageAddressPostcode: profile.postcode
        });
      });
    }
  };

  getData = async () => {
    var { data } = await Api[this.apiType]("UserSmartHealthRecord", {
      memberId: this.state.messageMemberId,
      admin: this.props.admin === "true" ? true : false,
    });

    if (!data) data = [];
    var _data = [];

    for (var i = data.length; i-- > 0; ) {
      if (DataType.indexOf(data[i].name) >= 0) {
        data[i].detail = Formular[data[i].name](
          data[i].value,
          data[i].age,
          data[i].gender
        );
        _data.push(data[i]);
      } else {
        _data.push(data[i]);
      }
    }

    this.setState({ profileRisk: GroupReport(_data) || [] });
  };

  chatList0 = null; //To check chatlist update

  chatListGet = async (force) => {
    var {
      list = [],
      campaignAll,
      msi
    } = await Api[this.apiType]("ChatListV2", {
      campaignId: this.state.filterChatCampaignSelect,
      chatListPage: this.state.chatListPage,
      keyword: this.state.chatListKeyword,
      filterChatTypeSelect: this.state.filterChatTypeSelect,
      messageSelectIndex : Api.queryString.messageSelectIndex || false
    });

    if(Array.isArray(list) !== true) list = [];
    
    list.sort(function (a, b) {
      var keyA = a.chatLast ? +a.chatLast.id : 0,
        keyB = b.chatLast ? +b.chatLast.id : 0;

      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    if (
      (list[0] != null && this.chatList0 != null && list[0].chatLast != null && list[0].chatLast.dateadd != this.chatList0.chatLast.dateadd) ||
      this.chatList0 === null ||
      force === true
    ) {
      
      this.chatList0 = list[0];
      
      if(Array.isArray(campaignAll)) {
        campaignAll = campaignAll.map((data)=>{
          try {
            data.memberchatbutton = JSON.parse(data.memberchatbutton);

          } catch(e) { 
            data.memberchatbutton = [];

          }

          return data;
        });
      } else {
        campaignAll = [];
      }
      
      

      this.setState({ chatList: list || [], chatListLoading: false, optionCampaign: campaignAll || []}, () => {
        if (Api.queryString.messageSelectIndex) {
          this.chatList[Api.queryString.messageSelectIndex].click();
          
        }
      });
      /* not auto select firt chat

      if (list[0] && this.state.messageSelectIndex === false) {
        var _messageSelectIndex = null;

        var _listviewIndex = 0;
        for (var i = list.length; i-- > 0; ) {
          _messageSelectIndex = `ca${list[i].campaignid}_co${list[i].consultid}_m${list[i].memberid}`;

          if (
            this.messageListView &&
            list[i].chatLast.id == this.messageListView
          ) {
            _listviewIndex = i;
          } else if (_messageSelectIndex === this.state.messageSelectIndex) {
            _listviewIndex = i;
          }
        }

        var _listView = list[_listviewIndex];

        this.setState(
          {
            messageCampaignId: _listView.campaignid,
            messageCampaignName: _listView.campaignname,
            messageConsultId: _listView.consultid,
            messageMemberId: _listView.memberid,
            messageSelectIndex: _listviewIndex,
            optionCampaign: campaignAll || [],
            chatMessageConsult: _listView,
          },
          () => {
            this.getData();
            this.messageListGet();

            if (_listviewIndex === 0) {
              this.setState({
                messageSelectIndex: `ca${_listView.campaignid}_co${_listView.consultid}_m${_listView.memberid}`,
              });

              this.profileGet();
            }
          }
        );
      } else {
        this.setState({ profileLoading: false });
      }*/
    }
  };

  alreadyHasPatientProfileSymptom = false

  alreadyHasPatientHiv = false

  alreadyHasDiabetesType = false


  alreadyHasDiabetesPregnant = false

  messageWelcomeSend = false;

  messageListGetPage = this.state.page;

  messageScrollBottom = () => {
    setTimeout(() => {
      if($("#d-last-chat-time-container").length) {
        $("#d-chat-body").scrollTop($('#d-chat-body')[0].scrollHeight);
      }
    }, 80);
  }

  messageListGet = async (forceGet) => {
    if (
      !(
        this.state.messageConsultId &&
        this.state.messageMemberId &&
        this.state.messageCampaignId
      )
    ) {
      this.setState({
        messageList: [],
        messageListLoading: false,
        messageListSending: false,
        chatMessageMemberImage: "",
        chatMessageConsultImage: "",
        noMoreMessage: false,
      });

      return false;
    }

    var {
      list = [],
      member,
      consult,
      atk,
      membercampaign,
      nomoremessage,
      symptom,
      hiv,
      diabetestype,
      diabetespregnant
    } = await Api[this.apiType]("ChatMessageList", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      page: this.state.page,
      chatby: this.props.admin === "true" ? false : this.props.chatby,
      admin: this.props.admin === "true" ? true : false,
    });

    this.alreadyHasPatientProfileSymptom = symptom == null ? false : true;
    this.alreadyHasPatientHiv = hiv == null ? false : true;

    this.alreadyHasDiabetesType = diabetestype == null ? false : true;
    
    this.alreadyHasDiabetesPregnant = diabetespregnant == null ? false : true;
    

    if (nomoremessage) this.setState({ noMoreMessage: nomoremessage });

    if (
      forceGet === true ||
      this.state.messageList[0] === undefined ||
      !list || !list[0] ||
      this.state.page !== this.messageListGetPage ||
      (this.state.messageList[0] && 
        list[0].c_id !== this.state.messageList[0].c_id)
    ) {
      this.messageSubmitWoundTabLoaded = false;

      this.messageListGetPage = this.state.page;

      this.profileGet();


      this.setState(
        {
          messageList: list || [],
          messageListLoading: false,
          messageListSending: false,
          chatMessageMemberImage: member,
          chatMessageConsultImage: consult,
          noMoreMessage: nomoremessage,
          atk,
          messageMemberCampaignExpire: membercampaign != null ? (moment(membercampaign.datestop) < moment()) : true,
          hivFinishLoading: false
        },
        async () => {
          if (this.state.page == 1) {
            this.messageScrollBottom();
          }

          this.dialysisHistoryGet();
          
          if(this.props.chatby === "c")
            this.consultInCampaignGet();

          if (
            this.state.messageList.length === 0 &&
            this.state.messageListLoading === false &&
            this.props.chatby === "m" &&
            this.messageWelcomeSend === false
          ) {
            //ไม่มีข้อความ ให้เพิ่มข้อความต้อนรับ
            this.messageWelcomeSend = true;

            await Api.consult("ChatMessageAdd", {
              consultId: this.state.messageConsultId,
              memberId: this.state.messageMemberId,
              campaignId: this.state.messageCampaignId,
              typeMessage: "text",
              memberRead: 0,
              consultRead: 1,
              message:
                "ยินดีต้อนรับสู่ระบบไดเอทซ์ คุณสามารถใช้บริการเพื่อสุขภาพที่ดีของท่านได้ดังนี้ \n 1. บันทึกและติดตามสุขภาพของตนเองทุกวัน \n 2. รับคำปรึกษาจากทีมโรงพยาบาลหรือคลินิกที่ดูแลท่านอยู่ \nหากมีข้อสงสัยสอบถามได้ที่ https://www.facebook.com/Dietz.asia/",
              chatby: "c",
            });

            this.messageListGet();
            this.chatListGet();
          }
        }
      );
      
    } else if(this.state.messageListLoading === true) {
      this.setState({messageListLoading: false});
    }
  };

  tableMemberDataRender = () => {
    var _type = [
      {
        key: "sys",
        name: "ความดันโลหิต",
      },
      {
        key: "fbs",
        name: "น้ำตาลในเลือด (FBS)",
      },
      {
        key: "bmi",
        name: "BMI",
      },
    ];

    return (
      <table
        style={{ border: "1px solid #E5E5E5" }}
        className="uk-table uk-table-small uk-table-divider"
      >
        <thead>
          <tr>
            <th className="uk-text-center">รายการตรวจที่สำคัญ</th>
            <th colSpan="3" className="uk-text-center">
              ค่าที่ตรวจ 3 ครั้งล่าสุด
            </th>
          </tr>
        </thead>
        <tbody>
          {_type.map((value, index) => {
            return (
              <tr key={`row_${index}`}>
                <td>{value.name}</td>
                <td className="uk-text-center uk-text-small">
                  {this.memberData[value.key][0] ? (
                    <div>
                      {value.key === "sys"
                        ? this.memberData[value.key][0].value
                        : numeral(this.memberData[value.key][0].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.memberData[value.key][0].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.memberData[value.key][0].note === '' ? '' : `(${this.memberData[value.key][0].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="uk-text-center uk-text-small">
                  {this.memberData[value.key][1] ? (
                    <div>
                      {value.key === "sys"
                        ? this.memberData[value.key][1].value
                        : numeral(this.memberData[value.key][1].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.memberData[value.key][1].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.memberData[value.key][1].note === '' ? '' : `(${this.memberData[value.key][1].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="uk-text-center uk-text-small">
                  {this.memberData[value.key][2] ? (
                    <div>
                      {value.key === "sys"
                        ? this.memberData[value.key][2].value
                        : numeral(this.memberData[value.key][2].value).format(
                            "0,0.00"
                          )}
                      <div className="uk-text-meta">
                        {moment(this.memberData[value.key][2].dateadd)
                          .add(543, "y")
                          .format("DD/MM/YY HH:mm")}
                      </div>
                      <div className="uk-text-meta">{this.memberData[value.key][2].note === '' ? '' : `(${this.memberData[value.key][2].note})`}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  chatList = {};

  checkOnline = [];

  chatListRender = () => {
    var _list = this.state.chatList
      .map((data, index) => {
        var _atk = {
          bg : '',
          status: ''
        };

        var _campaign_expire_badge = false;

        if(data.datestop !== '0000-00-00' && moment(data.datestop, 'YYYY-MM-DD') < moment() ) {
          _campaign_expire_badge = true;
        }

        if(this.props.chatby === "m") data.atk = null;
        else if(data.atk != null) {
          if(data.atk.value === 'abnormal') {
            _atk.bg = 'd-background-red';
            _atk.status = '(covid detected)';
          } else {
            _atk.bg = 'd-background-green';
            _atk.status = '(normal)';
          }
        }

        var _diabetesnoti = null;

        if(data.diabetesnoti) {
          _diabetesnoti = <div className="uk-margin-small-top">{data.diabetesnoti.split(',').map((noti) => {
            return <span className={"uk-label uk-text-small d-background-red uk-margin-small-right"}>{noti.replace("_", " ")}</span>
          })}</div>
        }

        var _healthnoti = null;

        if(data.healthnoti) {
          _healthnoti = <div className="uk-margin-small-top">{data.healthnoti.split(',').map((hn) => {
            return <span className={"uk-label uk-text-small d-background-red uk-margin-small-right"}>{hn.replace("_", " ")} ALERT</span>
          })}</div>
        }

        var _hivfinishNoti = null;

        if(data.hivData != null) {
          
          _hivfinishNoti = <div className="uk-margin-small-top uk-label d-background-yellow d-text-black"><div>ส่งแบบประเมินวันที่ {moment(data.hivData.dateadd).add(543, 'year').format('DD/MM/YYYY')}</div><div>เวลา {moment(data.hivData.dateadd).format('HH:mm')} น.</div></div>
        }


        if(data.hivDataFinish != null) {
          _hivfinishNoti = <div className="uk-margin-small-top uk-label d-background-gray d-text-black"><div>จบการรักษาแล้ว</div><div>วันที่ {moment(data.hivDataFinish.datefinish).add(543, 'year').format('DD/MM/YYYY')} เวลา {moment(data.hivDataFinish.datefinish).format('HH:mm')} น.</div></div>
        }
        
        var _sosnoti = null;

        if(data.sosnoti) {
          _sosnoti = <div className="uk-margin-small-top">{data.sosnoti.split(',').map((sn) => { 
            var _color = 'red';

            if(sn.includes("LOW") === true) {
              _color = 'green';
            } else if(sn.includes("INTERMEDIATE") === true) {
              _color = 'orange';
            }

            return <span className={`uk-label uk-text-small d-background-${_color} uk-margin-small-right`}>SOS : {sn.replace("_", " ")} ALERT</span>
          })}</div>
        }

        var _online = false;
        
        if (moment().diff(moment(data.datelogin), "minutes") < 1)
          _online = true;

        var _show = true;


        if (this.state.filterChatTypeSelect === "new") {
          if (this.props.chatby === "m" && +data.unreadMember === 0) {
            _show = false;
          } else if (this.props.chatby === "c" && +data.unreadConsult === 0) {
            _show = false;
          }
        } else if(this.state.filterChatTypeSelect === "unchat") {
          if(data.chatLast != null && Object.keys(data.chatLast)[0] != null)
            _show = false;
        }

        if (_show) {
          var _readMessage = true;
          if (
            (this.props.chatby === "m" && +data.unreadConsult > 0) ||
            (this.props.chatby === "c" && +data.unreadMember > 0)
          ) {
            _readMessage = false;
          }

          var _unread =
            this.props.chatby === "m"
              ? +data.unreadMember
              : +data.unreadConsult;

          try {
            var _timeLastMessage = moment(data.chatLast.dateadd, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm');
          } catch (e) {
            var _timeLastMessage = "";
          }

          let _messageSelectIndex = `ca${data.campaignid}_co${data.consultid}_m${data.memberid}`;

          return (
            <div
              key={`chat-list-${index}`}
              data-conid={this.state.messageConsultId}
              data-memid={this.state.messageMemberId}
              className={
                "d-chat-list" +
                (this.state.messageSelectIndex === _messageSelectIndex
                  ? " d-chat-list-active"
                  : "")
              }
              ref={(ref) => {
                this.chatList[_messageSelectIndex] = ref;
              }}
              onClick={() => {
                if(this.state.messageListLoading === true) {
                  UIkit.notification.closeAll();

                  UIkit.notification("โปรดรอจนข้อความโหลดเสร็จสิ้นและลองอีกครั้ง");

                  return;
                }

                if (this.props.chatby === "m") {
                  data.unreadMember = 0;
                } else {
                  data.unreadConsult = 0;
                }

                this.setState(
                  {
                    messageListLoading: true,
                    messageCampaignId: data.campaignid,
                    messageCampaignName: data.campaignname,
                    messageConsultId: data.consultid,
                    messageMemberId: data.memberid,
                    messageSelectIndex: _messageSelectIndex,
                    messageConsultName: `${data.nameprefix} ${data.name} ${data.lastname}`,
                    nomoremessage: false,
                    chatMessageConsult: data,
                    chatList: this.state.chatList, //Remove unread
                    mobileSideMenu: false
                  },
                  () => {
                    this.profileGet(true);
                    this.getData();
                    this.messageListGet();

                    setTimeout(()=>{
                      this.messageScrollBottom();
                    }, 400)
                  }
                );
              }}
            >
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-auto">
                  <div className="uk-inline">
                    <span
                      className={
                        "d-chat-icon-online " +
                        (_online ? "" : "d-chat-icon-offline")
                      }
                    ></span>
                    <div
                      className={"uk-background-muted uk-background-cover d-chatbox-chatlist-person-image" + (_online ? ' d-chatbox-chatlist-person-image-online' : '')}
                      style={{
                        backgroundImage:
                          data.image === ""
                            ? false
                            : `url(${ImageUri(data.image)})`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="uk-width-expand">
                  <div>
                    <div className="uk-text-truncate uk-display-inline-block uk-width-small uk-margin-small-right">{data.nameprefix} {data.name} {data.lastname} </div>
                    
                    {data.atk != null && <div title={"ตรวจเมื่อ " + moment(data.dateadd, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')} className={"uk-label uk-dispaly-inline-block uk-margin-small-bottom " + _atk.bg }>ATK {_atk.status}</div>}
                  </div>
                  <div>
                      <span className={"uk-label uk-text-small d-background-green-2 uk-margin-small-right"}>
                        {data.campaignname}
                      </span>
                    
                    {this.props.chatby === "c" ? (
                      <span>
                        {data.chatLast &&
                          data.chatLast.membergroup &&
                          data.chatLast.membergroup.length &&
                          data.chatLast.membergroup.map((data_membergroup) => {
                            return (
                              <span className="uk-label uk-text-small d-background-gray d-text-black  uk-margin-small-right">
                                {data_membergroup.name}
                              </span>
                            );
                          })}
                      </span>
                    ) : (
                      data.typeconsult && (
                        <span className="uk-label uk-text-small d-background-green uk-margin-small-right">
                          {data.typeconsult}
                        </span>
                      )
                    )}
                    {_online ? <span className={"uk-label uk-text-small d-background-green uk-margin-small-right"}>
                        ออนไลน์
                      </span> : null}
                    {
                      _sosnoti
                    }
                    {
                      _diabetesnoti
                    }
                    {
                      _healthnoti
                    }
                    {
                      _hivfinishNoti
                    }
                    {
                      _campaign_expire_badge === true &&
                      <span className={"uk-label uk-text-small d-background-red uk-margin-small-right uk-text-truncate"}>
                        แพคเกจหมดอายุแล้ววันที่ {moment(data.datestop).format('DD/MM/YYYY')}
                      </span>
                    }
                  </div>
                  <div className="d-chat-list-message">
                    {data.chatLast && data.chatLast.name != null ? <div className="uk-text-truncate ">
                      {_readMessage && <span uk-icon="icon: check; ratio: 0.7"></span> }{data.chatLast.name}
                    </div> : (
                      <>
                      <i className="uk-text-meta">ยังไม่มีการสนทนา</i>
                      <button className="uk-button uk-button-primary uk-margin-small-top">คลิกที่นี่เพื่อเริ่มสนทนา</button>
                      </>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-4@l uk-width-1-3 uk-text-right">
                  {data.chatLast && data.chatLast.dateadd && (
                    <span className="d-chat-list-time d-font-mon">
                      {_timeLastMessage}
                    </span>
                  )}
                  <div>
                    {_unread > 0 && (
                      <span className="d-chat-list-unread">{_unread}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })
      .filter(Boolean);

    return (
      <div id="d-chat-list-container">
        <div id="d-chat-list-header">{this.chatFilterRender()}</div>
        <div id="d-chat-list-body">
          {_list.length === 0 ? (
            <div className="uk-padding uk-text-center">ไม่พบรายการ</div>
          ) : (
            _list
          )}
          {this.state.chatListPage <= 10 && (this.state.chatListPage *10 <= this.state.chatList.length) && (
            <button
              className="uk-width-1-1 uk-button-default uk-button"
              onClick={() => {
                this.chatList0 = null;
                this.setState({ chatListPage: this.state.chatListPage + 1 }, () => {
                  this.chatListGet();
                });
              }}
            >
              ดูเพิ่มเติม
            </button>
          )}
        </div>
      </div>
    );
  };

  ratingUpdate = async (id, rating) => {
    var { result, responseText } = await Api.consult(
      "ChatMessageFoodRatingUpdate",
      {
        id,
        rating,
      }
    );

    UIkit.notification({ message: responseText });
  };


  woundUpdate = async (data) => {
    var _response = await Api.consult("WoundRatingUpdate", {
      id: data.cw_id,
      rating: data.cw_rating,
      suggest: data.cw_suggest,
    });

    UIkit.notification({ message: _response.responseText });
    return;
  };

  hivStatusFinishUpdate = async (id) => {
    this.setState({hivFinishLoading: true});
    var _response = await Api.consult("HivStatusFinishUpdate", {id});

    if(_response.result === true) {
      this.firebaseChatlistUpdate();
    }

  }

  hivCreateImageData = (id) => {
    
    var _data = $("#d-hiv-data-" + id).text().replace(/ {2}/g, '');

    var _lines = _data.split('\n');

    var canvas = document.createElement("CANVAS");
    canvas.width = 1000;
    canvas.height = _lines.length * 30 + 150;

    var ctx = canvas.getContext("2d");

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "black";
    ctx.font = "18px Tahoma";
    var x = 50;
    var y = 100;
    var lineheight = 30;

    for (var i = 0; i<_lines.length; i++)
      ctx.fillText(_lines[i], x, y + (i*lineheight) );
    
    return canvas;
  }

  chatMessageImageRender = (image, isMe, type) => {
    return (
      <div
        className="uk-background-muted uk-background-cover d-smart-health-chat-profile-image"
        onClick={() => {
          if (this.apiType === "member" && !isMe)
            this.setState({ modalConsultDetailShow: true });
          else if (type === "m") {
            $("#d-chat-body").scrollTop(0);
          }
        }}
        style={{
          backgroundImage: image === "" ? false : `url(${ImageUri(image)})`,
        }}
      ></div>
    );
  };

  logVideoCallSend = async (consultId, memberId, campaignId) => {
    Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: this.props.apiType
    }); 

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล
      https://con.defence-innovation.com/${this.state.messageMemberId}/${this.state.messageConsultId}?openExternalBrowser=1`,
      chatby: this.props.chatby,
    });

    this.firebaseChatlistUpdate();
  };


  messageListRender = () => {
    $("#video-call-button")
      .unbind("click")
      .bind("click", (e) => {
        e.stopPropagation();

        $("#video-call-button").attr(
          "href",
          `https://con.defence-innovation.com/${this.state.messageMemberId}/${this.state.messageConsultId}?openExternalBrowser=1`
        );

        this.logVideoCallSend(
          this.state.messageConsultId,
          this.state.messageMemberId,
          this.state.messageCampaignId
        );
      });

    var _message = [];
    if (!this.state.messageList) this.state.messageList = [];
    for (let i = this.state.messageList.length; i-- > 0; ) {

      let _consult_id = this.state.messageList[i].c_consultid;

      var isMe =
        this.props.chatby == this.state.messageList[i].chatby ? true : false;
      _message.push(
        <div
          key={`chatRow${this.state.messageList[i].c_id}`}
          className={
            "d-chatmessage-row " + (isMe ? "uk-text-right" : "uk-text-left")
          }
        >
          {!isMe && (
            <div>
              {
                //Name of consult
                this.props.chatby === "m" && (
                  <div className="d-chat-name-container">
                    <span className="uk-margin-small-right">
                      {this.state.chatMessageConsult.nameprefix}{" "}
                      {this.state.chatMessageConsult.name}{" "}
                      {this.state.chatMessageConsult.lastname}
                    </span>
                    {this.state.chatMessageConsult.typeconsult && (
                      <span className="uk-label uk-text-small d-background-green">
                        {this.state.chatMessageConsult.typeconsult}
                      </span>
                    )}
                  </div>
                )
              }
              <div>
                {this.chatMessageImageRender(
                  this.state[
                    this.props.chatby === "c"
                      ? "chatMessageMemberImage"
                      : "chatMessageConsultImage"
                  ],
                  isMe,
                  this.props.chatby === "c" ? "m" : "c"
                )}
                <div className="uk-margin-small-top">
                  <a
                    className="d-chat-video-button"
                    href={`https://con.defence-innovation.com/${this.state.messageList[i].c_memberid}/${_consult_id}?openExternalBrowser=1`}
                    target="_blank"
                    onClick={() =>
                      this.logVideoCallSend(
                        _consult_id,
                        this.state.messageList[i].c_memberid,
                        this.state.messageList[i].campaignid
                      )
                    }
                  >
                    วีดีโอคอล
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="uk-margin-small-top uk-inline uk-position-relative">
            {!isMe && (
              <div className="uk-margin-left">
                <div className="d-chat-arrow-left"></div>
              </div>
            )}

            <div
              className={"d-chatmessage d-chatmessage-" + (isMe ? "me" : "you")}
            >
              <div className="d-chatmessage-date">
                {moment(this.state.messageList[i].c_dateadd).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </div>
              <ChatBoxMessageChat 
                data={this.state.messageList[i]} 
                chatby = {this.props.chatby} 
                hivFinishLoading={this.state.hivFinishLoading}
                hivStatusFinishUpdate={this.hivStatusFinishUpdate}
                woundUpdate={this.woundUpdate}
                ratingUpdate={this.ratingUpdate}
                chatListUpdate={this.firebaseChatlistUpdate}
                profile={this.profile}
              />
              <div className="d-chatmessage-read">
                {this.state.messageList[i][
                  `${
                    this.state.messageList[i].chatby === "m"
                      ? "consult"
                      : "member"
                  }read`
                ] === "1" ? (
                  <span>
                    <i className="fas fa-check-circle uk-text-primary"></i>{" "}
                    อ่านแล้ว
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {isMe && (
              <div className="uk-margin-right">
                <div className="d-chat-arrow-right"></div>
              </div>
            )}
          </div>

          {isMe &&
            this.chatMessageImageRender(
              this.state[
                this.props.chatby === "c"
                  ? "chatMessageConsultImage"
                  : "chatMessageMemberImage"
              ],
              isMe,
              this.props.chatby === "c" ? "c" : "m"
            )}
        </div>
      );
    }

    return this.state.messageListLoading === true ? (
      <div className="uk-padding uk-text-center">
        <span uk-spinner=""></span>
      </div>
    ) : this.state.messageList.length === 0 ? (
      <div className="uk-padding uk-text-center">ไม่พบข้อความ</div>
    ) : (
      <div className="uk-padding uk-text-center">
        {_message}
        <div className="uk-padding-small uk-text-center uk-text-muted uk-text-small" id="d-last-chat-time-container">
          {moment(this.state.messageList[0].c_dateadd).fromNow()}
        </div>
      </div>
    );
  };

  messageSendText = async () => {
    this.setState({ messageListSending: true });
    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: this.state.chatMessage,
      chatby: this.props.chatby,
    });

    this.setState({ chatMessage: "", messageListSending: false }, () => {
      this.firebaseChatlistUpdate();
    });
  };

  messageSendImage = async () => {
    this.setState({ messageListSending: true });

    for(let image of this.state.chatMessageImage) {

      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "image",
        memberRead: this.props.chatby === "m" ? 1 : 0,
        consultRead: this.props.chatby === "c" ? 1 : 0,
        message: image,
        chatby: this.props.chatby,
      });
    }


    this.setState({ chatMessageImage: [], messageListSending: false }, () => {
      this.firebaseChatlistUpdate();
    });
  };

  messageSendFile = async () => {
    this.setState({ messageListSending: true });
    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "file",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: this.state.chatMessageFile,
      chatby: this.props.chatby,
    });

    this.setState({ chatMessageFile: "", messageListSending: false }, () => {
      this.firebaseChatlistUpdate();
    });
  };

  messageSendFood = async () => {
    this.setState({ messageListSending: true });
    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "food",
      memberRead: 1,
      consultRead: 0,
      name: this.state.chatMessageFoodName,
      caption: this.state.chatMessageFoodCaption,
      image: this.state.chatMessageFoodImage,
      date: this.state.chatMessageFoodDate,
      chatby: this.props.chatby,
      toAllConsult: "1",
    });

    this.setState(
      {
        chatMessageFoodName: "",
        chatMessageFoodCaption: "",
        chatMessageFoodImage: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendBlood = async () => {
    if (!this.state.chatMessageBloodSys || !this.state.chatMessageBloodDias) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลให้ครบ" });
      return false;
    }

    this.setState({ messageListSending: true });

    var _message = `ความดันโลหิต ${this.state.chatMessageBloodSys}/${
      this.state.chatMessageBloodDias
    } ${this.state.chatMessageBloodTime} วันที่ ${moment(
      this.state.chatMessageBloodDate
    ).format("DD/MM/YYYY HH:mm")}`;

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: _message,
      chatby: this.props.chatby,
      typeData: "sys/dias",
      note: this.state.chatMessageBloodTime,
      value: ` ${this.state.chatMessageBloodSys}/${this.state.chatMessageBloodDias}`,
      dateadd: this.state.chatMessageBloodDate,
    });

    this.setState(
      {
        chatMessage: "",
        chatMessageBloodSys: "",
        chatMessageBloodDias: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendWeight = async () => {
    if (!this.state.chatMessageWeight) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลน้ำหนัก" });
      return false;
    }
    /*
    if (!this.state.chatMessageWaistLine) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลรอบเอว" });
      return false;
    }

    if (!this.state.chatMessageHeight) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลส่วนสูง" });
      return false;
    }
    */

    this.setState({ messageListSending: true });

    var _bmi = "";

    if (this.state.chatMessageWeight && this.state.chatMessageHeight) {
      _bmi = (
        +this.state.chatMessageWeight /
        Math.pow(+this.state.chatMessageHeight / 100, 2)
      ).toFixed(2);
    }

    var _message = `น้ำหนัก ${
      this.state.chatMessageWeight || "-"
    } กิโลกรัม\n ไขมัน ${this.state.chatMessageFat || "-"} %\n รอบเอว ${
      this.state.chatMessageWaistLine || "-"
    } นิ้ว\n ส่วนสูง ${
      this.state.chatMessageHeight || "-"
    } เซนติเมตร\n BMI : ${_bmi}\n วันที่ ${moment(
      this.state.chatMessageWeightDate
    ).format("DD/MM/YYYY HH:mm")}`;

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: _message,
      chatby: this.props.chatby,
      typeData: "weight",
      value: this.state.chatMessageWeight,
      dateadd: this.state.chatMessageWeightDate,
      fat: this.state.chatMessageFat,
      height: this.state.chatMessageHeight,
      waistline: this.state.chatMessageWaistLine,
      bmi: _bmi || "",
    });

    this.setState(
      {
        chatMessage: "",
        chatMessageWeight: "",
        chatMessageWaistLine: "",
        chatMessageHeight: "",
        chatMessageFat: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendSugar = async () => {

    if(this.state.messageListSending === true) {
      return false;
    }

    if (!this.state.chatMessageSugar) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลน้ำตาลในเลือด" });
      return false;
    }

    this.setState({ messageListSending: true });

    var _message = `น้ำตาลในเลือด ${
      this.state.chatMessageSugar
    } mg/dL เวลาที่ตรวจ ${this.state.chatMessageSugarTimeFood} ${
      this.state.chatMessageSugarTime
    } วันที่ ${moment(this.state.chatMessageSugarDate).format(
      "DD/MM/YYYY HH:mm"
    )}`;

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: _message,
      chatby: this.props.chatby,
      typeData: "fbs/fpg",
      value: this.state.chatMessageSugar,
      note:
        this.state.chatMessageSugarTimeFood +
        "," +
        this.state.chatMessageSugarTime,
      dateadd: this.state.chatMessageSugarDate,
      toAllConsult: "1",
    });

    setTimeout(()=>{
      this.setState({ chatMessage: "", messageListSending: false }, () => {
        this.firebaseChatlistUpdate();
      });

    }, 50);

  };

  messageSendDiabates = async () => {
    this.setState({ messageListSending: true });

    var _message = JSON.stringify(this.state.chatMessageDiabetes);

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "diabates",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: _message,
      chatby: this.props.chatby,
      dateadd: this.state.chatMessageDiabetesDate,
      toAllConsult: "1",
    });


    this.setState(
      {
        chatMessage: "",
        chatMessageDiabetes: this.diabetes.state_init(),
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendDialysis = async () => {
    this.setState({ messageListSending: true });

    var _message = JSON.stringify(this.state.chatMessageDialysisDetail);

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "dialysis",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: _message,
      chatby: this.props.chatby,
      dateadd: this.state.chatMessageDialysisDate,
      toAllConsult: "1",
    });

    this.setState(
      {
        chatMessage: "",
        chatMessageDiabetes: this.diabetes.state_init(),
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendAppointment = async () => {
    this.setState({ messageListSending: true });
    
    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "appointment",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      chatby: this.props.chatby,
      typeData: "appointment",
      appointmentType: this.state.chatMessageAppointmentType,
      appointmentDate: this.state.chatMessageAppointmentDate,
      appointmentName: this.state.chatMessageAppointmentName,
      appointmentNote: this.state.chatMessageAppointmentNote,
      appointmentBy: this.state.chatMessageAppointmentBy,
    });

    this.setState({ messageListSending: false }, () => {
      this.firebaseChatlistUpdate();
    });
  };

  messageSendPayment = async () => {
    this.setState({ messageListSending: true });

    var _price =
      this.state.chatMessagePaymentPrice.indexOf(".") > -1
        ? numeral(this.state.chatMessagePaymentPrice).format("0,0.00")
        : numeral(this.state.chatMessagePaymentPrice).format("0,0");

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: `การชำระเงิน\nรายละเอียด : ${
        this.state.chatMessagePaymentDetail
      }\nยอดเงิน : ${_price} บาท\nคลิกชำระเงินที่นี่ : https://pay.sn/dietz/${_price}/${encodeURIComponent(
        this.state.chatMessagePaymentDetail
      )}`,
      chatby: this.props.chatby,
    });

    this.setState(
      {
        chatMessagePaymentDetail: "",
        chatMessagePaymentPrice: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendCovid19 = async () => {
    var _covid19AbnormalKey = this.covid19AbnormalKey;
    var _abnormal = [];
    var _abnormal_text = "";
    $(".d-covid-19-select:checked").each(function () {
      _abnormal.push($(this).val());
      _abnormal_text += `\n${_covid19AbnormalKey[$(this).val()]} (${$(
        this
      ).val()})`;
    });

    this.setState({ messageListSending: true });

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      typeData: "covid19",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      message: `อาการโควิด-19\n${
        $("#d-covid-19-risk-area-select").prop("checked") === true
          ? `✓ ได้ไปในพื้นที่เสี่ยงโควิด 19\n`
          : ""
      }${
        $("#d-covid-19-risk-contact-select").prop("checked") === true
          ? `✓ ได้ใกล้ชิดกลุ่มเสี่ยงโควิด 19\n`
          : ""
      }
      ผลตรวจ ATK : ${this.state.chatMessageCovid19AtkStatus === 'normal' ? 'ปกติ' : 'ผิดปกติ'}\nตรวจ ATK เมื่อ : ${moment(this.state.chatMessageCovid19AtkDate).format('DD/MM/YYYY HH:mm')}\n
      อุณหภูมิ ${
        this.state.chatMessageCovid19Temp || "-"
      } °C\nน้ำหนัก ${
        this.state.chatMessageCovid19Weight || "-"
      } กิโลกรัม\nส่วนสูง ${
        this.state.chatMessageCovid19Height || "-"
      } เซนติเมตร\nออกซิเจนในเลือด (SpO2) ${
        this.state.chatMessageCovid19O2 || "-"
      } %\nอัตราการเต้นหัวใจ (Heart Rate) ${
        this.state.chatMessageCovid19HeartRate || "-"
      } ครั้ง/นาที\n
      อาการผิดปกติทางร่างกาย ${
        this.state.chatMessageCovid19Abnormal === true ? "" : "ไม่มี"
      }${this.state.chatMessageCovid19Abnormal === true ? _abnormal_text : ""}
      `,
      chatby: this.props.chatby,
      covid19riskarea:
        $("#d-covid-19-risk-area-select").prop("checked") === true ? "1" : "0",
      covid19riskcontact:
        $("#d-covid-19-risk-contact-select").prop("checked") === true
          ? "1"
          : "0",
      covid19abnormal: JSON.stringify(_abnormal),
      covid19AtkImage: this.state.chatMessageCovid19AtkImage,
      covid19AtkStatus: this.state.chatMessageCovid19AtkStatus,
      covid19AtkDate: this.state.chatMessageCovid19AtkDate,
      temp: this.state.chatMessageCovid19Temp,
      sp02: this.state.chatMessageCovid19O2,
      heartrate: this.state.chatMessageCovid19HeartRate,
    });

    this.setState(
      {
        chatMessageCovid19Temp: "",
        chatMessageCovid19O2: "",
        chatMessageCovid19Weight: "",
        chatMessageCovid19Height: "",
        chatMessageCovid19HeartRate: "",
        chatMessageCovid19AtkImage: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendWound = async () => {
    if (this.state.chatMessageWoundSymptomImage) {
      this.setState({ messageListSending: true });

      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "wound",
        memberRead: this.props.chatby === "m" ? 1 : 0,
        consultRead: this.props.chatby === "c" ? 1 : 0,
        message: "",
        chatby: this.props.chatby,
        woundSymptomDate: this.state.chatMessageWoundSymptomDate,
        woundSymptom: this.state.chatMessageWoundSymptom,
        woundSymptomType: JSON.stringify(
          this.state.chatMessageWoundSymptom === ""
            ? []
            : this.state.chatMessageWoundSymptomType
        ),
        woundSymptomArea: this.state.chatMessageWoundSymptomArea,
        woundSymptomDetail: this.state.chatMessageWoundSymptomDetail,
        woundSymptomImage: this.state.chatMessageWoundSymptomImage,
      });

      this.messageSubmitWoundTabLoaded = false;

      this.setState(
        {
          chatMessageWoundSymptomImage: "",
          chatMessageWoundSymptom: "",
          chatMessageWoundSymptomType: [],
          chatMessageWoundSymptomArea: "",
          chatMessageWoundSymptomDetail: "",
          messageListSending: false,
        },
        () => {
          this.firebaseChatlistUpdate();
        }
      );
    } else {
      UIkit.notification({ message: "โปรดเพิ่มรูปภาพแผล" });
    }
  };

  messageSendVisitNote = async () => {
    this.setState({ messageListSending: true });

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "visitnote",
      memberRead: this.props.chatby === "m" ? 1 : 0,
      consultRead: this.props.chatby === "c" ? 1 : 0,
      chatby: this.props.chatby,
      visitNoteSymptomDate: this.state.chatMessageVisitNoteDate,
      visitNoteSymptomDetail: this.state.chatMessageVisitNoteDetail,
      chatMessageVisitNoteHn: this.state.chatMessageVisitNoteHn,
      visitNoteSymptomImage: this.state.chatMessageVisitNoteImage,
    });

    this.setState(
      {
        chatMessageVisitNoteImage: "",
        chatMessageVisitNoteDetail: "",
        chatMessageVisitNoteHn: "",
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };
  
  messageSendPatientProfile = async () => {
    if (!this.state.chatMessagePatientProfileSymptom || !this.state.chatMessagePatientProfileSymptomDay) {
      UIkit.notification({ message: "โปรดใส่ข้อมูลให้ครบ" });
      return false;
    }

    this.setState({ messageListSending: true });

    var _message = `<b>ข้อมูลก่อนพบแพทย์</b>
    <b>อาการ :</b> ${this.state.chatMessagePatientProfileSymptom}
    <b>ระยะเวลาที่เป็น</b> : ${this.state.chatMessagePatientProfileSymptomDay} วัน
    <b>ส่วนสูง</b> : ${this.state.chatMessageHeight || '-'} เซนติเมตร
    <b>น้ำหนัก</b> : ${this.state.chatMessageWeight || '-'} กิโลกรัม
    <b>โรคประจำตัว</b> : ${this.state.chatMessagePatientProfileCongenital || '-'}
    <b>ประวัติแพ้ยา</b> : ${this.state.chatMessagePatientProfileDrugAllergy || '-'}
    <b>ใบรับรองแพทย์</b> : ${this.state.chatMessagePatientProfileMedicalCert === true ? 'ต้องการใบรับรองแพทย์' : 'ไม่ต้องการ'}
    <b>วิธีการจัดส่งยา</b> : ${this.state.chatMessagePatientProfileShipping === 'drivethru' ? 'Drive thru' : 'ไปรษณีย์ (EMS)'}
    <b>ที่อยู่</b> : บ้านเลขที่ ${this.state.chatMessagePatientProfileAddress || ''} ${this.state.chatMessagePatientProfileAddressSoi ? ` ซอย ${this.state.chatMessagePatientProfileAddressSoi}` : ''} ${this.state.chatMessagePatientProfileAddressRoad ? ` ถนน ${this.state.chatMessagePatientProfileAddressRoad}` : ''} ตำบล ${this.state.chatMessagePatientProfileAddressDistrict || ''} อำเภอ ${this.state.chatMessagePatientProfileAddressAmphoe || ''} จังหวัด ${this.state.chatMessagePatientProfileAddressProvince || ''} รหัสไปรษณีย์ ${this.state.chatMessagePatientProfileAddressZipcode || ''} 
    `;

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "text",
      memberRead: 1,
      consultRead: 0,
      message: _message,
      chatby: this.props.chatby,
      chatMessagePatientProfileAddressZipcode : this.state.chatMessagePatientProfileAddressZipcode,
      chatMessagePatientProfileAddressDistrict: this.state.chatMessagePatientProfileAddressDistrict,
      chatMessagePatientProfileAddressAmphoe: this.state.chatMessagePatientProfileAddressAmphoe,
      chatMessagePatientProfileAddressProvince: this.state.chatMessagePatientProfileAddressProvince,
      chatMessagePatientProfileAddress: this.state.chatMessagePatientProfileAddress,
      chatMessagePatientProfileAddressRoad: this.state.chatMessagePatientProfileAddressRoad,
      chatMessagePatientProfileAddressSoi: this.state.chatMessagePatientProfileAddressSoi,
    });



    for(let i=1; i<=5; i++) {
      if(this.state[`chatMessagePatientProfileImage${i}`] !== '') {
        var result = await Api[this.apiType]("ChatMessageAdd", {
          consultId: this.state.messageConsultId,
          memberId: this.state.messageMemberId,
          campaignId: this.state.messageCampaignId,
          typeMessage: "image",
          memberRead: 1,
          consultRead: 0,
          message: this.state[`chatMessagePatientProfileImage${i}`],
          chatby: this.props.chatby,
        });
      }
    }
    
    
    Api[this.props.type === 'c' ? 'consult' : 'member']('MemberProfileUpdate', {
      key : 'address',
      value: `บ้านเลขที่ ${this.state.chatMessagePatientProfileAddress || ''} ${this.state.chatMessagePatientProfileAddressSoi ? ` ซอย ${this.state.chatMessagePatientProfileAddressSoi}` : ''} ${this.state.chatMessagePatientProfileAddressRoad ? ` ถนน ${this.state.chatMessagePatientProfileAddressRoad}` : ''} ตำบล ${this.state.chatMessagePatientProfileAddressDistrict || ''} อำเภอ ${this.state.chatMessagePatientProfileAddressAmphoe || ''} จังหวัด ${this.state.chatMessagePatientProfileAddressProvince || ''} รหัสไปรษณีย์ ${this.state.chatMessagePatientProfileAddressZipcode || ''}`
    });

    Api.member('MemberProfileUpdate', {
      key : 'weight',
      value: this.state.chatMessageWeight
    });

    
    Api.member('MemberProfileUpdate', {
      key : 'height',
      value: this.state.chatMessageHeight
    });

    Api.member('MemberProfileUpdate', {
      key : 'drugallergy',
      value: this.state.chatMessagePatientProfileDrugAllergy
    });


    Api.member('MemberProfileUpdate', {
      key : 'congenitaldisease',
      value: this.state.chatMessagePatientProfileCongenital
    });

    this.setState(
      {
        chatMessagePatientProfileImage1: "",
        chatMessagePatientProfileImage2: "",
        chatMessagePatientProfileImage3: "",
        chatMessagePatientProfileImage4: "",
        chatMessagePatientProfileImage5: "",
        chatMessagePatientProfileSymptomDay: "0",
        chatMessagePatientProfileSymptom: "",
        chatMessagePatientProfileDrugAllergy: "",
        chatMessagePatientProfileCongenital: "",
        chatMessageWeight: "",
        chatMessageHeight: "",
        chatMessagePatientProfileShipping: 'drivethru',
        chatMessagePatientProfileAddressZipcode : '',
        chatMessagePatientProfileAddressDistrict: '',
        chatMessagePatientProfileAddressAmphoe: '',
        chatMessagePatientProfileAddressProvince: '',
        chatMessagePatientProfileAddress: '',
        chatMessagePatientProfileAddressRoad: '',
        chatMessagePatientProfileAddressSoi: '',
        chatMessagePatientProfileConfirmInformation: false,
        messageListSending: false,
      },
      () => {
        this.firebaseChatlistUpdate();
      }
    );
  };

  messageSendAddress = async () => {
    if(!this.state.chatMessageAddressLat || !this.state.chatMessageAddressLng || !this.state.chatMessageAddressDetail || !this.state.chatMessageAddressPostcode) {
      UIkit.notification("โปรดใส่ข้อมูลให้ครบถ้วน")

      return;
    }
    this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

    var _message = `<b>ที่อยู่จัดส่งยา :</b> ${this.state.chatMessageAddressDetail}
    <b>รหัสไปรษณีย์ :</b> ${this.state.chatMessageAddressPostcode}
    <b>พิกัด :</b> <a href="http://maps.google.com/maps?&z=18&t=k&q=${this.state.chatMessageAddressLat}+${this.state.chatMessageAddressLng}" rel="noopener" target="_blank">${this.state.chatMessageAddressLat},${this.state.chatMessageAddressLng}</a>
    `;

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "address",
      memberRead: 1,
      consultRead: 0,
      message: _message,
      chatby: this.props.chatby,
      chatMessageAddressLat: this.state.chatMessageAddressLat,
      chatMessageAddressLng: this.state.chatMessageAddressLng,
      chatMessageAddressDetail: this.state.chatMessageAddressDetail,
      chatMessageAddressPostcode: this.state.chatMessageAddressPostcode
    });


    this.setState(
      {
        chatMessageAddressLat: "",
        chatMessageAddressLng: "",
        chatMessageAddressDetail: "",
        chatMessageAddressPostcode: "",
        messageListSending: false,
      }, ()=>{
        this.profileGet(true);
        this.firebaseChatlistUpdate();
      }
    );
    
  };

  messageSendHiv = async (data) => {

    this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "hiv",
      memberRead: 1,
      consultRead: 0,
      message: data.text,
      chatby: this.props.chatby,
      noti: data.noti,
      healthData: JSON.stringify(data.value),
      toAllConsult: "1",
    });

    var result = await Api[this.apiType]("HivDataAdd", {
      data: JSON.stringify(data.value),
      dateadd: moment().format('YYYY-MM') + '-01',
      campaignId: this.state.messageCampaignId,
      noti: data.noti,
    });

    this.setState(
      {
        messageListSending: false,
      }, ()=>{
        this.firebaseChatlistUpdate();
      }
    );

    
  };

  
  messageSendSOS = async () => {
    if(
      !this.state.chatMessageSOSTemp
      || !this.state.chatMessageSOSPulse
      || !this.state.chatMessageSOSBreath
      || !this.state.chatMessageSOSSys
    ) {

      UIkit.notification({ message: "โปรดใส่ข้อมูลให้ครบ" });

      return;
    }


    this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

    var _noti = '';

    var _force_high_risk = false;

    var _point = 0;

    var _temp = parseFloat(this.state.chatMessageSOSTemp);

    if(_temp <= 35 || _temp >= 38.5) {
      _point += 2;
    } else if(
      (_temp >= 35.1 && _temp <= 36)
      ||
      (_temp >= 38.1 && _temp <= 38.4)
    ) {
      _point += 1;
    }

    
    var _pulse = parseFloat(this.state.chatMessageSOSPulse);

    if(_pulse <= 40 || _pulse >= 140) {
      _point += 3;

      _force_high_risk = true;
    } 
    else if(
      (_pulse >= 41 && _pulse <= 50)
      ||
      (_pulse >= 100 && _pulse < 121)
    ) {
      _pulse += 1;
    }
    else if(
      _pulse >= 121 && _pulse < 140
    ) {
      _point += 2;
    }

    var _breath = parseFloat(this.state.chatMessageSOSBreath);

    if(_breath <= 8 || _breath >= 35) {
      _point += 3;

      _force_high_risk = true;
    } 
    else if(_breath >= 21 && _breath < 26) {
      _point += 1;
    }
    else if(_breath >= 26 && _breath < 35) {
      _point += 2;
    }

    if(this.state.chatMessageSOSRespirator == true) {
      _point += 2;
    }

    
    var _sys = parseFloat(this.state.chatMessageSOSSys);
    
    if(_sys <= 80) {
      _point += 3;

      _force_high_risk = true;
    }
    else if(
      (_sys > 80 && _sys < 91)
      ||
      (_sys >= 200)
    ) {
      _point += 2;
    }
    else if(
      (_sys >= 91 && _sys < 101)
      ||
      (_sys >= 181 && _sys < 200)
    ) {
      _point += 1;
    }


    if(this.state.chatMessageSOSStimulant == true) {
      _point += 2;
    }

    if(
      this.state.chatMessageSOSConsciousness  === 'สับสน กระสับกระส่าย ที่เพิ่งเกิดขึ้น'
      ||
      this.state.chatMessageSOSConsciousness === 'ซึม แต่เรียกแล้วลืมตา'
    ) {
      _point += 1;
    } else if(
      this.state.chatMessageSOSConsciousness  === 'ซึมมาก ต้องกระตุ้น จึงลืมตา'
    ) {
      _point += 2;
    } else if(
      this.state.chatMessageSOSConsciousness  === 'ไม่รู้สึกตัว แม้กระตุ้นแล้ว'
    ) {
      _point += 3;

      _force_high_risk = true;
    }

    if(_point < 3) {
      _noti = 'LOW_RISK';
    } else if(_point >= 3 && _point < 6) {
      _noti = 'INTERMEDIATE_RISK';
    } else if(_point >= 6 || _force_high_risk === true) {
      _noti = 'HIGH_RISK';
    }

    var result = await Api[this.apiType]("ChatMessageAdd", {
      consultId: this.state.messageConsultId,
      memberId: this.state.messageMemberId,
      campaignId: this.state.messageCampaignId,
      typeMessage: "sos",
      memberRead: 1,
      consultRead: 0,
      chatMessageSOSTemp: this.state.chatMessageSOSTemp,
      chatMessageSOSPulse: this.state.chatMessageSOSPulse,
      chatMessageSOSBreath: this.state.chatMessageSOSBreath,
      chatMessageSOSSys: this.state.chatMessageSOSSys,
      chatMessageSOSConsciousness: this.state.chatMessageSOSConsciousness,
      chatMessageSOSRespirator: this.state.chatMessageSOSRespirator,
      chatMessageSOSStimulant: this.state.chatMessageSOSStimulant,
      noti_sos: _noti,
      message: `<b>อุณหภูมิ :</b> ${this.state.chatMessageSOSTemp} °C
      <b>หายใจ :</b> ${this.state.chatMessageSOSBreath} ${this.state.chatMessageSOSRespirator == true ? ' (ใส่เครื่องช่วยหายใจ)' : ''}
      <b>ชีพจร :</b> ${this.state.chatMessageSOSPulse} ครั้ง/นาที
      <b>ความดันโลหิต (ค่าบน) :</b> ${this.state.chatMessageSOSSys} mm/Hg ${this.state.chatMessageSOSStimulant == true ? ' (ใช้ยากระตุ้นความดันโลหิต)' : ''}
      <b>ความรู้สึกตัว :</b> ${this.state.chatMessageSOSConsciousness}
      `
    });
    

    this.setState(
      {
        chatMessageSOSTemp: '',
        chatMessageSOSPulse: '',
        chatMessageSOSBreath: '',
        chatMessageSOSSys: '',
        chatMessageSOSRespirator: false,
        chatMessageSOSStimulant: false,
        messageListSending: false,
      }, ()=>{
        this.firebaseChatlistUpdate();
      }
    );

    
  };

  messageSubmitTextRender = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.messageSendText();
        }}
      >
        <div className="uk-grid-small" uk-grid="">
          <div className="uk-width-expand">
            <textarea
              type="text"
              className="uk-textarea"
              rows="3"
              placeholder="ข้อความ"
              value={this.state.chatMessage}
              onChange={(e) => this.setState({ chatMessage: e.target.value })}
            />
          </div>
          <div className="uk-width-auto">
            {this.state.messageListSending ? (
              <div className="uk-text-center">
                <span uk-spinner=""></span>
              </div>
            ) : (
              <button type="submit" className="uk-button uk-button-primary">
                บันทึก
              </button>
            )}
          </div>
        </div>
      </form>
    );
  };

  messageSubmitImageRender = () => {
    return (
      <div>
        <div>
          {
          this.state.chatMessageImage.map((file)=>{
            return <span className="uk-margin-right uk-inline">
            <a className="d-teleconsult-media-delete-button" uk-icon="icon: trash; ratio: 0.5" onClick={()=>{
              var _image = this.state.chatMessageImage || [];

              var _index_of_file = _image.indexOf(file);
              
              if(_index_of_file >= 0) {
                delete _image[_index_of_file];

                _image = _image.filter(Boolean);
              }

              this.setState({chatMessageImage: _image});
            }}></a>
            {<Media file={file} />}
          </span>
          })
        }
        </div>
        <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
          แนบรูป
          <UploadFirebase
            maxWidth={1000}
            maxHeight={1000}
            cb={(image) => {
              var _image = this.state.chatMessageImage
              if(!Array.isArray(_image)) {
                _image = [];
              }

              _image.push(image);


              this.setState({ chatMessageImage : _image });
            }}
          />
        </label>
        <div className="uk-margin-top uk-text-right">
            {this.state.messageListSending ? (
              <div className="uk-text-center">
                <span uk-spinner=""></span>
              </div>
            ) : (
              <button type="button" onClick={()=>this.messageSendImage()} className="uk-button uk-button-primary">
                บันทึก
              </button>
            )}
        </div>
      </div>
    );
  };

  messageSubmitFileRender = () => {
    return (
      <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
        ส่งไฟล์
        <UploadFirebase
          maxWidth={1000}
          maxHeight={1000}
          accept={"application/pdf"}
          cb={(chatMessageFile) => {
            this.setState({ chatMessageFile }, () => this.messageSendFile());
          }}
        />
      </label>
    );
  };

  messageSubmitFoodRender = () => {
    return (
      <div className="uk-grid-small uk-grid uk-grid-margin" uk-grid="">
        <div className="uk-width-1-1 uk-width-1-5@l">
          {this.state.chatMessageFoodImage === "" ? (
            <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
              ส่งรูปอาหาร
              <UploadFirebase
                maxWidth={1000}
                maxHeight={1000}
                cb={(chatMessageFoodImage) => {
                  this.setState({ chatMessageFoodImage });
                }}
              />
            </label>
          ) : (
            <img uk-img="" src={ImageUri(this.state.chatMessageFoodImage)} />
          )}
        </div>
        <div className="uk-width-1-2 uk-width-1-5@l">
          <input
            type="text"
            className="uk-input"
            placeholder="ชื่ออาหาร"
            value={this.state.chatMessageFoodName}
            onChange={(e) =>
              this.setState({ chatMessageFoodName: e.target.value })
            }
          />
        </div>
        <div className="uk-width-1-2 uk-width-1-5@l">
          <div
            className="d-button-group input-group uk-button-group date"
            id="datetimepickerFood"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-expand">
          <textarea
            rows="3"
            className="uk-textarea"
            placeholder="อธิบายรายละเอียดอาหารโดยละเอียด เช่น ข้าวสวย 1 ถ้วย ทุเรียน 1 พู"
            value={this.state.chatMessageFoodCaption}
            onChange={(e) =>
              this.setState({ chatMessageFoodCaption: e.target.value })
            }
          />
        </div>
        <div className="uk-width-auto">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendFood()}
            >
              บันทึก
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitBloodRender = () => {
    return (
      <div className="uk-grid-small uk-flex-middle" uk-grid="">
        <div className="uk-width-1-1 uk-width-auto@l uk-visible@l">
          ความดันโลหิต
        </div>
        <div className="uk-width-1-1 uk-width-1-4@l">
          <div className="uk-grid-small uk-flex-middle" uk-grid="">
            <div className="uk-width-1-3">
              <input
                type="text"
                className="uk-input"
                placeholder="ตัวบน"
                value={this.state.chatMessageBloodSys}
                onChange={(e) =>
                  this.setState({ chatMessageBloodSys: e.target.value })
                }
              />
            </div>
            <div className="uk-width-1-3">
              <input
                type="text"
                className="uk-input"
                placeholder="ตัวล่าง"
                value={this.state.chatMessageBloodDias}
                onChange={(e) =>
                  this.setState({ chatMessageBloodDias: e.target.value })
                }
              />
            </div>
            <div className="uk-width-1-3">mm/Hg</div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@l">
          <select
            className="uk-select"
            value={this.state.chatMessageBloodTime}
            onChange={(e) =>
              this.setState({ chatMessageBloodTime: e.target.value })
            }
          >
            <option value="ก่อนนอน">ก่อนนอน</option>
            <option value="ตื่นนอน">ตื่นนอน</option>
          </select>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@l">
          <div
            className="d-button-group input-group uk-button-group date"
            id="datetimepickerBlood"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-expand@l uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendBlood()}
            >
              บันทึก
            </button>
          )}
        </div>
      </div>
    );
  };

  covid19AbnormalKey = {
    cough: "ไอ",
    "sore throat": "เจ็บคอ",
    "runny nose": "มีน้ำมูก",
    secretion: "มีเสมหะ",
    "shortness of breathing": "หายใจเหนื่อยหรือลำบาก",
    "burning throat": "แสบคอ,คอแห้ง",
    anosmia: "ได้กลิ่นลดลง",
    "shortness of breathing": "หอบเหนื่อย",
    myalgia: "ปวดกล้ามเนื้อ",
    headache: "ปวดศีรษะ",
    diarrhea: "ถ่ายเหลว",
    "nausea vomiting": "คลื่นไส้ อาเจียน",
  };

  messageCovid19Render = (option) => {
    if(option == null) option = {};
    
    var _covid_19_abnormal_select_render = () => {
      var _option = [];
      for (let i in this.covid19AbnormalKey) {
        _option.push(
          <label
            key={"_covie_" + i}
            className="uk-width-1-2 uk-margin-small-bottom"
          >
            <input
              type="checkbox"
              className="uk-checkbox d-covid-19-select"
              value={i}
            />{" "}
            {this.covid19AbnormalKey[i]} ({i})
          </label>
        );
      }

      return _option;
    };

    return (
      <div uk-grid="">
        <div className="uk-width-2-5@m uk-width-1-1">
          <div className="d-border uk-padding-small uk-margin-small-bottom">
            <div className="uk-margin-small-bottom">
              {this.state.chatMessageCovid19AtkImage === "" ? (
                <label className="uk-button uk-button-default uk-width-1-1">
                  ส่งรูปผลตรวจ ATK (ถ้ามี)
                  <UploadFirebase
                    maxWidth={1000}
                    maxHeight={1000}
                    cb={(chatMessageCovid19AtkImage) => {
                      this.setState({ chatMessageCovid19AtkImage });
                    }}
                  />
                </label>
              ) : (
                <img uk-img="" src={ImageUri(this.state.chatMessageCovid19AtkImage)} />
              )}
            </div>
            <div className="uk-grid uk-grid-small uk-flex-middle" uk-grid="">
              <div className="uk-width-2-5">
                ผลตรวจ ATK
              </div>
              <div className="uk-width-3-5">
                <label>
                  <input type="radio" value="normal" checked={this.state.chatMessageCovid19AtkStatus === 'normal' ? true : false} onChange={()=>this.setState({chatMessageCovid19AtkStatus: 'normal'})} /> ปกติ
                </label>
                <label className="uk-margin-left">
                  <input type="radio" value="abnormal" checked={this.state.chatMessageCovid19AtkStatus === 'abnormal' ? true : false} onChange={()=>this.setState({chatMessageCovid19AtkStatus: 'abnormal'})} /> ผิดปกติ
                </label>
              </div>
              <div className="uk-width-2-5">ตรวจเมื่อ</div>
              <div className="uk-width-3-5">
                <div
                  className="d-button-group input-group uk-button-group date"
                  id={option.datepickerId || "datetimepickerCovid"}
                >
                  <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
                  <span
                    className="uk-button input-group-addon"
                    style={{ width: 45, marginLeft: 0 }}
                  >
                    <span className="glyphicon glyphicon-calendar"></span>
                  </span>
                </div>
                </div>
              </div>
          </div>
          <div className="uk-grid uk-grid-small uk-flex-middle">
            <div className="uk-width-2-5">
              <span>อุณหภูมิ</span>
            </div>
            <div className="uk-width-2-5">
              <input
                type="number"
                className="uk-input uk-text-center"
                placeholder=""
                value={this.state.chatMessageCovid19Temp}
                onChange={(e) =>
                  this.setState({ chatMessageCovid19Temp: e.target.value })
                }
              />
            </div>
            <div className="uk-width-1-5">
              <span>°C</span>
            </div>
          </div>
          {
            option.hideHeight !== true && 
            <div className="uk-grid uk-grid-small uk-flex-middle">
              <div className="uk-width-2-5">
                <span>น้ำหนัก</span>
              </div>
              <div className="uk-width-2-5">
                <input
                  type="number"
                  className="uk-input uk-text-center"
                  placeholder=""
                  value={this.state.chatMessageCovid19Weight}
                  onChange={(e) =>
                    this.setState({ chatMessageCovid19Weight: e.target.value })
                  }
                  required={true}
                />
              </div>
              <div className="uk-width-1-5">
                <span>กิโลกรัม</span>
              </div>
            </div>
          }
          {
            option.hideWeight !== true && <div className="uk-grid uk-grid-small uk-flex-middle">
            <div className="uk-width-2-5">
              <span>ส่วนสูง</span>
            </div>
            <div className="uk-width-2-5">
              <input
                type="number"
                className="uk-input uk-text-center"
                placeholder=""
                value={this.state.chatMessageCovid19Height}
                onChange={(e) =>
                  this.setState({ chatMessageCovid19Height: e.target.value })
                }
                required={true}
              />
            </div>
            <div className="uk-width-1-5">
              <span>เซนติเมตร</span>
            </div>
          </div>
          }
          
          <div className="uk-grid uk-grid-small uk-flex-middle">
            <div className="uk-width-2-5">
              <div>ออกซิเจนในเลือด</div>
              <div>SpO2</div>
            </div>
            <div className="uk-width-2-5">
              <input
                type="number"
                className="uk-input uk-text-center"
                placeholder=""
                value={this.state.chatMessageCovid19O2}
                onChange={(e) => {
                  var _value = +e.target.value;
                  if (_value > 100) _value = 100;
                  this.setState({ chatMessageCovid19O2: _value });
                }}
              />
            </div>
            <div className="uk-width-1-5">
              <span>%</span>
            </div>
          </div>
          <div className="uk-grid uk-grid-small uk-flex-middle">
            <div className="uk-width-2-5">
              <div>อัตราการเต้นหัวใจ</div>
              <div>Heart Rate</div>
            </div>
            <div className="uk-width-2-5">
              <input
                type="number"
                className="uk-input uk-text-center"
                placeholder=""
                value={this.state.chatMessageCovid19HeartRate}
                onChange={(e) =>
                  this.setState({ chatMessageCovid19HeartRate: e.target.value })
                }
              />
            </div>
            <div className="uk-width-1-5">
              <span>ครั้ง/นาที</span>
            </div>
          </div>
          <div className="uk-margin-small-top">
            <div>อาการผิดปกติทางร่างกาย</div>
            <div className="uk-grid uk-grid-small uk-margin-small-top">
              <label
                className="uk-width-1-2"
                onClick={() =>
                  this.setState({ chatMessageCovid19Abnormal: false })
                }
              >
                <input
                  type="radio"
                  className="uk-radio"
                  name="covid-19-abnormal-select"
                  checked={
                    this.state.chatMessageCovid19Abnormal === false
                      ? true
                      : false
                  }
                />{" "}
                ไม่มีอาการ
              </label>
              <label
                className="uk-width-1-2"
                onClick={() =>
                  this.setState({ chatMessageCovid19Abnormal: true })
                }
              >
                <input
                  type="radio"
                  className="uk-radio"
                  name="covid-19-abnormal-select"
                  checked={
                    this.state.chatMessageCovid19Abnormal === true
                      ? true
                      : false
                  }
                />{" "}
                มีอาการ
              </label>
            </div>
          </div>
        </div>
        <div className="uk-width-2-5@m uk-width-1-1">
          {this.state.chatMessageCovid19Abnormal === true && (
            <div className="d-border uk-padding-small">
              <div className="uk-grid uk-grid-small">
                {_covid_19_abnormal_select_render()}
              </div>
            </div>
          )}

          <div className="d-border uk-padding-small uk-margin-small-top">
            <label className="uk-display-block uk-margin-small-bottom">
              <input
                type="checkbox"
                className="uk-checkbox"
                id="d-covid-19-risk-area-select"
              />{" "}
              ไปในพื้นที่เสี่ยง (Travelled or lived in COVID-19 outbreak
              country)
            </label>
            <label className="uk-display-block uk-margin-small-bottom">
              <input
                type="checkbox"
                className="uk-checkbox"
                id="d-covid-19-risk-contact-select"
              />{" "}
              ใกล้ชิดกลุ่มเสี่ยง (Closed contact with hig risk, suspected or
              confirmed COVID-19 cases)
            </label>
          </div>
        </div>
        {
          option.hideSubmitButton !== true && <div className="uk-width-1-5@m uk-width-1-1 uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={this.messageSendCovid19}
            >
              บันทึก
            </button>
          )}
        </div>
        }
        
      </div>
    );
  };

  messageSubmitSugarRender = () => {
    var _timeFood = [
      "ก่อนอาหาร",
      "หลังอาหาร",
      "หลังอาหาร 1 ชั่วโมง",
      "หลังอาหาร 2 ชั่วโมง",
    ];
    var _time = ["เช้า", "อาหารว่างเช้า", "กลางวัน", "อาหารว่างบ่าย", "เย็น"];

    var _timeBox = (
      <div uk-grid="" className="uk-grid-collapse">
        <div className="uk-width-1-2">
          {_timeFood.map((d) => (
            <div
              key={d}
              className={
                "d-sugar-time-button d-sugar-time-food-button " +
                (this.state.chatMessageSugarTimeFood === d
                  ? "d-sugar-time-button-active"
                  : "")
              }
              onClick={() => this.setState({ chatMessageSugarTimeFood: d })}
            >
              {d}
            </div>
          ))}
        </div>
        <div className="uk-width-1-2">
          {_time.map((d) => (
            <div
              key={d}
              className={
                "d-sugar-time-button " +
                (this.state.chatMessageSugarTime === d
                  ? "d-sugar-time-button-active"
                  : "")
              }
              onClick={() => this.setState({ chatMessageSugarTime: d })}
            >
              {d}
            </div>
          ))}
        </div>
        <div className="uk-width-1-1">
          <div
            className={
              "d-sugar-time-button " +
              (this.state.chatMessageSugarTime === "ก่อนนอน"
                ? "d-sugar-time-button-active"
                : "")
            }
            onClick={() =>
              this.setState({
                chatMessageSugarTime: "ก่อนนอน",
                chatMessageSugarTimeFood: "",
              })
            }
          >
            ก่อนนอน
          </div>
        </div>
        <div className="uk-width-1-1">
          <div
            className={
              "d-sugar-time-button " +
              (this.state.chatMessageSugarTime === "กลางคืน"
                ? "d-sugar-time-button-active"
                : "")
            }
            onClick={() =>
              this.setState({
                chatMessageSugarTime: "กลางคืน",
                chatMessageSugarTimeFood: "",
              })
            }
          >
            กลางคืน
          </div>
        </div>
      </div>
    );

    return (
      <div className="uk-grid-small uk-flex-middle" uk-grid="">
        <div className="uk-width-1-1 uk-width-auto uk-visible@l">
          น้ำตาลในเลือด
        </div>
        <div className="uk-width-1-1 uk-width-1-5@l">
          <div className="uk-button-group uk-flex-middle">
            <input
              type="number"
              className="uk-input"
              placeholder="fbs/fpg"
              style={{ width: 80 }}
              value={this.state.chatMessageSugar}
              onChange={(e) =>
                this.setState({ chatMessageSugar: e.target.value })
              }
            />
            <div style={{ width: 50, marginLeft: 10 }}>mg/dL</div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-5@l">
          <div className="uk-inline">
            <button className="uk-button uk-button-default" type="button">
              เลือกเวลาตรวจ
            </button>
            <div
              id="d-sugar-time-container"
              className="uk-padding-remove"
              uk-dropdown="mode: click; pos: top;"
            >
              {_timeBox}
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-5@l">
          <div className="uk-text-meta uk-margin-small-top">
            {this.state.chatMessageSugarTimeFood}{" "}
            {this.state.chatMessageSugarTime}{" "}
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-5@l">
          <div
            className="d-button-group input-group uk-button-group date"
            id="datetimepickerSugar"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-expand@l uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendSugar()}
            >
              บันทึก
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitWeightRender = () => {
    return (
      <div className="uk-grid-small" uk-grid="">
        <div className="uk-width-1-1 uk-width-expand@l">
          <div className="uk-grid-small uk-flex-middle" uk-grid="">
            <div className="uk-width-1-2@m uk-width-1-1">
              <div className="uk-flex-middle">
                <div className="uk-display-inline-block">น้ำหนัก</div>
                <div className="uk-display-inline-block uk-margin-small-left uk-margin-small-right">
                  <input
                    type="number"
                    className="uk-input uk-width-small"
                    placeholder="น้ำหนัก"
                    value={this.state.chatMessageWeight}
                    onChange={(e) =>
                      this.setState({ chatMessageWeight: e.target.value })
                    }
                  />
                </div>
                <div className="uk-display-inline-block">กิโลกรัม</div>
              </div>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <div className="uk-flex-middle">
                <div className="uk-display-inline-block">รอบเอว</div>
                <div className="uk-display-inline-block uk-margin-small-left uk-margin-small-right">
                  <input
                    type="number"
                    className="uk-input uk-width-small"
                    placeholder="รอบเอว"
                    value={this.state.chatMessageWaistLine}
                    onChange={(e) =>
                      this.setState({ chatMessageWaistLine: e.target.value })
                    }
                  />
                </div>
                <div className="uk-display-inline-block">นิ้ว</div>
              </div>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <div className="uk-flex-middle">
                <div className="uk-display-inline-block">ส่วนสูง</div>
                <div className="uk-display-inline-block uk-margin-small-left uk-margin-small-right">
                  <input
                    type="number"
                    className="uk-input uk-width-small"
                    placeholder="ส่วนสูง"
                    value={this.state.chatMessageHeight}
                    onChange={(e) =>
                      this.setState({ chatMessageHeight: e.target.value })
                    }
                  />
                </div>
                <div className="uk-display-inline-block">เซนติเมตร</div>
              </div>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1">
              <div className="uk-flex-middle">
                <div className="uk-display-inline-block">ไขมัน</div>
                <div className="uk-display-inline-block uk-margin-small-left uk-margin-small-right">
                  <input
                    type="number"
                    className="uk-input uk-width-small"
                    placeholder="ไขมัน"
                    value={this.state.chatMessageFat}
                    onChange={(e) => {
                      var _value = +e.target.value;
                      if (_value > 100) _value = 100;

                      this.setState({ chatMessageFat: _value });
                    }}
                  />
                </div>
                <div className="uk-display-inline-block">%</div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-width-1-1 uk-width-1-4@l">
          <div
            className="d-button-group input-group uk-button-group date"
            id="datetimepickerWeight"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@l uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendWeight()}
            >
              บันทึก
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitDiabetesRender = () => {
    var _body = [];

    var _diabates_value_update = (key, value) => {
      this.setState((state) => {
        state.chatMessageDiabetes[key] = value;
        return { chatMessageDiabetes: state.chatMessageDiabetes };
      });
    };

    var _input_render = (name) => {
      return (
        <td>
          <input
            type="text"
            className="uk-input"
            value={this.state.chatMessageDiabetes[name]}
            onChange={(e) => {
              _diabates_value_update(name, e.target.value);
            }}
          />
        </td>
      );
    };

    this.diabetes.meal.forEach((meal, index) => {
      var _row = [
        <td key={`_meal_${meal}_${index}`}>
          <div>{this.diabetes.meal_name[meal]}</div>
        </td>,
      ];

      this.diabetes.time.forEach((time) => {
        _row.push(_input_render(`${meal}_${time}`));
      });

      this.diabetes.drug.forEach((drug) => {
        _row.push(_input_render(`${meal}_${drug}`));
      });

      this.diabetes.food.forEach((food) => {
        _row.push(_input_render(`${meal}_${food}`));
      });

      _body.push(<tr key={"_meal" + index}>{_row}</tr>);
    });

    return (
      <div className="uk-grid-small uk-flex-top" uk-grid="">
        <div className="uk-width-1-1 uk-width-1-5@l">
          <div
            className="d-button-group uk-width-1-1 input-group uk-button-group date"
            id="datetimepickerDiabetes"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-expand@l uk-text-right">
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-divider d-table-diabates-data">
              <thead>
                <tr>
                  <th rowSpan="2">มื้ออาหาร</th>
                  <th colSpan="2">ค่าน้ำตาล</th>
                  <th rowSpan="2">ยาฉีดอินซูลิน</th>
                  <th colSpan="2">บันทึกอาหาร</th>
                </tr>
                <tr>
                  <th>ก่อนอาหาร</th>
                  <th>หลังอาหาร</th>
                  <th>มื้อหลัก</th>
                  <th>มื้อว่าง</th>
                </tr>
              </thead>
              <tbody>
                {_body}
                <tr>
                  <td>
                    <div>หมายเหตุ</div>
                  </td>
                  <td colSpan="6">
                    <input
                      type="text"
                      className="uk-input"
                      value={this.state.chatMessageDiabetes.note}
                      onChange={(e) => {
                        _diabates_value_update("note", e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-auto@l uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendDiabates()}
            >
              บันทึก
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitAppointmentRender = () => {
    return (
      <div className="uk-grid-small uk-grid uk-grid-margin">
        <div className="uk-width-1-1 uk-width-1-5@m">
          <label>ช่องทาง</label>
          <select
            value={this.state.chatMessageAppointmentType}
            onChange={(e) =>
              this.setState({ chatMessageAppointmentType: e.target.value })
            }
            className="uk-select uk-margin-small-bottom"
          >
            {["วีดีโอคอล", "แชท", "โทรศัพท์"].map(
              (option_value, option_index) => (
                <option
                  value={option_value}
                  key={"appointment_" + option_index}
                >
                  {option_value}
                </option>
              )
            )}
          </select>
        </div>
        <div className="uk-width-2-3 uk-width-1-5@m">
          <label>วันที่</label>
          <div
            className="d-button-group input-group uk-button-group date"
            id="datetimepickerAppointment"
          >
            <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
            <span
              className="uk-button input-group-addon"
              style={{ width: 45, marginLeft: 0 }}
            >
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-5@m">
          <label>นัดหมายเพื่อ</label>
          <select
            value={this.state.chatMessageAppointmentName}
            onChange={(e) =>
              this.setState({ chatMessageAppointmentName: e.target.value })
            }
            className="uk-select uk-margin-small-bottom"
          >
            {["ติดตามอาการ", "ตรวจเพิ่มเติม", "อื่นๆ"].map(
              (option_value, option_index) => (
                <option value={option_value} key={"for_" + option_index}>
                  {option_value}
                </option>
              )
            )}
          </select>
        </div>
        <div className="uk-width-1-1 uk-width-1-5@m">
          <label>นัดหมายสำหรับ</label>
          <select
            defaultValue={this.state.chatMessageAppointmentBy}
            onChange={(e) =>
              this.setState({ chatMessageAppointmentBy: e.target.value })
            }
            className="uk-select uk-margin-small-bottom"
          >
            {this.state.consultInCampaign.map( (option_value, option_index) => {
              return <option value={option_value.id} key={"by_" + option_index}> {
                this.consultProfile.id == option_value.id ? 'ตนเอง' : `${option_value.nameprefix} ${option_value.name} ${option_value.lastname}`
              }
              
            </option>
            })}
          </select>
          
        </div>
        <div className="uk-width-1-1 uk-width-expand@m">
          <label>หมายเหตุ</label>
          <textarea
            className="uk-textarea"
            value={this.state.chatMessageAppointmentNote}
            onChange={(e) =>
              this.setState({ chatMessageAppointmentNote: e.target.value })
            }
          ></textarea>
        </div>
        <div className="uk-width-1-3 uk-width-auto@m uk-text-right">
          <p></p>
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendAppointment()}
            >
              ส่งคำร้องการจอง
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitPaymentRender = () => {
    return (
      <div className="uk-grid-small uk-grid uk-grid-margin">
        <div className="uk-width-1-1 uk-width-1-2@m">
          <textarea
            className="uk-textarea uk-margin-small-bottom"
            value={this.state.chatMessagePaymentDetail}
            onChange={(e) =>
              this.setState({ chatMessagePaymentDetail: e.target.value })
            }
            placeholder="รายละเอียด"
          ></textarea>
        </div>
        <div className="uk-width-2-3 uk-width-1-4@m">
          <input
            type="number"
            className="uk-input uk-width-1-1"
            placeholder="ยอดเงิน(บาท)"
            value={this.state.chatMessagePaymentPrice}
            onChange={(e) =>
              this.setState({ chatMessagePaymentPrice: e.target.value })
            }
          />
        </div>
        <div className="uk-width-1-3 uk-width-1-4@m uk-text-right">
          {this.state.messageListSending ? (
            <div className="uk-text-center">
              <span uk-spinner=""></span>
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              onClick={() => this.messageSendPayment()}
            >
              Send
            </button>
          )}
        </div>
      </div>
    );
  };

  messageSubmitVisitNoteRender = () => {
    return (<div>
      <div><b>ชื่อ-นามสกุล ผู้บันทึก :</b> {this.consultProfile.fullname}</div>
      <div className="uk-margin-small-top"><b>วันที่ : </b>
        <div
          className="d-button-group input-group uk-button-group date"
          id="datetimepickerVisitNote"
        >
          <input type="text" className="uk-input" placeholder="วันที่/เวลา" />
          <span
            className="uk-button input-group-addon"
            style={{ width: 45, marginLeft: 0 }}
          >
            <span className="glyphicon glyphicon-calendar"></span>
          </span>
        </div>
      </div>
      <div className="uk-margin-small-top">
        <b>เลขผู้ป่วย (HN) :</b> <input type="text" className="uk-input uk-width-small" placeholder="เลขผู้ป่วย (HN)" value={this.state.chatMessageVisitNoteHn} onChange={(e)=>this.setState({chatMessageVisitNoteHn: e.target.value})} />
      </div>
      <div className="uk-margin-small-top"><b>รายละเอียด :</b> </div>
      <textarea className="uk-textarea" rows={5} value={this.state.chatMessageVisitNoteDetail} onChange={(e)=>this.setState({chatMessageVisitNoteDetail: e.target.value})}></textarea>
      <div className="uk-margin-small-top"><b>แนบรูป :</b> </div>
      {this.state.chatMessageVisitNoteImage ?
      
      <div uk-lightbox="">
        <a href={ImageUri(this.state.chatMessageVisitNoteImage)}>
          <img
            uk-img=""
            src={ImageUri(this.state.chatMessageVisitNoteImage)}
            width="300"
            className="uk-margin-small-bottom"
          />
        </a>
        <div className="uk-margin-top">

          <a className="uk-text-danger" onClick={()=>{
            this.setState({chatMessageVisitNoteImage: ''})
          }}>ลบรูปภาพ</a>
        </div>
      </div>
      :
      <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
        ส่งรูป
        <UploadFirebase
          maxWidth={1000}
          maxHeight={1000}
          cb={(chatMessageVisitNoteImage) => {
            this.setState({chatMessageVisitNoteImage});
          }}
        />
      </label>
      }
      
      <div className="uk-text-right uk-margin-top">
        {this.state.messageListSending ? (
          <div className="uk-text-center">
            <span uk-spinner=""></span>
          </div>
        ) : (
          <button
            className="uk-button uk-button-primary"
            onClick={() => this.messageSendVisitNote()}
          >
            Send
          </button>
        )}
      </div>
    </div>)
  }

  messageSubmitShippingCallRender = () => {
    return <div>
      <div><b>กรุณาบันทึกเลขใบสั่งยาของสถานพยาบาลหรือเลือกออกใบสั่งยาจากระบบไดเอทซ์ เลือกอย่างใดอย่างหนึ่ง</b></div>
      
      <div>
        <label>
          <div uk-grid="">
            <div className="uk-width-auto">
              <input type="radio" name="shippingCallType" value="custom" />
            </div>
            <div className="uk-width-expand">
              <div>ใช้ใบสั่งยาของสถานพยาบาล กรุณาระบุเลขใบสั่งยา กรณีใบสั่งยาหลายใบส่งในคราวเดียวกันให้คั่นด้วย ,
              </div>
              <div>
                <input type="text" className="uk-input" placeholder="เลขที่ใบสั่งยาคั่นด้วย ," />
              </div> 
            </div>
          </div>
        </label>
      </div>
      <div>
        <label>
          <div uk-grid="">
            <div className="uk-width-auto">
              <input type="radio" name="shippingCallType" value="custom" />
            </div>
            <div className="uk-width-expand">
              ออกใบสั่งยาจากระบบไดเอทซ์
            </div>
          </div>
        </label>
      </div>
      <div className="uk-text-right">
        <button className="uk-button uk-button-primary" onClick={()=>{
          this.setState({ prescriptionShow: true });
        }} >ถัดไป</button>
      </div>
    </div>
  }

  dialysisHistoryGet = async () => {
    var _response = await Api[
      this.apiType
    ](
      "dialysisHistoryGet",
      {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId
      }
    );

    if(Array.isArray(_response.list)) {
      _response.list.forEach((data) => {
        try {
          data.cdi_detail = JSON.parse(data.cdi_detail)

        } catch(e) {
          data.cdi_detail = [];
        }
      })
    }

    this.setState({dialysisHistory: _response.list || []});
  }

  dialysisHistoryUpdate = async (data) => {
    var _response = await Api.member("dialysisHistoryUpdate", {
      id: data.id,
      detail: JSON.stringify(data.cdi_detail),
      dateadd: data.cdi_dateadd
    });

    if(_response.result) {
      data.edit = false;

      this.setState({dialysisHistory: this.state.dialysisHistory});

      UIkit.notification({message: _response.responseText});
    }
  }

  dialysisFormRender = (detail) => {

    var _concentration = ["1.5%", "2.5%", "4.25%"];

    var _select_concentration_render = (row, column) => {
      if (!detail[row][column])
      detail[row][column] = _concentration[0];

      return (
        <select
          className="uk-select"
          value={detail[row][column]}
          onChange={(e) => {
            detail[row][column] = e.target.value;

            this.setState({
              chatMessageDialysisDetail: detail,
            });
          }}
        >
          {_concentration.map((concentration, index) => {
            return (
              <option key={`concentration${index}${row}`} value={concentration}>
                {concentration}
              </option>
            );
          })}
        </select>
      );
    };

    var _color = ["เหลืองใส", "เหลืองขุ่น", "แดง", "อื่นๆ", ""];

    var _select_color_render = (row, column) => {
      if (!detail[row][column])
      detail[row][column] = "";

      return (
        <select
          className="uk-select"
          style={{
            background:
            detail[row][column] === "" ||
            detail[row][column] === "เหลืองใส"
                ? "#FFF"
                : "yellow",
          }}
          value={detail[row][column]}
          onChange={(e) => {
            detail[row][column] = e.target.value;

            this.setState({
              chatMessageDialysisDetail: detail,
            });
          }}
        >
          {_color.map((color, index) => {
            return (
              <option key={`color${index}${row}`} value={color}>
                {color}
              </option>
            );
          })}
        </select>
      );
    };

    var _input_render = (row, column, type, style) => {
      if (!style) style = {};

      var _type = "text";

      if ([1, 2, 4, 5].includes(column)) {
        _type = "time";
      } else if ([3, 6, 7, 8, 10].includes(column)) {
        _type = "number";

        if ([7, 8].includes(column)) {
          if (
            !detail[row][column] &&
            detail[row][3] &&
            detail[row][6]
          )
            detail[row][column] = 0;
        }
      }

      return (
        <input
          style={style}
          type={_type}
          className="uk-input uk-text-center"
          value={detail[row][column]}
          onChange={(e) => {
            detail[row][column] = e.target.value;

            if (column === 3 || column === 6) {
              if (
                !isNaN(detail[row][3]) &&
                !isNaN(detail[row][6])
              ) {
                detail[row][7] =
                  +detail[row][6] -
                  +detail[row][3];

                var _sum_volume = 0;

                [...Array(5)].map((data, index) => {
                  _sum_volume +=
                    +detail[index][7];
                });

                detail[0][8] = +_sum_volume;
              }
            }

            this.setState({
              chatMessageDialysisDetail: detail,
            });
          }}
        />
      );
    };

    var _row = [];

    for (let i = 0; i < 5; i++) {
      if (!detail[i]) {
        detail[i] = [];
      }

      _row.push(
        <tr key={`rowdialysis${i}`}>
          <td className="uk-text-center">{i + 1}</td>
          {[...Array(13)].map((data, index) => {
            if (!detail[i][index]) {
              detail[i][index] = "";
            }

            if (index === 0) {
              return <td>{_select_concentration_render(i, index)}</td>;
            } else if (index === 9) {
              return <td>{_select_color_render(i, index)}</td>;
            } else if (index === 8) {
              if (i === 0) {
                return <td rowSpan="5">{_input_render(i, index)}</td>;
              } else {
                return null;
              }
            } else if (index === 7) {
              var _style = {};

              if (detail[i][index] !== "") {
                if (+detail[i][index] >= 1000) {
                  _style = {
                    background: "green",
                    color: "#FFFFFF",
                  };
                } else if (
                  +detail[i][index] >= 900
                ) {
                  _style = {
                    background: "yellow",
                  };
                } else {
                  _style = {
                    background: "red",
                    color: "#FFFFFF",
                  };
                }
              }

              return <td>{_input_render(i, index, null, _style)}</td>;
            } else if (index === 11) {
              var _style = {};

              if (detail[i][index] !== "") {
                var _value =
                  detail[i][index].split("/");

                if (_value.length === 2) {
                  if (+_value[0] <= 140 && +_value[1] <= 90) {
                    _style = {
                      background: "green",
                      color: "#FFFFFF",
                    };
                  } else {
                    _style = {
                      background: "red",
                      color: "#FFFFFF",
                    };
                  }
                }
              }

              return <td>{_input_render(i, index, null, _style)}</td>;
            } else {
              return <td>{_input_render(i, index)}</td>;
            }
          })}
        </tr>
      );
    }

    return <div>
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider d-table-diabates-data">
          <thead>
            <tr>
              <th rowSpan={2}>รอบที่</th>
              <th rowSpan={2}>ความเข้มข้นน้ำยา</th>
              <th colSpan={3}>เติมน้ำยาเข้า</th>
              <th colSpan={3}>ปล่อยน้ำยาออก</th>
              <th rowSpan={2}>
                กำไรขาดทุน
                <div>(น้ำเข้า-น้ำออก)</div>
              </th>
              <th rowSpan={2}>ปริมาตรรวมสุทธิ</th>
              <th rowSpan={2}>ลักษณะน้ำยา</th>
              <th rowSpan={2}>
                น้ำหนัก
                <div>(ก.ก.)</div>
              </th>
              <th rowSpan={2}>ความดันโลหิต</th>
              <th rowSpan={2}>
                หมายเหตุ<div>(ปัสสาวะ (ซีซี))</div>
              </th>
            </tr>
            <tr>
              <th>เวลาเริ่ม</th>
              <th>เวลาหมด</th>
              <th>ปริมาตร</th>
              <th>เวลาเริ่ม</th>
              <th>เวลาหมด</th>
              <th>ปริมาตร</th>
            </tr>
          </thead>
          <tbody>{_row}</tbody>
        </table>
      </div>
    </div>
  }

  dialysisHistoryConsult = () => {
    return <div>{
      this.state.dialysisHistory.length === 0 ?
            <div className="uk-padding uk-text-center">ไม่พบรายการ</div>
            :
            this.state.dialysisHistory.map((data, index)=>{
              var _data = data;
              _data.c_type = 'dialysis';

              return <div className="d-dialysis-history-card" key={`dialysisHistory${index}`}>
              <ChatBoxMessageChat data={_data}  chatby = {this.props.chatby} />
              </div>
            })
          }</div>
  }

  messageSumbitDialysisRender = () => {
    

    return (
      <div>
        <div class="d-dialysis-tab-button-container">
          <button className={"d-dialysis-tab-button" + (this.state.dialysisTab === 'form' ? " d-dialysis-tab-button-active" : "")} onClick={()=>this.setState({dialysisTab: 'form' })}>เพิ่มข้อมูลใหม่</button>
          <button className={"d-dialysis-tab-button" + (this.state.dialysisTab === 'history' ? " d-dialysis-tab-button-active" : "")} onClick={()=>this.setState({dialysisTab: 'history' }, ()=>this.dialysisHistoryGet())}>ดูประวัติ</button>
        </div>
        {
          this.state.dialysisTab === 'form' ? <div>
            <div
              className="d-button-group input-group uk-button-group date uk-margin-small-bottom"
              id="datetimepickerDialysis"
            >
              <input
                type="text"
                className="uk-input"
                placeholder="วันที่/เวลา"
              />
              <span
                className="uk-button input-group-addon" 
                style={{ width: 45, marginLeft: 0 }}
              >
                <span className="glyphicon glyphicon-calendar"></span>
              </span>
            </div>
            {this.dialysisFormRender(this.state.chatMessageDialysisDetail)}
            <div className="uk-text-right uk-margin-small-top">
              <button
                className="uk-button uk-button-primary"
                onClick={this.messageSendDialysis}
              >
                บันทึก
              </button>
            </div>
          </div>
          :
          <div>
            
            
            {
            this.state.dialysisHistory.length === 0 ?
            <div className="uk-padding uk-text-center">ไม่พบรายการ</div>
            :
            this.state.dialysisHistory.map((data, index)=>{

              var _data = data;
              
              _data.c_type = 'dialysis';

            return <div className="d-dialysis-history-card" key={`dialysisHistory${index}`}>
              {data.edit === true ?
                  <div>
                        <div
                    className="d-button-group input-group uk-button-group date uk-margin-small-bottom"
                    id={"datetimepickerDialysisEdit"+data.id}
                  >
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="วันที่/เวลา"
                    />
                    <span
                      className="uk-button input-group-addon" 
                      style={{ width: 45, marginLeft: 0 }}
                    >
                      <span className="glyphicon glyphicon-calendar"></span>
                    </span>
                  </div>
                  <a className="uk-float-right" uk-icon="close" onClick={()=>{
                    data.edit = false;

                    this.setState({dialysisHistory: this.state.dialysisHistory});
                  }}></a>
                  {this.dialysisFormRender(data.cdi_detail)}
                  <div className="uk-margin-small-top uk-margin-small-bottom uk-text-right">
                    <button className="uk-button uk-button-primary" onClick={()=>{
                      this.dialysisHistoryUpdate(data);
                    }}>บันทึก</button>
                  </div>
                </div>
                :
                <div>
                  <a className="uk-float-right" uk-icon="pencil" onClick={()=>{
                    data.edit = true;


                    this.setState({dialysisHistory: this.state.dialysisHistory}, ()=>{

                      $("#datetimepickerDialysisEdit" + data.id)
                      .on("dp.change", function (e) {
                        data.cdi_dateadd = e.date.format("YYYY-MM-DD HH:mm");
                      })
                      .datetimepicker({
                        format: "DD/MM/YYYY HH:mm",
                        defaultDate: data.cdi_dateadd,
                      });
                    });
                  }}></a>
                  <ChatBoxMessageChat data={_data}  chatby = {this.props.chatby} />
                </div>
              }
            </div>
          })}
          </div>
        }
      </div>
    );
  };

  messageSubmitWoundTypeAdd = (type) => {
    if (this.state.chatMessageWoundSymptomType.includes(type) !== true) {
      this.state.chatMessageWoundSymptomType.push(type);

      this.setState({
        chatMessageWoundSymptomType: this.state.chatMessageWoundSymptomType,
      });
    }
  };

  messageSubmitWoundTypeRemove = (type) => {
    this.state.chatMessageWoundSymptomType.forEach((data, index) => {
      if (data === type) delete this.state.chatMessageWoundSymptomType[index];
    });

    this.setState({
      chatMessageWoundSymptomType:
        this.state.chatMessageWoundSymptomType.filter(Boolean),
    });
  };

  messageSubmitWoundTabLoaded = false;

  messageSubmitWoundTabLoad = async () => {
    this.messageSubmitWoundTabLoaded = true;

    var _respone = await Api.member("WoundTabLoad", {
      consultId: this.state.messageConsultId,
      campaignId: this.state.messageCampaignId,
    });

    this.setState({ WoundTabData: _respone.data || [] });
  };

  messageSubmitWound = () => {
    if (this.messageSubmitWoundTabLoaded !== true) {
      this.messageSubmitWoundTabLoad();
    }

    return (
      <div>
        <ul id="d-wound-tab" class="uk-subnav uk-subnav-pill" uk-switcher="">
          <li>
            <a href="#">ส่งรูปภาพแผล</a>
          </li>
          <li>
            <a href="#">ดูภาพแผลเปรียบเทียบ</a>
          </li>
        </ul>

        <ul class="uk-switcher uk-margin">
          <li>
            <div uk-grid="">
              <div className="uk-width-1-1 uk-width-expand@m">
                <div uk-grid="" className="uk-grid-small uk-flex-top">
                  <div class="uk-width-1-1 uk-width-1-3@m">
                    {this.state.chatMessageWoundSymptomImage && (
                      <img
                        src={ImageUri(this.state.chatMessageWoundSymptomImage)}
                        uk-img=""
                      />
                    )}
                    <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
                      ส่งรูป
                      <UploadFirebase
                        maxWidth={1000}
                        maxHeight={1000}
                        cb={(chatMessageWoundSymptomImage) => {
                          this.setState({ chatMessageWoundSymptomImage });
                        }}
                      />
                    </label>
                  </div>
                  <div class="uk-width-1-1 uk-width-1-3@m">
                    <input
                      type="text"
                      className="uk-input"
                      placeholder="บริเวณที่เป็น"
                      value={this.state.chatMessageWoundSymptomArea}
                      onChange={(e) => {
                        this.setState({
                          chatMessageWoundSymptomArea: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div class="uk-width-1-1 uk-width-1-3@m">
                    <div
                      className="d-button-group input-group uk-button-group date uk-margin-small-bottom"
                      id="datetimepickerWoundSymptom"
                    >
                      <input
                        type="text"
                        className="uk-input"
                        placeholder="วันที่/เวลา"
                      />
                      <span
                        className="uk-button input-group-addon"
                        style={{ width: 45, marginLeft: 0 }}
                      >
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                  </div>
                  <div className="uk-width-1-1">
                    <label>รายละเอียด</label>
                    <textarea
                      className="uk-textarea"
                      placeholder="รายละเอียด"
                      value={this.state.chatMessageWoundSymptomDetail}
                      onChange={(e) => {
                        this.setState({
                          chatMessageWoundSymptomDetail: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div className="uk-width-1-1">
                    <label>อาการ</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="d-wound-symptom"
                          checked={
                            this.state.chatMessageWoundSymptom === "ไม่มีอาการ"
                              ? true
                              : false
                          }
                          onChange={() =>
                            this.setState({
                              chatMessageWoundSymptom: "ไม่มีอาการ",
                            })
                          }
                        />{" "}
                        ไม่มีอาการ
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="d-wound-symptom"
                          checked={
                            this.state.chatMessageWoundSymptom === "มีอาการ"
                              ? true
                              : false
                          }
                          onChange={() =>
                            this.setState({
                              chatMessageWoundSymptom: "มีอาการ",
                            })
                          }
                        />{" "}
                        มีอาการ ระบุ
                      </label>
                      <div className="uk-margin-small-left">
                        <div
                          className="uk-grid-collapse uk-child-width-1-1 uk-child-width-1-3@s"
                          uk-grid=""
                        >
                          {[
                            "มีอาการปวดบริเวณแผล",
                            "แผลบวมอักเสบ",
                            "แผลซึมหรือแผลมีหนอง",
                            "แผลผ่าตัดแยกออก",
                            "อื่นๆ",
                          ].map((type, index) => {
                            return (
                              <div key={`checkbox${index}`}>
                                <label>
                                  <input
                                    type="checkbox"
                                    value={type}
                                    checked={
                                      this.state.chatMessageWoundSymptomType.includes(
                                        type
                                      ) === true
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      if (
                                        this.state.chatMessageWoundSymptomType.includes(
                                          type
                                        ) === true
                                      ) {
                                        this.messageSubmitWoundTypeRemove(
                                          type
                                        );
                                      } else {
                                        this.messageSubmitWoundTypeAdd(type);
                                      }
                                    }}
                                  />{" "}
                                  {type}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-auto@m uk-text-right">
                <br />
                <button
                  className="uk-button uk-button-primary"
                  onClick={this.messageSendWound}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </li>
          <li>
            <div className="uk-overflow-auto d-width-100">
              {this.state.WoundTabData.length > 0 ? (
                this.state.WoundTabData.map((data) => {
                  return (
                    <div className="uk-card uk-card-default uk-padding-small uk-width-medium uk-display-inline-block uk-margin-small-right uk-margin-small-bottom d-vertical-align-top">
                      <div>
                        <div uk-lightbox="">
                          <a href={ImageUri(data.image)}>
                            <img
                              uk-img=""
                              src={ImageUri(data.image)}
                              width="360"
                              className="uk-margin-small-bottom"
                            />
                          </a>
                        </div>
                      </div>
                      {this.props.chatby === "m" && +data.rating > 0 && (
                        <div>
                          <Rate
                            readonly={true}
                            initialRating={data.rating}
                            stop={5}
                          />{" "}
                          <span>/ {numeral(data.rating).format("0.0")}</span>
                        </div>
                      )}

                      <div>
                        <span className="d-chatwound-bold">
                          บริเวณที่เป็น:{" "}
                        </span>{" "}
                        {data.area}
                      </div>
                      <div>
                        <span className="d-chatwound-bold">วันที่/เวลา: </span>{" "}
                        {moment(data.dateadd).format("DD/MM/YYYY เวลา HH:mm")}
                      </div>
                      <div>
                        <span className="d-chatwound-bold">
                          อธิบายรายละเอียด:{" "}
                        </span>{" "}
                        {data.detail}
                      </div>
                      <div>
                        <span className="d-chatwound-bold">อาการ: </span>{" "}
                        {data.symptom}
                      </div>
                      {Array.isArray(data.symptomtype) === true &&
                        data.symptomtype.length > 0 && (
                          <ul className="uk-margin-remove">
                            {data.symptomtype.map((symptomtype) => {
                              return <li>{symptomtype}</li>;
                            })}
                          </ul>
                        )}
                      <div></div>
                      {this.props.chatby === "m" && +data.rating > 0 && (
                        <div>
                          <div>
                            <span className="d-chatwound-bold">คำแนะนำ: </span>{" "}
                            {data.suggest}
                          </div>
                          <div>
                            <span className="d-chatwound-bold">
                              วันที่/เวลาที่แนะนำ:{" "}
                            </span>{" "}
                            {moment(data.suggestdate).format(
                              "DD/MM/YYYY เวลา HH:mm"
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="uk-padding uk-text-center">ไม่พบรายการ</div>
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  };

  messageSubmitPatientProfileAddressInput = (func, type, placeholder) => {
    return <Autocomplete 
            wrapperStyle={{width: '100%'}}
            renderInput={(props)=> {
              return <input
                {...props}
                className="uk-input"
                placeholder={placeholder}
                value={this.state[`chatMessagePatientProfileAddress${type}`]}
                onChange={(e) => {
                  this.setState({[`chatMessagePatientProfileAddress${type}`]: e.target.value}, ()=>{
                    this.setState({[`address${type}Option`] : 
                    func(this.state[`chatMessagePatientProfileAddress${type}`]).map((data)=>{
                      return {
                        label: `${data.district} ${data.amphoe} ${data.province} ${data.zipcode}`, 
                        value: data.zipcode,
                        raw: data
                      }
                      })
                    });
                  })

                }}
              />
              
            }}
            menuStyle= {{
              fontSize: '18px'
            }}
            onSelect={(value, data)=> {
              this.setState({
                chatMessagePatientProfileAddressZipcode: data.raw.zipcode,
                chatMessagePatientProfileAddressDistrict : data.raw.district,
                chatMessagePatientProfileAddressAmphoe: data.raw.amphoe,
                chatMessagePatientProfileAddressProvince: data.raw.province,
              })
            }}
            getItemValue={(item) => item.value}
            items={this.state[`address${type}Option`]}
            renderItem={(item, isHighlighted) =>
              <div key={item.label} style={{ padding:'5px 10px', background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
              </div>
            }
          />
  }

  messageSubmitPatientProfile = () => {
    return <div>
      <div>ลักษณะอาการ</div>
      <textarea
        className="uk-textarea"
        placeholder="อาการที่ต้องการปรึกษา"
        value={this.state.chatMessagePatientProfileSymptom}
        onChange={(e) => {

          this.setState({ chatMessagePatientProfileSymptom: e.target.value });
        }}
      />
      <div className="uk-margin-small-top">เป็นมาแล้วกี่วัน</div>
      <input
        type="number"
        className="uk-input uk-width-small"
        placeholder="0"
        value={this.state.chatMessagePatientProfileSymptomDay}
        onChange={(e) => {

          this.setState({ chatMessagePatientProfileSymptomDay: e.target.value });
        }}
      /> วัน
      <div className="uk-margin-small-top">น้ำหนัก</div>
      <input
        type="number"
        className="uk-input uk-width-small"
        placeholder="น้ำหนัก"
        value={this.state.chatMessageWeight}
        onChange={(e) => {

          this.setState({ chatMessageWeight: e.target.value, chatMessageCovid19Weight: e.target.value });
        }}
      /> กิโลกรัม
      <div className="uk-margin-small-top">ส่วนสูง</div>
      <input
        type="number"
        className="uk-input uk-width-small"
        placeholder="ส่วนสูง"
        value={this.state.chatMessageHeight}
        onChange={(e) => {

          this.setState({ chatMessageHeight: e.target.value, chatMessageCovid19Height: e.target.value });
        }}
      /> เซนติเมตร
      <div className="uk-margin-small-top">โรคประจำตัว (ถ้ามี)</div>
      <textarea
        className="uk-textarea"
        placeholder="โรคประจำตัว"
        value={this.state.chatMessagePatientProfileCongenital}
        onChange={(e) => {

          this.setState({ chatMessagePatientProfileCongenital: e.target.value });
        }}
      />
      <div className="uk-margin-small-top">ประวัติการแพ้ยา (ถ้ามี)</div>
      <textarea
        className="uk-textarea"
        placeholder="ประวัติการแพ้ยา"
        value={this.state.chatMessagePatientProfileDrugAllergy}
        onChange={(e) => {

          this.setState({ chatMessagePatientProfileDrugAllergy: e.target.value });
        }}
      />
      <div className="uk-margin-small-top">ใบรับรองแพทย์</div>
      <label><input type="radio" className="uk-radio" checked={this.state.chatMessagePatientProfileMedicalCert} onClick={()=>this.setState({chatMessagePatientProfileMedicalCert: true})}/> ต้องการ</label>
      <label className="uk-margin-small-left"><input type="radio" className="uk-radio" checked={!this.state.chatMessagePatientProfileMedicalCert} onClick={()=>this.setState({chatMessagePatientProfileMedicalCert: false})} /> ไม่ต้องการ</label>
      <div className="uk-margin-small-top">ผู้ป่วยโควิด</div>
      <label><input type="radio" className="uk-radio" checked={this.state.chatMessagePatientProfileIsCovid} onClick={()=>this.setState({chatMessagePatientProfileIsCovid: true}, ()=>{
        this.datepickerGet();
      })}/> ผู้ป่วยโควิด</label>
      <label className="uk-margin-small-left"><input type="radio" className="uk-radio" checked={!this.state.chatMessagePatientProfileIsCovid} onClick={()=>this.setState({chatMessagePatientProfileIsCovid: false})} /> ไม่ใช่ผู้ป่วยโควิด</label>
      {this.state.chatMessagePatientProfileIsCovid === true && <div className="uk-margin-small-top uk-margin-bottom-small">{this.messageCovid19Render({
        hideSubmitButton: true,
        datepickerId : 'datetimepickerCovid2',
        hideHeight: true,
        hideWeight: true
      })}</div>}
      <div className="uk-margin-small-top">วิธีการจัดส่งยา</div>
      <label><input type="radio" className="uk-radio" checked={this.state.chatMessagePatientProfileShipping === 'drivethru'} onClick={()=>this.setState({chatMessagePatientProfileShipping: 'drivethru'}, ()=>{
        this.datepickerGet();
      })}/> Drive thru</label>
      <label className="uk-margin-small-left"><input type="radio" className="uk-radio" checked={this.state.chatMessagePatientProfileShipping === 'ems'} onClick={()=>this.setState({chatMessagePatientProfileShipping: 'ems'})} /> ไปรษณีย์ (EMS)</label>
      <div className="uk-grid uk-grid-small">
        <div className="uk-width-1-3@m uk-width-1-1">

          <div className="uk-margin-small-top">บ้านเลขที่</div>
          <input
            className="uk-input"
            placeholder="บ้านเลขที่"
            value={this.state.chatMessagePatientProfileAddress}
            onChange={(e) => {
              this.setState({ chatMessagePatientProfileAddress: e.target.value });
            }}
            type="text"
          />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">

          <div className="uk-margin-small-top">ซอย</div>
          <input
            className="uk-input"
            placeholder="ซอย"
            value={this.state.chatMessagePatientProfileAddressSoi}
            onChange={(e) => {
              this.setState({ chatMessagePatientProfileAddressSoi: e.target.value });
            }}
            type="text"
          />
          
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">
          <div className="uk-margin-small-top">ถนน</div>
          <input
            className="uk-input"
            placeholder="ถนน"
            value={this.state.chatMessagePatientProfileAddressRoad}
            onChange={(e) => {
              this.setState({ chatMessagePatientProfileAddressRoad: e.target.value });
            }}
            type="text"
          />
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">
          <div className="uk-margin-small-top">รหัสไปรษณีย์</div>
          {this.messageSubmitPatientProfileAddressInput(searchAddressByZipcode, 'Zipcode', "รหัสไปรษณีย์")}
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">
          <div className="uk-margin-small-top">ตำบล</div>
          {this.messageSubmitPatientProfileAddressInput(searchAddressByDistrict, 'District', "ตำบล")}
          
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">
          <div className="uk-margin-small-top">อำเภอ</div>
          {this.messageSubmitPatientProfileAddressInput(searchAddressByAmphoe, 'Amphoe', "อำเภอ")}
        </div>
        <div className="uk-width-1-3@m uk-width-1-1">
          <div className="uk-margin-small-top">จังหวัด</div>
          {this.messageSubmitPatientProfileAddressInput(searchAddressByProvince, 'Province', "จังหวัด")}
        </div>
      </div>

      

      <div className="uk-margin-small-top">
        <div key={`patientProfile1`} className="uk-margin-small-top">
            {this.state[`chatMessagePatientProfileImage1`] === "" ? (
            <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
              ภาพบัตรประชาชนถ่ายคู่กับใบหน้าผู้ป่วย ใช้สำหรับยืนยันการเข้ารับบริการ
              <UploadFirebase
                maxWidth={1000}
                maxHeight={1000}
                cb={(image) => {
                  this.setState({ [`chatMessagePatientProfileImage1`] : image });
                }}
              />
            </label>
          ) : (
            <img uk-img="" src={ImageUri(this.state[`chatMessagePatientProfileImage1`])} height={200} />
          )
        }
        </div>
      </div>
      {
        [2,3,4,5].map((data) => {
          return <div key={`patientProfile${data}`} className="uk-margin-small-top">
            {this.state[`chatMessagePatientProfileImage${data}`] === "" ? (
            <label className="d-submit-food-upload-button uk-button uk-button-default uk-width-1-1">
              ภาพประกอบ {data} {(data === 2 ? 'แนบรูป ATK พร้อมเขียนชื่อกำกับบน ATK' : '')} (ถ้ามี)
              <UploadFirebase
                maxWidth={1000}
                maxHeight={1000}
                cb={(image) => {
                  this.setState({ [`chatMessagePatientProfileImage${data}`] : image });
                }}
              />
            </label>
          ) : (
            <img uk-img="" src={ImageUri(this.state[`chatMessagePatientProfileImage${data}`])} height={200} />
          )
        }</div>
      })}
      
      <div className="uk-grid uk-margin-top">
        <div className="uk-width-1-1 uk-width-1-2@m">
          <label>
            <input className="uk-checkbox" type="checkbox" onChange={(e)=>{
              console.log(e)
              this.setState
              ({chatMessagePatientProfileConfirmInformation: !this.state.chatMessagePatientProfileConfirmInformation})
            }} /> <span>ข้าพเจ้ายืนยันว่าข้อมูลเอกสารที่แนบข้างต้นทั้งหมดเป็นความจริง</span>
          </label>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@m uk-text-right">
            <button
            className="uk-button uk-button-primary uk-margin-small-top"
            onClick={()=>{
              if(this.state.chatMessagePatientProfileConfirmInformation !== true) {
                UIkit.notification({message: 'โปรดยืนยันว่าข้อมูลในเอกสารเป็นความจริง'});

                return false;
              }
              this.messageSendPatientProfile();

              if(this.state.chatMessagePatientProfileIsCovid === true) {
                this.messageSendCovid19();
              }
            }}
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  }

  geoData = {
    lat: null,
    lng: null
  }

  messageSubmitAddress = () => {

    return this.state.profileLoading === false && <div>
      <MemberRegisterLocation
        callback={(data)=>{
          this.setState({
            
            chatMessageAddressLat: data.lat,
            chatMessageAddressLng: data.lng,
            chatMessageAddressDetail: data.address,
            chatMessageAddressPostcode: data.postcode,
          })
        }}
        address={this.state.chatMessageAddressDetail}
        postcode={this.state.chatMessageAddressPostcode}
        lat={this.state.chatMessageAddressLat} 
        lng={this.state.chatMessageAddressLng} 
        hideAddressName={true} 
        hideNote={true} 
        formType="admin" 
      />
      <div className="uk-text-right uk-margin-top">
        {
          this.state.messageListSending === true ?
          <div><span uk-spinner=""></span></div>
          :
          <button
            className="uk-button uk-button-primary uk-margin-small-top"
            onClick={this.messageSendAddress}
          >
            บันทึก
          </button>
        }
      
      </div>
    </div>
  }

  messageSubmitHiv = () => {
    return this.state.messageListSending === true ? <div><span uk-loading=""></span></div>
    : this.state.profileLoading === false && <div><HIV member={{
      firstname: `${this.profile.profile.nameprefix} ${this.profile.profile.name}`,
      lastname: this.profile.profile.lastname,
      nid: this.profile.profile.nid
    }} consult={{
      name: this.state.messageConsultName
    }} callback={(data)=>{
      this.messageSendHiv(data);
    }} /></div>
  }

  messageSubmitHivManual = () => {
    return <div className="uk-text-center">
      <video className="uk-width-1-3@s uk-width-1-1" controls="true">
          <source src="https://firebasestorage.googleapis.com/v0/b/diet-z.appspot.com/o/manual-hiv.mp4?alt=media&token=ac976001-77cf-4c5a-855a-945ca54a398a" type="video/mp4" />
          <div>Your browser does not support the video tag.</div>
        </video>
    </div>
  }

  messageSubmitSOS = () => {
    return this.state.messageListSending === true ? <div><span uk-loading=""></span></div>
    : this.state.profileLoading === false && <div className="uk-grid">
      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-1 uk-width-1-4@m">อุณหภูมิ</div>
          <div className="uk-width-2-3 uk-width-1-4@m">
            <input type="number" className="uk-input uk-margin-bottom" value={this.state.chatMessageSOSTemp} onChange={(e)=>this.setState({
              chatMessageSOSTemp: e.target.value
            })} />
            {
              /*
              <select className="uk-select">
              <option value="≤35">≤ 35</option>
              <option value="35.1-36">35.1 - 36</option>
              <option value="36.1-38">36.1 - 38</option>
              <option value="38.1-38.4">38.1 - 38.4</option>
              <option value="≥38.5">≥ 38.5</option>
            </select>
              */
            }
            
          </div>
          <div className="uk-width-1-3 uk-width-1-2@m">°C</div>
          <div className="uk-width-1-1 uk-width-1-4@m">หายใจ</div>
          <div className="uk-width-1-1 uk-width-1-4@m">
            <input type="number" className="uk-input uk-margin-bottom" value={this.state.chatMessageSOSBreath} onChange={(e)=>this.setState({
              chatMessageSOSBreath: e.target.value
            })} />
            {
              /*
              <select className="uk-select">
              <option value="≤8">≤ 8</option>
              <option value="9-20">9 - 20</option>
              <option value="21-25">21 - 25</option>
              <option value="26-35">26 - 35</option>
              <option value="≥35">≥ 35</option>
            </select>
              */
            }
            
          </div>
          <div className="uk-width-1-1 uk-width-expand@m">
            <label>
              <input type="checkbox" className="uk-checkbox" checked={this.state.chatMessageSOSRespirator} onChange={e=>{
                this.setState({
                  chatMessageSOSRespirator: !this.state.chatMessageSOSRespirator
                });
              }} /> ใส่เครื่องช่วยหายใจ
            </label>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1">
        <div className="uk-grid">
          <div className="uk-width-1-1 uk-width-1-4@m">ชีพจร</div>
          <div className="uk-width-1-1 uk-width-1-4@m">


            <input type="number" className="uk-input uk-margin-bottom" value={this.state.chatMessageSOSPulse} onChange={(e)=>this.setState({
              chatMessageSOSPulse: e.target.value
            })} />
            {
              /** 
               * <select className="uk-select">
              <option value="≤40">≤ 40</option>
              <option value="41-50">41 - 50</option>
              <option value="51-100">51 - 100</option>
              <option value="100-120">100 - 120</option>
              <option value="121-139">121 - 139</option>
              <option value="≥140">≥ 140</option>
            </select>
              */
            }
            
          </div>
          <div className="uk-width-1-1 uk-width-1-2@m">ครั้ง/นาที</div>
          <div className="uk-width-1-1 uk-width-1-4@m">ความดันโลหิต (ค่าบน)</div>
          <div className="uk-width-2-3 uk-width-1-4@m">
            
          <input type="number" className="uk-input" value={this.state.chatMessageSOSSys} onChange={(e)=>this.setState({
              chatMessageSOSSys: e.target.value
            })} />
            {/*
            <select className="uk-select">
              <option value="≤80">≤ 80</option>
              <option value="81-90">81 - 90</option>
              <option value="91-100">91 - 100</option>
              <option value="101-180">101 - 180</option>
              <option value="181-199">181 - 199</option>
              <option value="≥200">≥ 200</option>
            </select>
          */}
          </div>
          <div className="uk-width-1-3 uk-width-1-5@m">mm/Hg</div>
          <div className="uk-width-1-1 uk-width-auto@m">
            <label>
              <input type="checkbox" className="uk-checkbox" checked={this.state.chatMessageSOSStimulant} onChange={(e)=>this.setState({
              chatMessageSOSStimulant: e.target.value
            })} /> ใช้ยากระตุ้นความดันโลหิต
            </label>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1">
        <div className="uk-margin-small-top uk-margin-small-bottom">ความรู้สึกตัว</div>
        <select className="uk-select" value={this.state.chatMessageSOSConsciousness} onChange={(e)=>this.setState({
          chatMessageSOSConsciousness: e.target.value
        })}>
          <option className="ตื่นดี พูดคุยรู้เรื่อง">ตื่นดี พูดคุยรู้เรื่อง</option>
          <option className="สับสน กระสับกระส่าย ที่เพิ่งเกิดขึ้น">สับสน กระสับกระส่าย ที่เพิ่งเกิดขึ้น</option>
          <option className="ซึม แต่เรียกแล้วลืมตา">ซึม แต่เรียกแล้วลืมตา</option>
          <option className="ซึมมาก ต้องกระตุ้น จึงลืมตา">ซึมมาก ต้องกระตุ้น จึงลืมตา</option>
          <option className="ไม่รู้สึกตัว แม้กระตุ้นแล้ว">ไม่รู้สึกตัว แม้กระตุ้นแล้ว</option>
        </select>
      </div>
      <div className="uk-width-1-1 uk-text-right">
        
      {this.state.messageListSending === true ?
          <div><span uk-spinner=""></span></div>
          :
          <button
          className="uk-button uk-button-primary uk-margin-small-top"
          onClick={this.messageSendSOS}
        >
          บันทึก
        </button>}
        
      </div>
    </div>
  }

  messageSubmitDialysisType1Render = () => {
    return <ChatBoxMessageSumbitDialysisType1Render messageListSending={this.state.messageListSending} callback={async (data) => {
      
      this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "text",
        memberRead: 1,
        consultRead: 0,
        message: `<b>ตารางน้ำตาลเบาหวานชนิดที่ 1</b>
        <b>วันที่ :</b> ${moment(data.date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}
        <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider d-table-diabates-data uk-text-center"><thead><tr><th colspan="3">มื้อเช้า</th><th colspan="3">มื้อเที่ยง</th><th colspan="3">มื้อเย็น</th><th colspan="2">ก่อนนอน</th></tr><tr><th>ก่อน</th><th>คาร์บ</th><th>ยาฉีด</th><th>ก่อน</th><th>คาร์บ</th><th>ยาฉีด</th><th>ก่อน</th><th>คาร์บ</th><th>ยาฉีด</th><th>ค่าน้ำตาล</th><th>ยาฉีด</th></tr></thead><tbody><tr><td>${data.morning_before}</td><td>${data.morning_carb}</td><td>${data.morning_med}</td><td>${data.lunch_before}</td><td>${data.lunch_carb}</td><td>${data.lunch_med}</td><td>${data.dinner_before}</td><td>${data.dinner_carb}</td><td>${data.dinner_med}</td><td>${data.before_bed_sugar}</td><td>${data.before_bed_med}</td></tr></tbody></table></div>`,
        chatby: this.props.chatby,
        toAllConsult: "1",
      });

      

    var result = await Api[this.apiType]("DiabetesType1DataAdd", {
      data: JSON.stringify(data),
      dateadd: moment(data.date, 'YYYY-MM-DD HH:mm:00'),
      campaignId: this.state.messageCampaignId,
    });

      this.setState(
        {
          messageListSending: false,
        }, ()=>{
          this.firebaseChatlistUpdate();
        }
      );

      return;
    }} />
  }

  
  messageSubmitDialysisType2Render = () => {
    return <ChatBoxMessageSumbitDialysisType2Render messageListSending={this.state.messageListSending} callback={async (data) => {
      
      this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "text",
        memberRead: 1,
        consultRead: 0,
        message: `<b>ตารางน้ำตาลเบาหวานชนิดที่ 2</b>
        <b>วันที่ :</b> ${moment(data.date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}
        <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider d-table-diabates-data uk-text-center"><thead><tr><th colspan="2">มื้อเช้า</th><th colspan="2">มื้อเที่ยง</th><th colspan="2">มื้อเย็น</th></tr><tr><th>ก่อน</th><th>ยาฉีด</th><th>ก่อน</th><th>ยาฉีด</th><th>ก่อน</th><th>ยาฉีด</th></tr></thead><tbody><tr><td>${data.morning_before}</td><td>${data.morning_carb}</td><td>${data.lunch_before}</td><td>${data.lunch_med}</td><td>${data.morning_carb}</td><td>${data.dinner_before}</td><td>${data.dinner_med}</td></tr></tbody></table></div>`,
        chatby: this.props.chatby,
        toAllConsult: "1",
      });

      var result = await Api[this.apiType]("DiabetesType2DataAdd", {
        data: JSON.stringify(data),
        dateadd: moment(data.date, 'YYYY-MM-DD HH:mm:00'),
        campaignId: this.state.messageCampaignId,
      });

      this.setState(
        {
          messageListSending: false,
        }, ()=>{
          this.firebaseChatlistUpdate();
        }
      );
    }} />
  }

  messageSubmitDialysisPregnant = () => {
    return <ChatBoxMessageSumbitDialysisPregnant messageListSending={this.state.messageListSending} callback={async (data) => {
      
      this.setState({ messageListSending: true, chatMessageAddressPopupShow: false });

      
      var result = await Api[this.apiType]("ChatMessageAdd", {
        consultId: this.state.messageConsultId,
        memberId: this.state.messageMemberId,
        campaignId: this.state.messageCampaignId,
        typeMessage: "text",
        memberRead: 1,
        consultRead: 0,
        message: `<b>ตารางน้ำตาลเบาหวานตั้งครรภ์</b>
        <b>วันที่ :</b> ${moment(data.date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}
        <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider d-table-diabates-data uk-text-center"><thead><tr><th colspan="3">ก่อนรับประทานอาหาร</th><th colspan="3">หลังรับประทานอาหาร 1 ชั่วโมง</th><th colspan="3">หลังรับประทานอาหาร 2 ชั่วโมง</th></tr><tr><th>เช้า</th><th>เที่ยง</th><th>เย็น</th><th>เช้า</th><th>เที่ยง</th><th>เย็น</th><th>เช้า</th><th>เที่ยง</th><th>เย็น</th></tr></thead><tbody><tr><td>${data.before_breakfast}</td><td>${data.before_lunch}</td><td>${data.before_dinner}</td><td>${data.after_breakfast_1}</td><td>${data.after_lunch_1}</td><td>${data.after_dinner_1}</td><td>${data.after_breakfast_2}</td><td>${data.after_lunch_2}</td><td>${data.after_dinner_2}</td></tr></tbody></table></div>`,
        chatby: this.props.chatby,
        toAllConsult: "1",
      });

      var result = await Api[this.apiType]("DiabetesPregnantDataAdd", {
        data: JSON.stringify(data),
        dateadd: moment(data.date, 'YYYY-MM-DD HH:mm:00'),
        campaignId: this.state.messageCampaignId,
      });

      this.setState(
        {
          messageListSending: false,
        }, ()=>{
          this.firebaseChatlistUpdate();
        }
      );
    }} />
  }
  

  profileRender = () => {

    var _atk = {
      bg : '',
      status: ''
    };

    if(this.state.atk != null) {
      if(this.state.atk.value === 'abnormal') {
        _atk.bg = 'd-background-red';
        _atk.status = '(covid detected)';
      } else {
        _atk.bg = 'd-background-green';
        _atk.status = '(normal)';
      }
    }

    return (
      <div className="uk-padding">
        {this.state.profileLoading === true ? (
          <div className="uk-text-center">
            <span uk-spinner=""></span>
          </div>
        ) : (
          <MemberProfileChatCard apiType={this.apiType} campaignId={this.state.messageCampaignId} memberId={this.state.messageMemberId} scrollToBottom = {this.messageScrollBottom} />
        )}
      </div>
    );
  };

  chatFilterRender = () => {
    return (
      <div>
        <div uk-grid="" className="uk-grid-small uk-child-width-1-2">
          <div>
            <select
              className="uk-select"
              value={this.state.filterChatTypeSelect}
              onChange={(e) => {
                this.setState({ filterChatTypeSelect: e.target.value }, () => {
                  this.chatListGet();
                });
              }}
            >
              <option value="all">ทั้งหมด</option>
              <option value="new">ยังไม่ได้อ่าน</option>
              <option value="unchat">ยังไม่มีการสนทนา</option>
            </select>
          </div>
          <div>
            <select
              className="uk-select"
              value={this.state.filterChatCampaignSelect}
              onChange={(e) => {
                this.setState(
                  { filterChatCampaignSelect: e.target.value },
                  () => {
                    this.chatList0 = null;
                    this.chatListGet();
                  }
                );
              }}
            >
              <option value="all">All</option>
              {this.state.optionCampaign.map((data) => {
                return (
                  <option key={`campaign${data.id}`} value={data.id}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div>
          <div className="uk-background-muted uk-padding-small uk-text-meta uk-margin-small-top">จำกัดจำนวนการแสดงผลแชทไว้ที่ 100 คน หากต้องการค้นหาคนไข้กรุณาพิมพ์ชื่อคนไข้แล้วกดปุ่ม ค้นหา</div>
          <div className="uk-inline uk-width-1-1">
            <form onSubmit={(e)=>{
              e.preventDefault();
              
              this.chatListGet();
            }}>
              <button
                type="submit"
                className="uk-form-icon uk-form-icon-flip uk-margin-small-top"
                uk-icon="icon: search"
              ></button>
              <input
                type="text"
                className="uk-input uk-margin-small-top"
                placeholder="ค้นหา"
                onChange={(e) => this.chatListKeywordSet(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>
    );
  };

  chatListKeywordSet = (value) => {
    this.chatList0 = null;

    this.setState({ chatListKeyword: value }, () => {
    });
  };

  consultModalRender = (_c) => {
    var _languageRender = (data) => {
      switch (data) {
        case "th":
          return "ไทย";
          break;
        case "en":
          return "English";
          break;
        case "ch":
          return "Chiness";
          break;
        default:
          return false;
          break;
      }
    };

    var _service = [];

    try {
      _service = JSON.parse(_c.service);
    } catch (e) {
      console.error(e);
    }

    if (!_service) _service = [];

    return (
      <div id="d-chat-modal-consult-detail">
        <div>
          <a
            className="uk-float-right"
            uk-close=""
            onClick={() => this.setState({ modalConsultDetailShow: false })}
          ></a>
        </div>
        <div uk-grid="" className="uk-grid-small">
          <div className="uk-width-1-3">
            <div>
              <div
                className="uk-background-muted uk-background-cover "
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 40,
                  backgroundImage:
                    _c.image === "" ? false : `url(${ImageUri(_c.image)})`,
                }}
              ></div>
            </div>
          </div>
          <div className="uk-width-2-3">
            <div>
              <b>
                {_c.nameprefix} {_c.name} {_c.lastname}
              </b>
            </div>
            <div className="uk-margin-small-top">
              <span className="uk-label d-background-green">
                {_c.typeconsult}
              </span>
              <span className="uk-label uk-margin-small-left d-background-green">
                {_c.typebranch}
              </span>
            </div>
            <div className="uk-margin-small-top">
              ใช้ภาษา:{" "}
              <div>
                {_c.language
                  .split(",")
                  .map((data) =>
                    _languageRender(data) ? (
                      <span className="uk-label uk-margin-small-right">
                        {_languageRender(data)}
                      </span>
                    ) : null
                  )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-height-medium uk-overflow-auto uk-card uk-card-default">
          <div className="uk-padding-small">
            <b>โรงพยาบาล</b>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.hospitalhistory
                  ? _c.hospitalhistory.replace(/(?:\r\n|\r|\n|\\n)/g, "<br>")
                  : "ไม่พบข้อมูล",
              }}
            ></div>
            <hr />
            <b>การศึกษา</b>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.education
                  ? _c.education.replace(/(?:\r\n|\r|\n|\\n)/g, "<br>")
                  : "ไม่พบข้อมูล",
              }}
            ></div>
            <hr />
            <b>ความชำนาญเฉพะทาง</b>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.speciallist
                  ? _c.speciallist.replace(/(?:\r\n|\r|\n|\\n)/g, "<br>")
                  : "ไม่พบข้อมูล",
              }}
            ></div>
            <hr />
            <b>ประวัติ</b>
            <div>
              {_c.history
                ? _c.history.replace(/(?:\r\n|\r|\n)/g, "<br>")
                : "ไม่พบข้อมูล"}
            </div>
            <hr />
            <b>เวลาและเงื่อนไขการให้บริการ</b>
            <div>
              {_c.service
                ? _service.map((data, index) => {
                    var _nameService = "";
                    switch (data.service) {
                      case "chat":
                        _nameService = "ให้คำแนะนำผ่านแชท";
                        break;
                      case "voice":
                        _nameService = "วอยส์คอล";
                        break;
                      case "video":
                        _nameService = "วีดีโอคอล";
                        break;
                      default:
                    }

                    return (
                      <div
                        style={{ marginLeft: 10, marginTop: 5 }}
                        key={"s" + index}
                      >
                        <b>{_nameService}</b>{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.condition.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br>"
                            ),
                          }}
                        ></span>{" "}
                        <div className="uk-text-meta">
                          {data.price == "0"
                            ? "ไม่มีค่าใช้จ่าย"
                            : `ค่าใช้จ่ายครั้งละ ${numeral(data.price).format(
                                "0,0.00"
                              )} บาท`}
                        </div>
                      </div>
                    );
                  })
                : "ไม่พบข้อมูล"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  memberChatButtonDataLabel = {
    'wound' : 'รักษาแผล',
    'covid19' : 'อาการโควิด-19',
    'bloodpressure' : 'ความดันโลหิต',
    'sugar' : 'ค่าน้ำตาลปลายนิ้ว',
    'bmi' : 'น้ำหนัก/รอบเอว',
    'food' : 'อาหาร',
    'diabetes' : 'เฉพาะผู้ป่วยเบาหวาน',
    'diabetestype1' : 'ตารางน้ำตาลเบาหวานชนิดที่ 1',
    'diabetestype2' : 'ตารางน้ำตาลเบาหวานชนิดที่ 2',
    'diabetespregnant' : 'ตารางน้ำตาลเบาหวานตั้งครรภ์',
    'dialysis' : 'น้ำยาล้างไต',
    'patientprofile' : 'ข้อมูลก่อนพบแพทย์',
    'address' : 'ข้อมูลสำหรับจัดส่งยา',
    'hiv' : 'สร้างเสริมสุขภาพ',
    'hiv_manual' : 'คู่มือวีดีโอใช้งานสร้างเสริมสุขภาพ',
    'sos' : 'SOS'
  }

  memberChatButtonDataForm = {
    'wound' : this.messageSubmitWound,
    'covid19' : this.messageCovid19Render,
    'bloodpressure' : this.messageSubmitBloodRender,
    'sugar' : this.messageSubmitSugarRender,
    'bmi' : this.messageSubmitWeightRender,
    'food' : this.messageSubmitFoodRender,
    'diabetes' : this.messageSubmitDiabetesRender,
    'dialysis' : this.messageSumbitDialysisRender,
    'diabetestype1' : this.messageSubmitDialysisType1Render,
    'diabetestype2' : this.messageSubmitDialysisType2Render,
    'diabetespregnant' : this.messageSubmitDialysisPregnant,
    'patientprofile' : this.messageSubmitPatientProfile,
    'address' : this.messageSubmitAddress,
    'hiv' : this.messageSubmitHiv,
    'hiv_manual' : this.messageSubmitHivManual,
    'sos' : this.messageSubmitSOS
  }

  

  memberChatButtonRender = (mode) => {
    var _dom = [];
    
    var _campaign = this.state.optionCampaign.filter((campaign) => {
      return (campaign.id == this.state.messageCampaignId ? true : false);
    });

    var _type = ['wound','covid19','bloodpressure','sugar','bmi','food','diabetes','dialysis','address'];

    if(_campaign[0] !=null) {
      var _memberChatButton = _type;

      
      if(_campaign[0] != null && Array.isArray(_campaign[0].memberchatbutton))
        _memberChatButton = _campaign[0].memberchatbutton;
        

      _memberChatButton.forEach((type) => {
        var _show_button = true;

        if(type === 'diabetespregnant' && this.profile.profile.diabetes != '4') {
          _show_button = false;
        } else if(type === 'diabetestype1' && this.profile.profile.diabetes != '2') {
          _show_button = false;
        } else if(type === 'diabetestype2' && this.profile.profile.diabetes != '3') {
          _show_button = false;
        }

        if(_show_button === true) {
          if(mode === 'button') {
            _dom.push(<li><a href="#">{this.memberChatButtonDataLabel[type]}</a></li>);
          }
          else {
            try {
              _dom.push(<li>{this.memberChatButtonDataForm[type]()}</li>);

            } catch(e) {
              console.log(type, e)
            }
          }

        }
      });


    }

    return _dom;

  }

  consultChatButtonDataLabel = {
    'dialysis' : 'น้ำยาล้างไต',
  }

  consultChatButtonDataForm = {
    'dialysis' : this.dialysisHistoryConsult,
  }


  consultChatButtonRender = (mode) => {
    var _dom = [];
    
    var _campaign = this.state.optionCampaign.filter((campaign) => {
      return (campaign.id == this.state.messageCampaignId ? true : false);
    });
    
    var _type = ['dialysis'];

    if(_campaign[0] !=null) {
      var _consultChatButton = _type;

      
      if(_campaign[0] != null && Array.isArray(_campaign[0].memberchatbutton))
      _consultChatButton = _campaign[0].memberchatbutton.filter((type)=>{ return _type.includes(type); });
     

      if(mode === 'button') {

        _consultChatButton.forEach((type) => {
          _dom.push(<li><a href="#">{this.consultChatButtonDataLabel[type]}</a></li>);
        });

      } else {

        _consultChatButton.forEach((type) => {
          _dom.push(<li>{this.consultChatButtonDataForm[type]()}</li>);
        });
      }

    }

    return _dom;


  }


  messageListDefaultRender = () => {
    return <>
    <div
      id="d-chat-body"
      className={
        this.props.admin === "true" ? "d-chat-body-readonly" : ""
      }
    >
      {this.profileRender()}
      <div className="uk-text-center">
        <span className="d-chat-history-label">Chat History</span>
      </div>
      {!this.state.noMoreMessage && this.state.messageList.length > 0 && (
        <div className="uk-text-center">
          <a
            className="d-chat-history-more"
            onClick={() =>
              this.setState({ page: this.state.page + 1, messageListLoading: true }, () =>
                this.messageListGet()
              )
            }
          >
            ดูข้อความก่อนหน้า
          </a>
        </div>
      )}

      <div>{this.messageListRender()}</div>
    </div>
    {this.props.admin !== "true" && [
      <div id="d-chat-submit-container">
        {this.apiType === 'consult' && this.profile.profile.healthdrug && <div className="d-background-red uk-margin uk-text-center d-color-white uk-padding-small">{this.profile.profile.healthdrug}</div>}
        <div>
          <ul uk-tab="connect: #d-chat-tab-body" id="d-chat-tab-title">
            <li>
              <a href="#">ข้อความ</a>
            </li>
            <li>
              <a href="#">รูปภาพ</a>
            </li>
            <li>
              <a id="video-call-button" target="_blank" rel="noopener">
                วีดีโอคอล
              </a>
            </li>
            <li>
              <a href="#">ไฟล์</a>
            </li>
            {
              this.props.chatby === "c" && [
                this.consultChatButtonRender('button'),
                <li>
                  <a href="#">สร้างนัด</a>
                </li>,
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({ prescriptionShow: true });
                    }}
                  >
                    ใบสั่งยา
                  </a>
                </li>,
                <li>
                  <a href="#">บันทึกการดูแล (Visit Note)</a>
                </li>,
                // <li>
                //   <a href="#">เรียกพนักงานจัดส่ง</a>
                // </li>
              ]
            }
            {/*this.props.chatby === "c" && (
              <li>
                <a href="#">สร้างลิงค์จ่ายเงิน</a>
              </li>
            )*/}
            {this.props.chatby === "m" && this.memberChatButtonRender('button')}
          </ul>
        </div>

        <ul className="uk-switcher uk-margin" id="d-chat-tab-body">
          <li>{this.messageSubmitTextRender()}</li>
          <li>{this.messageSubmitImageRender()}</li>
          <li></li>
          <li>{this.messageSubmitFileRender()}</li>
          {
            this.props.chatby === 'c' && [
              this.consultChatButtonRender(),
              <li>{this.messageSubmitAppointmentRender()}</li>,
              <li></li>,
              <li>{this.messageSubmitVisitNoteRender()}</li>,
              // <li>{this.messageSubmitShippingCallRender()}</li>
            ]
          }
          {/*this.props.chatby === "c" && (
            <li>{this.messageSubmitPaymentRender()}</li>
          )*/}
          {this.props.chatby === "m" && this.memberChatButtonRender()}
        </ul>
      </div>
    ]}
    </>
  }

  messageListContainerRender = () => {
    if(this.state.profileLoading === true || this.state.messageListLoading === true) return <div className="uk-padding uk-text-center"><span uk-spinner=""></span></div>

    var _use_address = false;

    var _use_symptom = false;

    var _use_hiv = false;
    
    var _use_diabetespregnant = false;

    var _use_diabetestype = false;


    var _campaign = this.state.optionCampaign.filter((campaign) => {
      return (campaign.id == this.state.messageCampaignId ? true : false);
    });



    if(_campaign[0] !=null && Array.isArray(_campaign[0].memberchatbutton) === true ) {

      if(_campaign[0].memberchatbutton.includes('address') === true) {
        _use_address = true;
      } 
      
      if(_campaign[0].memberchatbutton.includes('patientprofile') === true) {
        _use_symptom = true;
      }

      if(_campaign[0].memberchatbutton.includes('hiv') === true) {
        _use_hiv = true;
      }

      if(_campaign[0].memberchatbutton.includes('diabetespregnant' && this.profile.profile.diabetes == '4') === true) {
        _use_diabetespregnant = true;
      }

      if(
        (_campaign[0].memberchatbutton.includes('diabetestype1') === true  && this.profile.profile.diabetes == '2')
        ||
        (_campaign[0].memberchatbutton.includes('diabetestype2') === true  && this.profile.profile.diabetes == '3')
        ) {
        _use_diabetestype = true;
      }

    }

    if(this.apiType === 'member' && this.state.messageMemberCampaignExpire === true) { 

      return <div className="uk-padding"><CampaignRenew show={true} mode="form" apiType={this.apiType} memberCampaignRenew={this.state.memberCampaignRenew} memberCampaignId={this.state.memberCampaignId} campaignId={this.state.messageCampaignId} hideClose={true} closePopup={(refresh)=>{
        if(refresh === true) window.location.reload();
        
      }} /></div>
    } else if(_use_address === true && (!this.profile.profile.address || !this.profile.profile.postcode || !this.profile.profile.lat || !this.profile.profile.lng)) {
      return <div className="uk-padding">
        <h4 className="uk-text-center uk-text-bold">โปรดกรอกที่อยู่สำหรับจัดส่งยาเพื่อดำเนินการต่อ</h4>
        {this.messageSubmitAddress()}
      </div>;
    } else if(_use_symptom === true && this.alreadyHasPatientProfileSymptom !== true) {
      return <div className="uk-padding">
        <h4 className="uk-text-center uk-text-bold">โปรดใส่ข้อมูลก่อนพบแพทย์</h4>
        {this.messageSubmitPatientProfile()}
      </div>;

    } else if(_use_hiv === true &&   this.alreadyHasPatientHiv !== true) {
      return <div className="uk-padding">
        <h4 className="uk-text-center uk-text-bold">โปรดใส่แบบประเมินตนเองก่อนพบแพทย์</h4>
        {this.messageSubmitHiv()}
      </div>;

    } else if(_use_diabetespregnant === true &&   this.alreadyHasDiabetesPregnant !== true) {
      return <div className="uk-padding">
        <h4 className="uk-text-center uk-text-bold">โปรดใส่แบบประเมินตนเองก่อนพบแพทย์</h4>
        {this.DiabatesPregnantForm()}
      </div>;

    } else if(_use_diabetestype === true &&   this.alreadyHasDiabetesType !== true) {
      return <div className="uk-padding">
        <h4 className="uk-text-center uk-text-bold">โปรดใส่แบบประเมินตนเองก่อนพบแพทย์</h4>
        {this.DiabatesTypeForm()}
      </div>;

    } else {
      setTimeout(()=>{
        this.datepickerGet();
      }, 500);

      return this.messageListDefaultRender();
    }
  }

  render() {
    return (
      <div className="uk-height-1-1">
        {this.state.chatMessageAddressPopupShow === true && <div className="d-popup-background">
            <div className="d-popup-body">
              <div className="uk-text-right">
                <a uk-icon="close" onClick={()=>this.setState({chatMessageAddressPopupShow: false})}></a>
              </div>
              <div>{this.messageSubmitAddress()}</div>
            </div>
          </div>
        }
        <div className="uk-grid-collapse" uk-grid="">
          <div
            className={
              "uk-width-1-4@l " +
              (this.state.mobileSideMenu ? "d-chat-list-active" : "")
            }
            id="d-chat-list-side"
          >
            {this.state.chatListLoading ? (
              <div className="uk-padding uk-text-center">
                <span uk-spinner=""></span>
              </div>
            ) : (
              this.chatListRender()
            )}
          </div>
          <div className="uk-width-3-4@l uk-width-1-1">
            <div className="uk-hidden@l">
              <button
                id="d-chat-list-button-side-call"
                onClick={() => {
                  this.setState({
                    mobileSideMenu: !this.state.mobileSideMenu,
                  });
                }}
              >
                <span className="icon-user"></span>{" "}
                {this.props.chatby === "m" ? "กดพบหมอ" : "กดพบคนไข้"}
              </button>
              {this.state.mobileSideMenu && (
                <div
                  id="d-chat-list-side-overlay"
                  onClick={() => this.setState({ mobileSideMenu: false })}
                  className="uk-animation-fade"
                ></div>
              )}
            </div>
            {this.state.messageSelectIndex === false ?
            <div className="uk-padding uk-text-center">
              <h4 className="uk-position-center">
                <img src="/assets/doctor.png" className="uk-width-medium" uk-img="" onClick={() => {
                  this.setState({
                    mobileSideMenu: !this.state.mobileSideMenu,
                  });
                }} />
                <div className="uk-margin-top-small uk-text-meta">กดปุ่ม "กดพบหมอ"</div>
                <div className="uk-margin-top-small uk-text-meta">เพื่อเลือกบุคลากรทางการแพทย์ที่ท่านต้องการ</div>
              </h4>
            </div>
            :
            this.messageListContainerRender()
          }
          </div>
        </div>
        {this.props.chatby === "c" &&
          this.state.profileLoading === false &&
          this.state.prescriptionShow && (
            <ConsultPrescription
              campaignId={this.state.messageCampaignId}
              memberId={this.profile.profile.id}
              chatCallback={this.firebaseChatlistUpdate}
              closeCallback={() => {
                this.setState({ prescriptionShow: false });
              }}
            />
          )}
        {this.apiType === "member" &&
          this.state.modalConsultDetailShow &&
          this.consultModalRender(this.state.chatMessageConsult)}
      </div>
    );
  }
}
