import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import firebase from "firebase";
UIkit.use(Icons);

export default class ConsultMemberList extends Component {
  state = {
    list: [],
    listAll: [],
    dateStart: moment().startOf('day'),
    dateStop: moment().add(7, 'day'),
    loadingApi: false,
    page: 1,
    pageAmount: 1,
    filterCampaign: 'all'
  }

  memberById = {} ;

  campaignById ={} ;

  campaignOption = [
    {label: "แคมเปญทั้งหมด", value: "all"},
  ];
  
  firebaseChatlistUpdate = (memberid, consultid) => {
    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  }
  
  logVideoCallSend = async (consultId, memberId, campaignId) => {
    
    Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: 'consult'
    }); 

    var result = await Api.consult("ChatMessageAdd", {
      consultId: consultId,
      memberId: memberId,
      campaignId: campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message: `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล
      https://con.defence-innovation.com/${memberId}/${consultId}?openExternalBrowser=1`,
      chatby: 'c',
    });

    this.firebaseChatlistUpdate(memberId, consultId);

  };

  listGet = async  () => {
    this.setState({loadingApi: true});

    var _response = await Api.consult("MemberList", {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD '),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD'),
      page: this.state.page
    });
    

    if(Array.isArray(_response.member)) {

      _response.member.forEach((member)=>{
        this.memberById[member.id] = member;
      })
    } else {
      this.memberById = {};
    }

    this.campaignOption = [
      {label: "แคมเปญทั้งหมด", value: "all"},
    ];

    if(Array.isArray(_response.campaign)) {
      _response.campaign.forEach((campaign) => {
        
        this.campaignOption.push({
          label: campaign.name,
          value: campaign.id
        });

        this.campaignById[campaign.id] = campaign.name;
      })
    }

    if(Array.isArray(_response.list)) {

      _response.list.forEach((list) => {
        var _member = this.memberById[list.memberid];

        if(_member != null) {

          list.member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`

          list.dateadd = _member.dateadd;

        } else {
          list.member = '-';

          list.dateadd = '-';
        }

        list.campaignname = '';

        if(this.campaignById[list.campaignid] != null) {
          list.campaignname = this.campaignById[list.campaignid];
        }
      });
    }

    this.setState({
      listAll: _response.list || [],
      loadingApi: false,
      pageAmount: _response.pageAmount
    }, ()=>{
      this.listFilterRender();
    });
  }

  listFilterRender = () => {

    var _list = [];

    this.state.listAll.forEach((data)=>{
      if(this.state.filterCampaign !== 'all' && this.state.filterCampaign != data.campaignid) {
        return null;
      } else {
        _list.push(data);
      }
    });


    this.setState({list: _list});
    
  }
  
  handlePageClick = (page) => {
    this.setState({page: page.selected + 1}, ()=> this.listGet())
    
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        className={'uk-padding-remove'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageAmount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  componentDidMount() {
    this.listGet();
  }

  render() {
    return <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">รายชื่อคนไข้</div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
          </div>
          <div className="uk-width-2-5@m uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <select className="uk-select" defaultValue={this.state.filterCampaign} onChange={e=>this.setState({filterCampaign: e.target.value}, ()=>{
              this.listFilterRender();
            })}>
              {this.campaignOption.map((option)=>{
                return <option value={option.value}>{option.label}</option>
              })}
            </select>
          </div>
        </div>

        <hr />
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่สมัคร</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>วีดีโอคอล</th>
                <th>แชท</th>
                <th>แคมเปญ</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{

                  // console.log(data)


                  return <tr key={`row${data.id}`}>
                    <td>{key+1}</td>
                    <td>
                      {data.dateadd == '-' ? '-' : moment(data.dateadd).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {data.dateadd == '-' ? '-' :moment(data.dateadd).format('HH:mm น.')}
                    </td>
                    <td>{data.member == '-' ? 'ไม่พบข้อมูลสมาชิก สมาชิกถูกลบ' : data.member}</td>
                    <td>
                      {
                        data.member == '-' ? 
                        ''
                        :
                        <div>
<a
                        href={`https://con.defence-innovation.com/${data.memberid}/${this.props.consult.id}?openExternalBrowser=1`}
                        className="uk-margin-right"
                        target="_blank"
                        onClick={()=>{

                          this.logVideoCallSend(this.props.consult.id, data.memberid, data.campaignid)
                        }}
                      >
                        กดวีดีโอคอล
                      </a>
                      <a href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${this.props.consult.id}_m${data.memberid}`}>แชท</a>
                        </div>
                      }
                      
                      
                    </td>
                    <td>{data.campaignname}</td>
                    <td>{data.type}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
          <div>
            {this.paginateRender()}
          </div>
          </div>
      </div>
    </div>
  }
};
