import React, {Component} from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import HeaderSub from '../../Components/Member/HeaderSub';
import { data } from 'jquery';

UIkit.use(Icons);


export default class Survey extends Component {
  form_init = {
    login_easy: 5,
    system_easy: 5,
    chat_easy: 5,
    secret_confident: 5,
    test_easy: 5,
    service_step_easy: 5,
    consult_suggest: 5,
    save_money: 5,
    summary: 5,
    use_system_one_more_time: 'yes',
    suggest: ''
  }

  state = {
    form: {...this.form_init}
  }

  rate = [
    {
      type: 'title',
      value: 'ด้านแอปพลิเคชัน',
      child: [
        {
          type: 'rate',
          label: '1. ความสะดวกในการล็อกอินใช้ระบบ',
          key: 'login_easy'
        },
        {
          type: 'rate',
          label: '2. ระบบออกแบบให้ใช้งานง่าย เมนูไม่ซับซ้อน',
          key: 'system_easy'
        },
        {
          type: 'rate',
          label: '3. ความสะดวกในการสนทนาผ่านระบบ',
          key: 'chat_easy'
        },
        {
          type: 'rate',
          label: '4. ความมั่นใจเรื่องการรักษาความลับ',
          key: 'secret_confident'
        },
      ]
    },
    {
      type: 'title',
      value: 'ด้านบริการ',
      child: [
        {
          type: 'rate',
          label: '1. แบบประเมินก่อนพบแพทย์ครบถ้วนเหมาะสมเข้าใจง่าย',
          key: 'test_easy'
        },
        {
          type: 'rate',
          label: '2. ขั้นตอนการรับบริการเข้าใจง่าย',
          key: 'service_step_easy'
        },
        {
          type: 'rate',
          label: '3. การให้คำปรึกษาและคำแนะนำของเจ้าหน้าที่บุคลากร',
          key: 'consult_suggest'
        },
        {
          type: 'rate',
          label: '4. ประหยัดค่าใช้จ่าย/ค่าเดินทางการมาโรงพยาบาล',
          key: 'save_money'
        },
        {
          type: 'rate',
          label: '5. การใช้งานระบบ Telehealth ในภาพรวม',
          key: 'summary'
        },

      ]
    },
    {
      type: 'radio',
      key: 'use_system_one_more_time',
      title: 'หากท่านมีโอกาสได้ใช้งานระบบอีกครั้ง ท่านจะ',
      option: [
        {
          value: 'yes',
          label: 'ใช้'
        },
        {
          value: 'no',
          label: 'ไม่ใช้'
        },
        {
          value: 'not_sure',
          label: 'ไม่แน่ใจ'
        },
      ]
    },
    {
      type: 'input',
      label: 'ข้อเสนอแนะ/ข้อปรับปรุง',
      key: 'suggest',
      placeholder: 'ข้อเสนอแนะ/ข้อปรับปรุง',
    }
  ];
      
  SurveySubmit = async () => {
    var _response = await Api.member('SurveyAdd', {form: this.state.form});

    UIkit.notification(_response.responseText);

    if(_response.result === true) {
      this.setState({form: {...this.form_init}});
    }
  }

  updateFormValue = (key, value) => {
    this.setState((state)=>{
      state.form[key] = value;

      return state;
    })
  }

  inputTypeRender = (data) => {
    
    if(data.type === 'title') {
      return <div><u>{data.value}</u></div>
    } 
    else if(data.type === 'rate') {
      return <div>
        <div className='uk-margin-small-bottom'>{data.label}</div>
        <div>{[1,2,3,4,5].map((rate)=>{
          return <label className='uk-margin-right' key={'label'+data.key+rate} >
            <input name={'rate'+data.key} type="radio" value={rate} className='uk-radio' onChange={(e)=>this.updateFormValue(data.key, e.target.value)} checked={this.state.form[data.key] == rate} /> {rate}
          </label>
        })}</div>
      </div>
    }
    else if(data.type === 'radio') {
      return <div>
        <div className='uk-margin-small-bottom'>{data.title}</div>
        <div>{data.option.map((option, index)=>{
          return <label className='uk-margin-right' key={'label'+index+'_'+option.value}>
            <input name={data.key} type="radio" value={option.value} className='uk-radio' onChange={(e)=>this.updateFormValue(data.key, e.target.value)} checked={this.state.form[data.key] == option.value} /> {option.label}
          </label>
        })}</div>
      </div>
    } else if(data.type === 'input') {
      return <div>
        <div className='uk-margin-small-bottom'>{data.label}</div>
        <input className='uk-input' type="text" placeholder={data.placeholder} onChange={(e)=>this.updateFormValue(data.key, e.target.value)} value={this.state.form[data.key]} />
      </div>
    }
  }

  rateRender = (data_child) => {
    return data_child.map((data) => {
      return <div className='uk-margin-bottom' key={data.key}>
        <div className='uk-margin-bottom-small'>{this.inputTypeRender(data)}</div>
        {data.child && <div className='uk-padding-small d-border uk-margin-small-top uk-margin-small-bottom'>{this.rateRender(data.child)}</div>}
      </div>
    });
  }

  formRender = () => {
    return this.rateRender(this.rate);
  }

  render() {
    return <div className="uk-padding">
      <HeaderSub title="Survey" />
      <div className="uk-margin-small-top">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">แบบสำรวจความพึงพอใจ</div>
            <div className="d-card-header-right">
            </div>
          </div>
          <div className='uk-padding-small'>
            {this.formRender()}
            <div className='uk-text-right'>
              <button className='uk-button uk-button-primary' onClick={this.SurveySubmit} >บันทึก</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
};
