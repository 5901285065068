import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import moment from "moment";
import OnlineStat from "../../Components/Consult/OnlineStat.js";
import Survey from "../../Components/Consult/Survey.js";
import HivTable from "../../Pages/Consult/HivTable.js";

export default class ModeratorDashboard extends Component {
  state = {
    loading : false, 
    campaignId: "all",
    campaignLoading: true,
    campaignList: [],
    year: moment().format("YYYY"),
  };

  componentDidMount() {
    this.init();
  }

  // componentWillReceiveProps() {
  //   this.init();
  // }

  campaignListGet = async () => {
    this.setState({ campaignLoading: true });

    var _response = await Api.moderator("CampaignList");

    this.setState({
      campaignList: _response.list || [],
      campaignLoading: false,
    });
  };

  
  campaignSelectRender = () => {
    var _option = [
      <option value="all">แคมเปญทั้งหมด</option>
    ]

    this.state.campaignList.forEach((data)=>{
      _option.push(<option value={data.id}>{data.name}</option>)
    })

    return <select className="uk-select uk-width-1-1 uk-margin-bottom" value={this.state.campaignId} onChange={(e)=>this.setState({campaignId: e.target.value, loading: true}, ()=>{ 
      this.setState({loading: false})
    })}>{_option}</select>;
  }

  async init() {
    this.campaignListGet();
  }

  yearSelectRender = () => {
    var _next_year = moment().add(1, "year").format("YYYY");

    var _op = [];

    for (let i = 0; i < 10; i++) {
      _op.push(_next_year - i);
    }

    return (
      <select
        className="uk-select uk-width-1-1 uk-margin-bottom"
        value={this.state.year}
        onChange={(e) =>
          this.setState({ year: e.target.value, loading: true }, ()=>{ 
            this.setState({loading: false})
          })
        }
      >
        {_op.map((year) => {
          return <option key={`year${year}`} value={year}>ปี ค.ศ. {year}</option>;
        })}
      </select>
    );
  };

  render() {
    return (
      <div>
        {
          this.state.loading === true ?
          <div className="uk-padding uk-text-center">
            <span uk-spinner=""></span>
          </div>
          :
          <div className="uk-container uk-margin-bottom uk-padding-top">
            <div className="uk-grid">
              <div className="uk-width-1-2@m uk-width-1-1"></div>
              <div className="uk-width-1-4@m uk-width-1-1">
                {this.campaignSelectRender()}
              </div>
              <div className="uk-width-1-4@m uk-width-1-1">
                {this.yearSelectRender()}
              </div>
            </div>
            <div>
              <OnlineStat apiType="moderator" campaignId={this.state.campaignId} year={this.state.year} />
            </div>
            <div>
              <Survey apiType="moderator" campaignId={this.state.campaignId} year={this.state.year} />
            </div>
            <div>
              <HivTable apiType="moderator" campaignId={this.state.campaignId} year={this.state.year} />
            </div>
          </div>
        }
        
      </div>
    );
  }
}
