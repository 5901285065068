import React, { Component } from "react";
import moment from 'moment';
import Api from './Api.js';
import ChatBoxMessageChat from "./ChatBoxMessageChat.js";
import $ from 'jquery';

export default class ChatHistory extends Component {
  state = {
    showPopup: false,
    dateStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    dateStop: moment().format('YYYY-MM-DD'),
    list: []
  }

  componentDidMount () {
    this.init();
  }

  componentWillReceiveProps () {
    this.init();
  }

  listGet = async () => {
    var _response = await Api[this.props.apiType]("ChatMessageHistory", {
      dateStart: this.state.dateStart,
      dateStop: this.state.dateStop,
      memberId: this.props.memberId,
      campaignId : this.props.campaignId
    });

    var _list = [];


    if(Array.isArray(_response.list) === true) {
      _response.list.forEach((data) => {
        _list.push(<tr>
          <td>{moment(data.c_dateadd, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}</td>
          <td>{moment(data.c_dateadd, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</td>
          <td className="uk-text-left">{data.chatby === 'm' ? (_response.member || '-') : (_response.consult[data.c_consultid] || '-')}</td>
          <td className="uk-text-left"><ChatBoxMessageChat apiType={this.props.apiType} data={data} /></td>
        </tr>);
      })
    }

    if(_list.length === 0) {
      _list.push(<tr><td colSpan={'100%'} className="uk-padding-small uk-text-center">ไม่พบข้อมูล</td></tr>)
    }

    this.setState({list: _list});
  }

  init = () => {

  }

  print = () => {
    
    $("#d-print-container").remove();

    $("#root").prepend(
      $('<div id="d-print-container">').html([
        `<div>วันที่ ${moment(this.state.dateStart).format('DD/MM/YYYY')} ถึง ${moment(this.state.dateStop).format('DD/MM/YYYY')}</div>`,
        $("#d-chat-history-list-container").html()
      ])
    );

    window.print();
    
    $("#d-print-container").css({display: 'none'});
  }

  render () {
    return <div>
      <button className="uk-button uk-button-secondary uk-button-muted uk-margin-small-top" onClick={()=>{
        this.setState({showPopup: true});
                
        this.listGet();
      }}>
        ดูประวัติการแชท
      </button>
      {
        this.state.showPopup === true && <div className="d-popup-background">
          <div className="d-popup-body">
            <div className="uk-text-right">
              <a uk-icon="close" onClick={()=>this.setState({showPopup: false})}></a>
            </div>
            <div uk-grid="" className="uk-flex-middle uk-margin-top">
              <div className="uk-width-auto@m uk-width-1-1">วันที่</div>
              <div className="uk-width-expand@m uk-width-1-1">
                <input type="date" className="uk-input" value={this.state.dateStart} onChange={(e)=>this.setState({dateStart: e.target.value})} />
              </div>
              <div className="uk-width-auto@m uk-width-1-1">ถึง</div>
              <div className="uk-width-expand@m uk-width-1-1">
                <input type="date" className="uk-input" value={this.state.dateStop} onChange={(e)=>this.setState({dateStop: e.target.value})} />
              </div>
              <div className="uk-width-auto@m uk-width-1-1">
                <button className="uk-button uk-button-primary" onClick={this.listGet}>ยืนยัน</button>
              </div>
              <div className="uk-width-auto@m uk-width-1-1">
               <button className="uk-button uk-button-default" onClick={this.print}><span uk-icon="print"></span> พิมพ์</button>
              </div>
            </div>
            <div id="d-chat-history-list-container">
              <table className="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th>วันที่</th>
                    <th>เวลา</th>
                    <th>ผู้สนทนา</th>
                    <th>ข้อความ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.list}
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
      }
      
    </div>
  }
};