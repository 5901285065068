
import React, { Component } from 'react';
import $ from 'jquery';

export default (id) => {
    
  var _data = $("#d-hiv-data-" + id).text().replace(/  /g, '');

  var _lines = _data.split('\n');

  var canvas = document.createElement("CANVAS");
  canvas.width = 1000;
  canvas.height = _lines.length * 30 + 150;

  var ctx = canvas.getContext("2d");

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "black";
  ctx.font = "18px Tahoma";
  var x = 50;
  var y = 100;
  var lineheight = 30;

  for (var i = 0; i<_lines.length; i++)
    ctx.fillText(_lines[i], x, y + (i*lineheight) );
  
  return canvas;

}
