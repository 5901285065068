import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import moment from "moment";
import Thumbnail from '../Thumbnail';
import firebase from "firebase";

UIkit.use(Icons);

export default class TeleConsultHeader extends Component {
  state = {
    loadingConsult : false
  };

  data = null

  init = () => {
    this.data = this.props.data;
    this.data.special = this.data.speciallist.split('\n').filter(Boolean);

    

    if(!this.data.campaign) {
      this.setState({loadingConsult: true});
      this.campaignGet();
    }
    else {
      this.setState({loadingConsult: false});
    }
  }

  componentDidMount() {
    this.init();
    console.log(this.props)
  }

  campaignGet = async () => {
    
    this.setState({loadingConsult: true});

    var _r = await Api.consult('CampaignList');

    if(Array.isArray(_r.list)) {
      this.data.campaign = _r.list;
    }
    
    this.setState({loadingConsult: false});
  }

  roomKeyVideoCallRender = () => {
    if(+this.data.id > +this.props.consult.id) {
      return `${this.props.consult.id}/${this.data.id}`;
    } else {
      return `${this.data.id}/${this.props.consult.id}`;
    }
  }
  
  render() {
    return this.data !== null && <div className="uk-grid uk-grid-small">
    <div className="uk-width-auto">
      <div className="d-teleconsult-thumbnail-container">
        {
          this.props.hideNotiIcon === true ? null :
          <span className="d-teleconsult-thumbnail-unread uk-border-circle">{this.data.unread}</span>
        }
        <div className="uk-text-center">
          <Thumbnail image={this.data.image} online={this.data.online} />
        </div>
      </div>
      <div>
        <a className="d-chat-video-button" href={`https://con.defence-innovation.com/teleconsult${this.roomKeyVideoCallRender()}?openExternalBrowser=1`} target="_blank" onClick={()=>{
          firebase.database().ref('teleconsult_videocall/' + this.data.id).set({
            timestamp : new Date().getTime(),
            value: true,
            consultId: this.props.consult.id
          });
        }} >วีดีโอคอล</a>
      </div>
    </div>
    <div className="uk-width-expand">
      <b>{this.data.nameprefix} {this.data.name} {this.data.lastname}</b>
      <div className="uk-margin-small-bottom">
        {this.data.online === true ? <span className="uk-label d-background-green uk-margin-small-right">ออนไลน์</span> : <span className="uk-label d-background-gray uk-margin-small-right">ออฟไลน์</span>}
        {this.data.typeconsult && <span className="uk-label d-background-green uk-margin-small-right">{this.data.typeconsult}</span>}
        {this.data.special.map((name)=>{
          return <span className="uk-label d-background-green-2 uk-margin-small-right">{name}</span>
        })}
        {
          this.data.campaign && this.data.campaign.map((campaign)=>{
            return <span className="uk-label d-background-green uk-margin-small-right">{campaign.name}</span>
          })
        }
      </div>
      {
        this.props.hideLastMessage === true ? null : <div className="uk-text-meta uk-margin-small-bottom"><b>ข้อความล่าสุด :</b> <span className="uk-text-truncate uk-width-medium">{this.data.title || '-'}</span> (วันที่ {moment(this.data.dateadd).format('DD/MM/YYYY เวลา HH:mm น.')})</div>
      }
      
    </div>
    {
      this.props.hideMessageButton === true ? null : <div className="uk-width-auto@s uk-width-1-1 uk-text-right">
      <a href={'/consult/teleConsultRoom?receiveId=' + this.data.id} className="uk-button uk-button-primary">ส่งข้อความ</a>
    </div>
  }
  </div>
  }
}
