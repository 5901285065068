import React, { Component } from 'react';
import Telemonitoring from './Telemonitoring.js';
import Error404 from '../Pages/Error404.js';
import Api from '../Components/Api.js';
import './dashboard.css';

export default class DietzDashboard extends Component {

  state = {
    sideMin: false,
    sideHide: false
  }

  componentDidMount() {
  }

  
  qs = Api.queryString

  async init() {

    if(this.qs.logout === 'true') localStorage.setItem('AL', '');

    var result = false;
    try {
      var {result, member} = await Api.member('MemberLoginBySession');
    } catch(e) {
      console.error(e);
    }

    this.setState({ login: result === true ? true : false, loadingLogin: false, member: member || this.member });
  }

  routeRender = () => {
    var routes = {
      "/dietz-dashboard": Telemonitoring,
    };

    var pathname = this.props.location.pathname.split('/').filter(Boolean).join('/');
    var Route = routes[`/${pathname}`];
    if(Route) {
      return <Route {...this.props} qs={this.qs} />;
    }
    else {
      return <Error404 {...this.props} />;
    }
  }


  render() {
    return <div className="uk-grid uk-grid-collapse">
      <div className="uk-width-auto">
        <div id="dd-side-container" className={`${(this.state.sideMin === true ? 'dd-side-min' : '')} ${this.state.sideHide === true ? 'dd-side-hide' : ''}`}>
          <div className="uk-text-center"><div className="dd-side-title uk-text-uppercase">Dietz Dashboard</div></div>
          <div className="dd-hr"></div>
          <a className="d-font-mon"><i className="fa fa-tachometer-alt"></i> <span>Telemonitoring</span></a>
          <div className="dd-hr"></div>
          <div className="uk-margin-top-small uk-text-center">
            <button id="dd-side-min-button" onClick={()=>this.setState({sideMin: !this.state.sideMin})}>{this.state.sideMin === false ? <i class="fa fa-chevron-left"></i> : <i class="fa fa-chevron-right"></i>}</button>
          </div>
          
        </div>
      </div>
      <div className="uk-width-expand">
        <div id="dd-top-container" className="uk-flex-middle">
          <img src="/assets/logo_dietz-l.png" style={{height: 40}} />
          <button id="dd-menu-mobile-button" className="uk-hidden@m" onClick={()=>this.setState({sideHide: !this.state.sideHide})}><i className="fa fa-bars"></i></button>
        </div>
        <div id="dd-body-container">{this.routeRender()}</div>
        <div id="dd-body-footer" className=" uk-text-center uk-text-small">© 2021 <a href="http://www.dietz.asia/" target="_blank">dietz.asia</a>, Precision Dietz co., ltd. All Rights Reserved.</div>
      </div>
    </div>

  }
};
