//import axios from 'axios';
import $ from 'jquery';

var Api = {
  serverUrl: 'https://phrserver.idoit.life/',
  admin: async (type, data) => {
    var response = await $.ajax({
      method: 'POST',
      url: Api.serverUrl,
      data: JSON.stringify({type: 'Admin/'+type, ...data}),
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",window.localStorage.getItem('adminLogin') || '' );
      }
    }).promise();


    return response;
  },
  member: async (type, data) => {
    var response =  await $.ajax({
      method: 'POST',
      url: Api.serverUrl,
      data: JSON.stringify({type: 'Member/'+type, ...data}),
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",window.localStorage.getItem('memberLogin') || '' );
      },
      error: function(e) {
        console.log(e)
      }
    })
    .promise();
    
    /*
    var response =  await axios.post(Api.serverUrl, {type: 'Member/'+type, ...data}, {
      headers: {
        Authorization : window.localStorage.getItem('memberLogin') || ''
      }
    });
    */

    return response;
  },
  consult: async (type, data) => {

    return await $.ajax({
      method: 'POST',
      url: Api.serverUrl,
      data: JSON.stringify({type: 'Consult/'+type, ...data}),
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",window.localStorage.getItem('consultLogin') || '' );
      }
    }).promise();
  },
  moderator: async (type, data) => {

    return await $.ajax({
      method: 'POST',
      url: Api.serverUrl,
      data: JSON.stringify({type: 'Moderator/'+type, ...data}),
      beforeSend: function(request) {
        request.setRequestHeader("Authorization",window.localStorage.getItem('moderatorLogin') || '' );
      }
    }).promise();
  },
  dietzdashboard: async (type, data) => {

    var response =  await $.ajax({
      method: 'POST',
      url: Api.serverUrl,
      data: JSON.stringify({type: 'DietzDashboard/'+type, ...data}),
      error: function(e) {
        console.log(e)
      }
    })
    .promise();

    return response;
  },
  queryString: (function(a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i)
    {
        var p=a[i].split('=', 2);
        if (p.length == 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
  })(window.location.search.substr(1).split('&'))
};

export default Api;
