import React, { Component } from "react";
import Api from "../Components/Api.js";
import ReactEcharts from "echarts-for-react";
import numeral from "numeral";

export default class TelemonitoringHealthRecord extends Component {
  state = {
    amount: 0,
    loadingApi: true,
    dataX: [],
    dataY: [],
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    var _result = await Api.dietzdashboard("TelemonitoringHealthRecord");

    if (!_result.data) _result.data = [];

    var _dataX = [];
    var _dataY = [];
    var _amount =0;

    _result.data.forEach(function (data, index) {
      if (index < 5) {
        _dataX.push(data.name.toUpperCase());
  
        _dataY.push(data.amount * 8);
      }

      _amount += +data.amount*8;

    });

    this.setState({
      dataX: _dataX,
      dataY: _dataY,
      amount: _amount,
      loadingApi: false,
    });
  }

  renderChart = () => {
    var chartOptions = {
      xAxis: {
        type: "category",
        data: this.state.dataX,
        axisLine: {
          lineStyle: {
            width: 0,
            color: '#AAA'
          }
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            width: 0,
            color: '#AAA'
          }
        },
      },
      series: [
        {
          data: this.state.dataY,
          type: "bar",
          barMaxWidth: 50,
        },
      ],
      color: ['#4e73df'],
      // Enable drag recalculate
      calculable: true,

      // Add tooltip
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // Pointer axis, the axis trigger effective
          type: 'shadow'        // The default is a straight line, optionally: 'line' | 'shadow'
        },
      },
    };

    return (
      <ReactEcharts
        option={chartOptions}
        style={{ height: 250 }}
        lazyUpdate={true}
      />
    );
  };

  render() {
    return (
      <div className="dd-card dd-shadow">
        <div className="dd-card-header">
        Health Records <i className="uk-text-meta" uk-icon="info" uk-tooltip="จำนวนการบันทึกข้อมูลสุขภาพ ผ่านแพลต์ฟอร์ม ทั้งข้อมูลสุขภาพก่อนพบแพทย์ออนไลน์ และข้อมูลสุขภาพผู้ป่วยที่บันทึกเพื่อติดตามสุขภาพตามคำสั่งแพทย์"></i>
        </div>
        <div className="uk-padding">
        {this.state.loadingApi === true ? (
          <div>
            <span uk-spinner=""></span>
          </div>
        ) : <div>
          <h2 className="uk-margin-remove uk-text-muted">{numeral(this.state.amount).format('0,0')}</h2>
          <div>{this.renderChart()}</div>
        </div>
          
        }

        </div>
      </div>
    );
  }
}
