import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import numeral from 'numeral';
import moment from 'moment';
import CampaignInputSetting from '../../Components/CampaignInputSetting.js';

UIkit.use(Icons);

export default class CampaignDetail extends Component {
  state = {
    consult: [],
    member: [],
    consultSearch: '',
    consultSearchResult: [],
    memberSearch: '',
    memberSearchResult: [],
    consultByCampaign: [],
    memberByCampaign: [],
    loadingMember: true,
    campaign: {},
    campaignLoading: true,
    membercampaign: {
      datestart: moment().format('YYYY-MM-DD'),
      datestop:  moment().add(7, 'd').format('YYYY-MM-DD'),
      day: '7'
    },
    memberSearchSelect: null,
    popupOpen: false
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    this.setState({loadingMember: true});

    var consult = await Api.admin('ConsultList', {companyId: this.props.qs.companyid});

    
    var member = await Api.admin('MemberList', {all: true, companyId: this.props.qs.companyid});

    
    this.setState({ member: member.list, consult: consult.list, loadingMember: false });

    this.consultByCampaignGet();
    this.memberByCampaignGet();

    this.campaignGet();
  }

  campaignGet = async () => {
    this.setState({campaignLoading: true});

    var _response = await Api.admin('CampaignById', {id: this.props.qs.id});

    this.setState({campaign: _response.data, campaignLoading: false});
  }

  consultSearchGet = (e) => {
    this.setState({ consultSearch: e.target.value }, () => {
      var consultSearchResult = [];
      if(this.state.consultSearch.trim() !== '') {
        for(var i=this.state.consult.length; i-->0;) {
          if( (this.state.consult[i].name + ' ' + this.state.consult[i].lastname).indexOf(this.state.consultSearch) >=0 ) {
            consultSearchResult.push(this.state.consult[i]); 
          }
        }
      } 
      
      this.setState({consultSearchResult});
    });
  }

  consultCampaignUpdate = async (data) => {
    var isHas = false;
    for(var i = this.state.consultByCampaign.length; i-->0;) {
      if(this.state.consultByCampaign[i].consultid == data.id)  isHas = true;
    }

    var result  = false;
    var responseText = 'มีที่ปรึกษานี้แล้ว';

    if(!isHas) {
      var {result, responseText} = await Api.admin('ConsultByCampaignAdd', {consultid: data.id, campaignid: this.props.qs.id});this.consultByCampaignGet();
    }

    UIkit.notification({message: responseText, status: result ? 'success' : 'danger' });
  }

  consultByCampaignGet = async () => {
    var {list} = await Api.admin('ConsultByCampaignList', {id: this.props.qs.id});
    this.setState({consultByCampaign: list || []});
  }

  memberCampaignUpdate = async (data) => {
    this.setState({
      memberSearchSelect: data,
      popupOpen: true
    });
  }

  memberByCampaignGet = async () => {
    var {list, query} = await Api.admin('MemberByCampaignList', {id: this.props.qs.id});
    this.setState({memberByCampaign: list || []});
  }
 
  consultForm = () => {
    return <div className="uk-button-group uk-width-1-1">
        <input type="text" className="uk-input" placeholder="ค้นหาชื่อที่ปรึกษา" value={this.state.consultSearch} onChange={this.consultSearchGet} />
      </div>
  }

  consultTabelRender = () => {
    return <table className="uk-table uk-table-small uk-table-divider">
    <thead>
      <tr>
        <th>ลำดับ</th>
        <th>รายละเอียด</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    {
      this.state.consultByCampaign.length > 0 ?
        this.state.consultByCampaign.map((data,key)=>{
          return <tr key={`rowConsult${key}`}>
            <td>{key+1}</td>
            <td>{data.prefixname} {data.name} {data.lastname}</td>
            <td className="uk-text-right">
              <button className="uk-button uk-button-small" onClick={async ()=>{
                  var {result} = await Api.admin('ConsultByCampaignRemove', {id: data.id});
                  this.consultByCampaignGet();
              }} >
                <span uk-icon="icon: trash;"></span>
              </button>
            </td>
          </tr>
        })
        :
        <tr><td colSpan="100%"><div className="uk-padding uk-background-muted uk-text-center">ไม่พบรายการ</div></td></tr>
    }
    </tbody>
    </table>
  }

  memberSearchGetLoading = false;

  memberSearchGet = (e) => {
    if(this.memberSearchGetLoading === true) {
      setTimeout(()=> {
        this.memberSearchGetLoading = false;
      }, 200)

      return false;

    } else {
      this.memberSearchGetLoading = true;
      
      this.setState({ memberSearch: e.target.value }, () => {
        var memberSearchResult = [];
        var memberSearchResultMemberId = [];

        if(this.state.memberSearch.trim() !== '') {
          for(var i=this.state.member.length; i-->0;) {
            if( (memberSearchResultMemberId.indexOf(this.state.member[i].id) < 0) && (this.state.member[i].name + ' ' + this.state.member[i].lastname).indexOf(this.state.memberSearch) >=0 ) {
              memberSearchResultMemberId.push(this.state.member[i].id);
              memberSearchResult.push(this.state.member[i]); 
            }
          }
        }
        
        this.setState({memberSearchResult}, ()=>{
          this.memberSearchGetLoading = false;
        });
      });
    }
  }

  memberForm = () => {
    return <div className="uk-button-group uk-width-1-1">
        <input type="text" className="uk-input" placeholder="ค้นหาชื่อสมาชิก" value={this.state.memberSearch} onChange={this.memberSearchGet} />
      </div>
  }

  memberTabelRender = () => {
    return <table className="uk-table uk-table-small uk-table-divider">
    <thead>
      <tr>
        <th>ลำดับ</th>
        <th>รหัสสมาชิก</th>
        <th>ชื่อผู้ใช้</th>
        <th>รายละเอียด</th>
        <th>วันที่สมัคร</th>
        <th>วันที่หมดอายุ</th>
        <th>จำนวนวัน</th>
        <th>สถานะปัจจุบัน</th>
        <th>วิธีการสมัคร</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    {
      this.state.memberByCampaign.length > 0 ?
        this.state.memberByCampaign.map((data,key)=>{
          return <tr key={`rowConsult${key}`}>
            <td>{key+1}</td>
            <td>{data.memberid}</td>
            <td>{data.username}</td>
            <td>{data.prefixname} {data.name} {data.lastname}</td>
            <td>{data.datestart}</td>
            <td>{data.datestop}</td>
            <td>{data.day} วัน</td>
            <td>{data.datestop === '0000-00-00' ? '' : (moment(data.datestop) > moment() ? 'ไม่หมดอายุ' : 'หมดอายุ') + ` (ต่ออายุครั้งที่ ${data.renew})`}</td>
            <td>{data.registertype || 'เพิ่มโดยแอดมิน'}</td>
            <td className="uk-text-right">
              <button className="uk-button uk-button-small" onClick={async ()=>{
                  var {result} = await Api.admin('MemberByCampaignRemove', {id: data.id});
                  this.memberByCampaignGet();
              }}>
                <span uk-icon="icon: trash;"></span>
              </button>
            </td>
          </tr>
        })
        :
        <tr><td colSpan="100%"><div className="uk-padding uk-background-muted uk-text-center">ไม่พบรายการ</div></td></tr>
    }
    </tbody>
    </table>
  }

  updateMemberCampaignValue = (key, value) => {
    this.setState((state)=>{
      state.membercampaign[key] = value;

      return state;
    })
  }

  updateMemberCampaignSend = async () => {
    
    var {result, responseText} = await Api.admin('MemberByCampaignAdd', {
      memberid: this.state.memberSearchSelect.id, campaignid: this.props.qs.id, ...this.state.membercampaign
    });

    UIkit.notification({message: responseText, status: result ? 'success' : 'danger' });

    this.setState({popupOpen: false})
    
  }
    
  render() {
    return <div>
      {this.state.popupOpen === true && <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small">
          <a className='uk-float-right' uk-icon="close" onClick={()=>this.setState({popupOpen: false})} ></a>
          <div className="uk-margin-small-bottom">
            <label>วันที่เริ่มต้น
              <input type="date" className="uk-input" value={this.state.membercampaign.datestart} onChange={(e)=>{
                this.updateMemberCampaignValue('datestart', e.target.value);
                
                this.updateMemberCampaignValue('datestop', moment(e.target.value).add(this.state.membercampaign.day, 'day').format('YYYY-MM-DD'));
              }} />
            </label>
          </div>
          <div className="uk-margin-small-bottom">
            <label>วันที่สิ้นสุด</label>
            <div>{this.state.membercampaign.datestop}</div>
          </div>
          <div className="uk-margin-small-bottom">
              <label>ระยะแคมเปญกรณีรายบุคคล</label>
              <select className="uk-select" value={this.state.membercampaign.day} onChange={(e)=>{
                this.updateMemberCampaignValue('day', e.target.value);
                this.updateMemberCampaignValue('datestop', moment(this.state.membercampaign.datestart).add(e.target.value, 'day').format('YYYY-MM-DD'));


              }}>{
                ['7', '14', '30', '45', '60', '90', '120', '150', '180', '210', '240', '270', '300', '365'].map((data, index)=>{
                  return <option value={data} key={`selectday${index}`}>{data} วัน</option>
                })}
                
              </select>
          </div>
          <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" onClick={this.updateMemberCampaignSend}>เพิ่มสมาชิกในแคมเปญ</button>
        </div>
      </div>}
      { this.state.campaignLoading === false && <CampaignInputSetting {...this.props} apiType={'admin'} campaign={this.state.campaign} callback={(data)=>{
          console.log(data)
        }} /> }
      <h1>ที่ปรึกษาและสมาชิกในแคมเปญ</h1>
      {this.props.qs.campaignname && <div className="uk-margin-small-bottom">บริษัท : {decodeURIComponent(this.props.qs.campaignname)}</div>}
      <div className="uk-margin-small-bottom">แคมเปญ : {this.state.campaign.name}</div>
      <div className="uk-margin-small-bottom">วันที่แคมเปญ :  {this.state.campaign.datestart} ถึง {this.state.campaign.datestop}</div>
      <div className="uk-margin-small-bottom">ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล : {this.state.campaign.day} วัน</div>
      <div className="uk-margin-small-bottom">ค่าใช้จ่าย : {this.state.campaign.pay === '1' ? numeral(this.state.campaign.price).format("0,0.00") + ' บาท' : '-'} </div>

      <div uk-grid="" className="uk-child-width-1-2">
        <div>
          <h3>เพิ่มที่ปรึกษา</h3>
          <div>
            {this.consultForm()}
          </div>
          <div>
            {
              this.state.consultSearchResult.length > 0 ?
                <div className="uk-overflow-auto uk-margin-small-top uk-padding-small" style={{maxHeight: 200}}>
                {
                  this.state.consultSearchResult.map(data=>{
                    return <div key={`consultListSearchResult${data.id}`} className="uk-grid-small" uk-grid="">
                              <div className="uk-width-3-4">{data.prefixname} {data.name} {data.lastname}</div>
                              <div className="uk-width-1-4 uk-text-right">
                                <button className="uk-button uk-button-small uk-button-secondary" onClick={()=>this.consultCampaignUpdate(data)}>
                                  <span uk-icon="icon: plus"></span>
                                </button>
                              </div>
                          </div>
                  })
                }
                </div>
                :
                <div className="uk-margin-small-top uk-padding-small uk-text-center uk-background-muted">
                  {
                  this.state.consultSearch === '' ? 'โปรดใส่ชื่อที่ปรึกษาเพื่อค้นหารายชื่อ' : 'ไม่พบรายการ'
                  }
                </div>
            }
          </div>
        </div>
        <div>
          <h3>เพิ่มสมาชิก</h3>
          <div>
            {this.state.loadingMember === true ? <span uk-spinner=""></span> : this.memberForm()}
          </div>
          <div>
            {
              this.state.memberSearchResult.length > 0 ?
                <div className="uk-overflow-auto uk-margin-small-top uk-padding-small" style={{maxHeight: 200}}>
                {
                  this.state.memberSearchResult.map(data=>{
                    return <div key={`memberListSearchResult${data.id}`} className="uk-grid-small" uk-grid="">
                              <div className="uk-width-3-4">{data.prefixname} {data.name} {data.lastname}</div>
                              <div className="uk-width-1-4 uk-text-right">
                                <button className="uk-button uk-button-small uk-button-secondary" onClick={()=>this.memberCampaignUpdate(data)}>
                                  <span uk-icon="icon: plus"></span>
                                </button>
                              </div>
                          </div>
                  })
                }
                </div>
                :
                <div className="uk-margin-small-top uk-padding-small uk-text-center uk-background-muted">
                  {
                  this.state.memberSearch === '' ? 'โปรดใส่ชื่อสมาชิกเพื่อค้นหารายชื่อ' : 'ไม่พบรายการ'
                  }
                </div>
            }
          </div>
        </div>
      </div>
      <hr />
      <h4>ที่ปรึกษา</h4>
      {this.consultTabelRender()}
      <hr />
      <h4>สมาชิก</h4>
      {this.memberTabelRender()}
    </div>
  }
};
