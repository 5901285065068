import firebase from "firebase";
 

const config = {
  apiKey: "AIzaSyDDbkJQjPLfO2RQgo2iCjQTbiLnEEf9SjM",
  authDomain: "tele-cmu.firebaseapp.com",
  databaseURL: "https://tele-cmu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tele-cmu",
  storageBucket: "tele-cmu.appspot.com",
  messagingSenderId: "682985172743",
  appId: "1:682985172743:web:4400fb725d133a1bb95fa2",
  measurementId: "G-WS1PSY2QBG"
};

firebase.initializeApp(config);

firebase.auth().signInAnonymously().catch(function(error) {
  
});

