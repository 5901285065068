import React, { Component } from "react";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import numeral from "numeral";
import moment from "moment";
import CampaignInputSetting from '../CampaignInputSetting';
UIkit.use(Icons);

export default class ConsultCampaignDetail extends Component {
  state = {
    campaignLoading: false,
    campaign: null,
    keywordConsult: "",
    keywordMember: "",
    consultList: [],
    consultFilter: [],
    consultInCampaignList: [],
    memberList: [],
    memberFilter: [],
    memberInCampaignList: [],
    popupOpen: false,
    memberSearchSelect: null,
    membercampaign: {
      datestart: moment().format('YYYY-MM-DD'),
      datestop:  moment().add(7, 'd').format('YYYY-MM-DD'),
      day: '7'
    },
  };

  componentDidMount() {
    this.init();
  }


  init = () => {
    this.campaignDetailGet();
    this.consultInCompanyGet();
    this.memberInCompanyGet();
    this.consultInCampaignGet();
    this.memberInCampaignGet();

  }

  consultInCompanyGet = async () => {
    var _response = await Api.consult("ConsultInCompany", {
      id: this.props.qs.id,
    });

    this.setState({ consultList: _response.list });
  };

  memberInCompanyGet = async () => {
    var _response = await Api.consult("MemberInCompany", {
      id: this.props.qs.id,
    });


    this.setState({ memberList: _response.list });
  };

  campaignDetailGet = async () => {
    this.setState({ campaignLoading: true });

    var _response = await Api.consult("CampaignById", { id: this.props.qs.id });

    this.setState({ campaign: _response.data, campaignLoading: false });
  };

  consultFind = () => {
    this.setState({
      consultFilter: this.state.keywordConsult
        ? this.state.consultList.filter((data) => {
            if (
              data.id.includes(this.state.keywordConsult) ||
              data.name.includes(this.state.keywordConsult) ||
              data.lastname.includes(this.state.keywordConsult)
            ) {
              return true;
            } else {
              return false;
            }
          })
        : [],
    });
  };

  memberFind = () => {
    this.setState({
      memberFilter: this.state.keywordMember
        ? this.state.memberList.filter((data) => {
            if (
              data.id.includes(this.state.keywordMember) ||
              data.name.includes(this.state.keywordMember) ||
              data.lastname.includes(this.state.keywordMember)
            ) {
              return true;
            } else {
              return false;
            }
          })
        : [],
    });
  };

  memberInCampaignGet = async () => {
    var _response = await Api.consult("MemberInCampaign", {
      id: this.props.qs.id,
    });

    this.setState({ memberInCampaignList: _response.list });
  };

  consultInCampaignGet = async () => {
    var _response = await Api.consult("ConsultInCampaign", {
      id: this.props.qs.id,
    });

    this.setState({ consultInCampaignList: _response.list });
  };

  memberCampaignAddForm = (data) => {
    this.setState({
      popupOpen: true, 
      memberSearchSelect: data
    });

  }


  memberCampaignAdd = async () => {

    var _response = await Api.consult("CampaignAddMember", {
      memberid: this.state.memberSearchSelect.id, campaignid: this.props.qs.id, ...this.state.membercampaign
    });


    UIkit.notification(_response.responseText);

    if(_response.result) {
      this.memberInCampaignGet();

      this.setState({popupOpen: false});
    }

  };

  consultCampaignAdd = async (data) => {
    var _response = await Api.consult("CampaignAddConsult", {
      id: this.props.qs.id,
      consultid: data.id
    });

    UIkit.notification(_response.responseText);

    if(_response.result) {
      this.consultInCompanyGet();
    }
  };

  updateMemberCampaignValue = (key, value) => {
    this.setState((state)=>{
      state.membercampaign[key] = value;

      return state;
    })
  }

  removeMemberCampaign = async (memberid) => {
    if(window.confirm("ยืนยันลบสมาชิกรหัส" + memberid)) {
      var _response = await Api.consult("CampaignRemoveMember", {
        memberid: memberid, id: this.props.qs.id
      });
  
      UIkit.notification(_response.responseText);
  
      if(_response.result) {
        this.memberInCampaignGet();
      }

    }

  }

  removeConsultCampaign = async (consultid) => {
    if(window.confirm("ยืนยันลบที่ปรึกษารหัส" + consultid)) {
      var _response = await Api.consult("CampaignRemoveConsult", {
        consultid: consultid, id: this.props.qs.id
      });
  
      UIkit.notification(_response.responseText);
  
      if(_response.result) {
        this.consultInCampaignGet();
      }
    }

  }

  render() {
    return (
      <div className="uk-padding">
        {this.state.popupOpen === true && <div className="d-popup-background">
        <div className="d-popup-body d-popup-body-small">
          <a className='uk-float-right' uk-icon="close" onClick={()=>this.setState({popupOpen: false})} ></a>
          <div className="uk-margin-small-bottom">
            <label>วันที่เริ่มต้น
              <input type="date" className="uk-input" value={this.state.membercampaign.datestart} onChange={(e)=>{
                this.updateMemberCampaignValue('datestart', e.target.value);
                
                this.updateMemberCampaignValue('datestop', moment(e.target.value).add(this.state.membercampaign.day, 'day').format('YYYY-MM-DD'));
              }} />
            </label>
          </div>
          <div className="uk-margin-small-bottom">
            <label>วันที่สิ้นสุด</label>
            <div>{this.state.membercampaign.datestop}</div>
          </div>
          <div className="uk-margin-small-bottom">
              <label>ระยะแคมเปญกรณีรายบุคคล</label>
              <select className="uk-select" value={this.state.membercampaign.day} onChange={(e)=>{
                this.updateMemberCampaignValue('day', e.target.value);
                this.updateMemberCampaignValue('datestop', moment(this.state.membercampaign.datestart).add(e.target.value, 'day').format('YYYY-MM-DD'));
              }}>{
                ['7', '14', '30', '45', '60', '90', '120', '150', '180', '210', '240', '270', '300', '365'].map((data, index)=>{
                  return <option value={data} key={`selectday${index}`}>{data} วัน</option>
                })}
                
              </select>
          </div>
          <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" onClick={this.memberCampaignAdd}>เพิ่มสมาชิกในแคมเปญ</button>
        </div>
      </div>}
      
        {this.state.campaignLoading === false && <CampaignInputSetting {...this.props} campaign={this.state.campaign} callback={(data)=>{
          console.log(data)
        }} />}

        <div className="uk-card uk-card-default uk-margin-top uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">ที่ปรึกษาและสมาชิกในแคมเปญ</div>
            <div className="d-card-header-right"></div>
          </div>
          <div>
            {this.state.campaign === null ? (
              this.state.campaignLoading === true ? (
                <div className="uk-padding-small uk-text-center">
                  <span uk-spinner=""></span>
                </div>
              ) : (
                <div className="uk-padding-small uk-text-center">
                  ไม่พบข้อมูล
                </div>
              )
            ) : (
              <div>
                <div
                  className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-1"
                  uk-grid=""
                >
                  <div>
                    <b>บริษัท :</b> {this.state.campaign.companyname}
                  </div>
                  <div>
                    <b>แคมเปญ :</b> {this.state.campaign.name}
                  </div>
                  <div>
                    <b>ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล :</b>{" "}
                    {this.state.campaign.day} วัน
                  </div>
                  <div>
                    <b>ค่าใช้จ่าย (ถ้ามี) :</b>{" "}
                    {this.state.campaign.pay === "1"
                      ? numeral(this.state.campaign.price).format("0,0.00") +
                        " บาท"
                      : "-"}
                  </div>
                  <div>
                    <h4 className="uk-margin-remove-bottom">เพิ่มที่ปรึกษา</h4>
                    <input
                      type="text"
                      placeholder="ค้นหาชื่อที่ปรึกษา"
                      className="uk-input uk-margin-small-top"
                      value={this.state.keywordConsult}
                      onChange={(e) => {
                        this.setState(
                          { keywordConsult: e.target.value },
                          () => {
                            this.consultFind();
                          }
                        );
                      }}
                    />
                    <div className="uk-margin-small-top">
                      {this.state.keywordConsult ? (
                        this.state.consultFilter.length === 0 ? (
                          <div className="uk-padding-small uk-background-muted uk-text-center">
                            ไม่พบข้อมูล
                          </div>
                        ) : (
                          this.state.consultFilter.map((data, index) => {
                            return (
                              <div key={`consultFilterRow${index}`}>
                                {data.nameprefix} {data.name} {data.lastname}
                                <button
                                  className="uk-float-right"
                                  uk-icon="plus-circle"
                                  onClick={() => this.consultCampaignAdd(data)}
                                ></button>
                                <hr />
                              </div>
                            );
                          })
                        )
                      ) : (
                        <div className="uk-padding-small uk-background-muted uk-text-center">
                          โปรดใส่ชื่อที่ปรึกษาเพื่อค้นหารายชื่อ
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h4 className="uk-margin-remove-bottom">เพิ่มสมาชิก</h4>
                    <input
                      type="text"
                      placeholder="ค้นหาชื่อสมาชิก"
                      className="uk-input uk-margin-small-top"
                      value={this.state.keywordMember}
                      onChange={(e) => {
                        this.setState({ keywordMember: e.target.value }, () => {
                          this.memberFind();
                        });
                      }}
                    />
                    <div className="uk-margin-small-top">
                      {this.state.keywordMember ? (
                        this.state.memberFilter.length === 0 ? (
                          <div className="uk-padding-small uk-background-muted uk-text-center">
                            ไม่พบข้อมูล
                          </div>
                        ) : (
                          this.state.memberFilter.map((data, index) => {
                            return (
                              <div key={`memberFilterRow${index}`}>
                                {data.nameprefix} {data.name} {data.lastname}
                                <button
                                  className="uk-float-right"
                                  uk-icon="plus-circle"
                                  onClick={() => this.memberCampaignAddForm(data)}
                                ></button>
                                <hr />
                              </div>
                            );
                          })
                        )
                      ) : (
                        <div className="uk-padding-small uk-background-muted uk-text-center">
                          โปรดใส่ชื่อสมาชิกเพื่อค้นหารายชื่อ
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className=" uk-card uk-card-default uk-margin-top uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">ที่ปรึกษาปัจจุบัน</div>
            <div className="d-card-header-right"></div>
          </div>
          <div className="uk-overflow-auto">
          <table className="uk-table uk-table-small uk-table-divider">
                        <thead>
                          <tr>
                            <th>ลำดับ</th>
                            <th>รหัส</th>
                            <th>ชื่อ-สกุล</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.consultInCampaignList.length === 0 ? (
                            <tr>
                              <td colSpan={"100%"} className="uk-text-center">
                                {" "}
                                ไม่พบที่ปรึกษาโปรดเพิ่มที่ปรึกษา
                              </td>
                            </tr>
                          ) : (
                            this.state.consultInCampaignList.map(
                              (data, index) => {
                                return (
                                  <tr key={`rowConsultList${index}`}>
                                    <td>{index + 1}</td>
                                    <td>{data.id}</td>
                                    <td>
                                      {data.nameprefix} {data.name}{" "}
                                      {data.lastname}
                                    </td>
                                    <td>
                                      <a uk-icon="trash" onClick={()=>this.removeConsultCampaign(data.id)} ></a>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
          </div>
        </div>

        <div className=" uk-card uk-card-default uk-margin-top uk-padding-small">
          <div className="d-card-header">
            <div className="d-card-header-left">สมาชิกปัจจุบัน</div>
            <div className="d-card-header-right"></div>
          </div>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>รหัส</th>
                  <th>ชื่อผู้ใช้</th>
                  <th>วันเดือนปีเกิด</th>
                  <th>ชื่อ-สกุล</th>
                  <th>วันที่สมัคร</th>
                  <th>วันที่หมดอายุ</th>
                  <th>จำนวนวัน</th>
                  <th>สถานะ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.memberInCampaignList.length === 0 ? (
                  <tr>
                    <td colSpan={"100%"} className="uk-text-center">
                      {" "}
                      ไม่พบสมาชิก โปรดเพิ่มสมาชิก
                    </td>
                  </tr>
                ) : (
                  this.state.memberInCampaignList.map((data, index) => {
                    return (
                      <tr key={`rowMemberList${index}`}>
                        <td>{index + 1}</td>
                        <td>{data.id}</td>
                        <td>{data.username}</td>
                        <td>{data.birthday && data.birthday != '0000-00-00' ? `${moment(data.birthday, 'YYYY-MM-DD').add(543, 'year').format('DD/MM/YYYY')} (อายุ ${moment().diff(data.birthday, 'years', false)} ปี)` : ''}</td>
                        <td>
                          {data.nameprefix} {data.name} {data.lastname}
                        </td>
                        <td>{data.datestart}</td>
                        <td>{data.datestop}</td>
                        <td>{data.day} วัน</td>
                        <td>
                          {data.datestop === "0000-00-00"
                            ? ""
                            : (moment(data.datestop) > moment()
                                ? "ไม่หมดอายุ"
                                : "หมดอายุ") +
                              ` (ต่ออายุครั้งที่ ${data.renew})`}
                        </td>
                        <td>
                          <a uk-icon="trash" onClick={()=>this.removeMemberCampaign(data.id)}></a>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    );
  }
}
